import {
  displayInvestmentType,
  getInvestmentValue,
  getLastInvestmentUpdateDate,
} from '../../Investment';
import {
  AmountSumTable,
  AmountSumTableItem,
  AmountSumTableProps,
} from '../../UI';
import {
  UseQtPreTaxInvestmentsSumTableArgs,
  useQtPreTaxInvestmentsSumTable,
} from '../hooks';

interface QtPreTaxInvestmentsSumTableProps
  extends UseQtPreTaxInvestmentsSumTableArgs,
    Pick<AmountSumTableProps, 'hideOnEmpty'> {
  className?: string;
}

export function QtPreTaxInvestmentsSumTable({
  className,
  hideOnEmpty,
  ...useQtTaxDeferredInvestmentsSumTableArgs
}: QtPreTaxInvestmentsSumTableProps) {
  const { isLoading, qtDetails, tUI, tInvestment } =
    useQtPreTaxInvestmentsSumTable(useQtTaxDeferredInvestmentsSumTableArgs);

  return (
    <AmountSumTable
      className={className}
      isLoading={isLoading}
      sum={qtDetails?.totalTaxDeferred}
      hideOnEmpty={hideOnEmpty ?? true}
      title={tUI('qt-pre-tax-investments-table-title')}
      sumTitle={tUI('qt-pre-tax-investments-table-sum-title')}
    >
      {qtDetails?.taxDeferredAccounts.map((investment) => (
        <AmountSumTableItem
          key={investment.id}
          title={investment.name}
          amount={getInvestmentValue(investment)}
          lastUpdated={getLastInvestmentUpdateDate(investment)}
          subtitle={displayInvestmentType(
            investment.investmentType,
            tInvestment
          )}
        />
      ))}
    </AmountSumTable>
  );
}
