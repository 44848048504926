import { ElementsIconProps } from '../components/Icon/types';

export function FileUploadIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_69_745)">
        <path
          d="M7.4 10H8.99V15C8.99 15.55 9.44 16 9.99 16H13.99C14.54 16 14.99 15.55 14.99 15V10H16.58C17.47 10 17.92 8.92 17.29 8.29L12.7 3.7C12.31 3.31 11.68 3.31 11.29 3.7L6.7 8.29C6.07 8.92 6.51 10 7.4 10ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_745">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
