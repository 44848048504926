import { ElementsIconProps } from '../components/Icon/types';

export function MicOffIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="mic_off_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.92 11.1V5.5C14.92 3.84 13.58 2.5 11.92 2.5C10.38 2.5 9.13 3.66 8.96 5.15L14.92 11.1ZM18 11.5C17.59 11.5 17.23 11.8 17.17 12.21C17.12 12.53 17.05 12.85 16.95 13.14L18.22 14.41C18.52 13.81 18.74 13.16 18.85 12.47C18.92 11.96 18.52 11.5 18 11.5ZM3.63 4.06C3.24 4.45 3.24 5.08 3.63 5.47L8.92 10.77V11.2C8.92 12.39 9.52 13.52 10.55 14.11C11.3 14.54 11.96 14.55 12.57 14.42L14.23 16.08C13.52 16.41 12.73 16.6 11.92 16.6C9.38 16.6 7.04 14.83 6.67 12.21C6.61 11.8 6.25 11.5 5.84 11.5C5.32 11.5 4.92 11.96 4.99 12.47C5.45 15.43 7.95 17.77 10.92 18.22V20.5C10.92 21.05 11.37 21.5 11.92 21.5C12.47 21.5 12.92 21.05 12.92 20.5V18.22C13.83 18.09 14.69 17.77 15.47 17.32L18.96 20.81C19.35 21.2 19.98 21.2 20.37 20.81C20.76 20.42 20.76 19.79 20.37 19.4L5.04 4.06C4.85317 3.87275 4.59952 3.76751 4.335 3.76751C4.07048 3.76751 3.81683 3.87275 3.63 4.06Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_451"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="2"
          width="18"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.92 11.1V5.5C14.92 3.84 13.58 2.5 11.92 2.5C10.38 2.5 9.13 3.66 8.96 5.15L14.92 11.1ZM18 11.5C17.59 11.5 17.23 11.8 17.17 12.21C17.12 12.53 17.05 12.85 16.95 13.14L18.22 14.41C18.52 13.81 18.74 13.16 18.85 12.47C18.92 11.96 18.52 11.5 18 11.5ZM3.63 4.06C3.24 4.45 3.24 5.08 3.63 5.47L8.92 10.77V11.2C8.92 12.39 9.52 13.52 10.55 14.11C11.3 14.54 11.96 14.55 12.57 14.42L14.23 16.08C13.52 16.41 12.73 16.6 11.92 16.6C9.38 16.6 7.04 14.83 6.67 12.21C6.61 11.8 6.25 11.5 5.84 11.5C5.32 11.5 4.92 11.96 4.99 12.47C5.45 15.43 7.95 17.77 10.92 18.22V20.5C10.92 21.05 11.37 21.5 11.92 21.5C12.47 21.5 12.92 21.05 12.92 20.5V18.22C13.83 18.09 14.69 17.77 15.47 17.32L18.96 20.81C19.35 21.2 19.98 21.2 20.37 20.81C20.76 20.42 20.76 19.79 20.37 19.4L5.04 4.06C4.85317 3.87275 4.59952 3.76751 4.335 3.76751C4.07048 3.76751 3.81683 3.87275 3.63 4.06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_451)"></g>
      </g>
    </svg>
  );
}
