import { SummaryPageContainer } from './components';
import { BusinessSummarySumTable } from '../../../TtDetails';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewBusinessPageProps } from './types';

export function TotalTermElementDetailViewBusinessPage({ householdID }: TotalTermElementDetailViewBusinessPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-business-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-business-summary-page-empty-description')}
    >
      <BusinessSummarySumTable hideOnEmpty className="mt-10" householdID={householdID} />
    </SummaryPageContainer>
  );
}
