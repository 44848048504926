import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseEquityRateElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useEquityRateElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseEquityRateElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
