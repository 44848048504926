import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@remix-run/react';
import { useDisplayMonetaryAmount } from '../../../../DataPoint';
import { CreateFile, FileListItem } from '../../../../File';
import { useFileAttachmentsForItem } from '../../../../FileAttachment';
import { displayTaxFilingStatusType } from '../../../../TaxReturnSummary';
import { TaxReturnSummary } from '../../../../generated/graphql';
import {
  ClickAnchor,
  ClickMenuContainer,
  IconButton,
} from '../../../components';
import { ArrowDropdownIcon } from '../../../icons/arrowDropDown';

export interface TaxReturnSummaryCardProps {
  householdID: string;
  supportEdit?: boolean;
  taxReturnSummary: TaxReturnSummary;
}

export const TaxReturnSummaryCard: FC<TaxReturnSummaryCardProps> = ({
  supportEdit,
  householdID,
  taxReturnSummary,
}) => {
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();
  const { t: tTaxes } = useTranslation('taxes');
  const [deleteFileListItemOpen, setDeleteFileListItemOpen] = useState(false);
  const { fileAttachments } = useFileAttachmentsForItem({
    itemID: taxReturnSummary.id,
    tenantID: taxReturnSummary.householdID,
  });

  return (
    <motion.div
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="relative h-auto w-auto rounded-2xl bg-[#212326] p-6"
    >
      {supportEdit && (
        <Link className="contents" to={`${taxReturnSummary.id}/edit`}>
          <IconButton
            theme="text"
            diameter={40}
            className="absolute right-4 top-4"
            icon={<span className="material-icons-round">edit</span>}
          />
        </Link>
      )}
      <p
        className="h-[30px] text-3xl font-bold leading-9 text-white"
        style={{ letterSpacing: '-0.31px' }}
      >
        {taxReturnSummary.stringifiedYear}
      </p>
      <p
        className="pb-7 text-base font-medium text-darkMode-gray-light"
        style={{ letterSpacing: '-0.34px' }}
      >
        {displayTaxFilingStatusType(taxReturnSummary.filingStatus, tTaxes)}
      </p>
      <div className="mb-4">
        <p className="h-[18px] text-sm font-normal leading-[18px] text-darkMode-gray-light">
          {tTaxes('income')}
        </p>
        <p className="h-6 text-lg font-medium leading-6 text-white">
          {displayMonetaryAmount(taxReturnSummary.income)}
        </p>
      </div>
      <div className="mb-4">
        <p className="h-[18px] text-sm font-normal leading-[18px] text-darkMode-gray-light">
          {tTaxes('fed-tax-paid')}
        </p>
        <p className="h-6 text-lg font-medium leading-6 text-white">
          {displayMonetaryAmount(taxReturnSummary.federalTaxesPaid)}
        </p>
      </div>
      <div>
        <p className="h-[18px] text-sm font-normal leading-[18px] text-darkMode-gray-light">
          {tTaxes('state-tax-paid')}
        </p>
        <p className="h-6 text-lg font-medium leading-6 text-white">
          {displayMonetaryAmount(taxReturnSummary.stateTaxesPaid)}
        </p>
      </div>

      {!fileAttachments.length ? (
        <CreateFile
          className="mt-[33px]"
          itemID={taxReturnSummary.id}
          label={
            <span className="order-1 ml-2 text-lg text-elements-Tr">
              {tTaxes('tax-card-add-documents')}
            </span>
          }
          tenantID={taxReturnSummary.householdID}
        />
      ) : (
        <ClickAnchor
          inPortal={false}
          offset={[132, 0]}
          placement="top-end"
          ignoreHideTrigger={deleteFileListItemOpen}
          floatingElement={({ setPopperInvisible, setOpen }) => (
            <ClickMenuContainer className="max-h-[80vh] overflow-scroll">
              {fileAttachments.map((fileAttachment) => (
                <FileListItem
                  subtitle={false}
                  tenantID={householdID}
                  key={fileAttachment.id}
                  file={fileAttachment.file}
                  itemID={fileAttachment.itemID}
                  fileAttachmentID={fileAttachment.id}
                  onDeleteSuccess={() => {
                    setOpen(false);
                    setDeleteFileListItemOpen(false);
                  }}
                  onDelete={() => {
                    setPopperInvisible(true);
                    setDeleteFileListItemOpen(true);
                  }}
                  onCancelDelete={() => {
                    setPopperInvisible(false);
                    setDeleteFileListItemOpen(false);
                  }}
                  className="!rounded-none !py-2 px-4 [&_button]:h-8 [&_img]:h-8"
                />
              ))}
            </ClickMenuContainer>
          )}
          popperJSPreventOverflowOptions={{ padding: 24 }}
        >
          <div className="mt-[33px] flex justify-between">
            <span className="text-lg text-elements-Tr">
              {tTaxes('tax-card-tax-documents')}
            </span>
            <ArrowDropdownIcon className="h-6 w-6 text-elements-Tr" />
          </div>
        </ClickAnchor>
      )}
    </motion.div>
  );
};
