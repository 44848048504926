import { ElementsIconProps } from '../components/Icon/types';

export const ArrowForwardIcon = (props: ElementsIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrow_forward_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20876 13H16.3788L11.4988 17.88C11.1088 18.27 11.1088 18.91 11.4988 19.3C11.8888 19.69 12.5188 19.69 12.9088 19.3L19.4988 12.71C19.8888 12.32 19.8888 11.69 19.4988 11.3L12.9188 4.69999C12.7319 4.51273 12.4783 4.4075 12.2138 4.4075C11.9492 4.4075 11.6956 4.51273 11.5088 4.69999C11.1188 5.08999 11.1188 5.71999 11.5088 6.10999L16.3788 11H5.20876C4.65876 11 4.20876 11.45 4.20876 12C4.20876 12.55 4.65876 13 5.20876 13Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_923"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.20876 13H16.3788L11.4988 17.88C11.1088 18.27 11.1088 18.91 11.4988 19.3C11.8888 19.69 12.5188 19.69 12.9088 19.3L19.4988 12.71C19.8888 12.32 19.8888 11.69 19.4988 11.3L12.9188 4.69999C12.7319 4.51273 12.4783 4.4075 12.2138 4.4075C11.9492 4.4075 11.6956 4.51273 11.5088 4.69999C11.1188 5.08999 11.1188 5.71999 11.5088 6.10999L16.3788 11H5.20876C4.65876 11 4.20876 11.45 4.20876 12C4.20876 12.55 4.65876 13 5.20876 13Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_923)"></g>
      </g>
    </svg>
  );
};
