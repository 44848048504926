import { MonetaryAmount, RealEstate } from '../../../../generated/graphql';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAnchorContext } from '../../../components';
import { displayMonetaryAmount } from '../../../../util';
import { displayRealEstateType } from '../../../../RealEstateProperty';
import { displayLoanType } from '../../../../Loan';
import { EquityMenuListItem } from './EquityMenuListItem';
import InsuranceTotalRow from './InsuranceTotalRow';
import { useRealEstateEquity } from '../hooks';
import { useLoansForResidenceAssetLoans } from '../hooks/useLoansForRealEstateAssetLoans';

interface IndividualPrimaryResidenceEquityTableProps {
  residence: RealEstate;
}

export const IndividualPrimaryResidenceEquityTable: FC<IndividualPrimaryResidenceEquityTableProps> = ({
  residence,
}) => {
  const { loansForResidence, finishedLoading } = useLoansForResidenceAssetLoans(residence);
  const { t: tLoan } = useTranslation('loan');
  const { t: tRealEstate } = useTranslation('realEstate');
  const { t: tInsurance } = useTranslation('insurance');
  const { forceFloatingElementUpdate } = useClickAnchorContext();

  const realEstateEquity = useRealEstateEquity(residence, loansForResidence);

  // force the click menu's position recalculation after the loans have loaded
  // done inside a timeout to avoid a flushSync called from inside a lifecycle method error.
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (finishedLoading) {
      timer = setTimeout(() => {
        forceFloatingElementUpdate?.();
      }, 0);
    }
    return () => clearTimeout(timer);
  }, [finishedLoading]);

  return (
    <div key={residence.id} className="mb-8 grid gap-[13px] last:!mb-0">
      <EquityMenuListItem
        title={residence.name}
        valueDisplay={displayMonetaryAmount(residence.value.latestDataPoint?.data.value as MonetaryAmount)}
        subtitle={displayRealEstateType(residence.realEstateType, tRealEstate)}
      />
      {loansForResidence?.map((loan) => (
        <EquityMenuListItem
          key={loan.id}
          title={loan.name}
          subtitle={displayLoanType(loan.loanType, tLoan)}
          valueDisplay={displayMonetaryAmount(loan.balance.latestDataPoint?.data.value as MonetaryAmount, {
            forceNegative: true,
          })}
        />
      ))}
      <InsuranceTotalRow
        title={tInsurance('estimation-click-menu-adjusted-net-worth-real-estate-equity', {
          estateName: residence.name,
        })}
        amountDisplay={displayMonetaryAmount(realEstateEquity)}
      />
    </div>
  );
};
