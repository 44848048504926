import clsx from 'clsx';
import { LoanDetailsSumTableProps } from '..';
import { RecurringFrequency } from '../../generated/graphql';
import { getLastLoanUpdateDate, getLoanBalance, getLoanPaymentsAndExtraPayments } from '../../Loan';
import { ContributionsSumTable, ContributionsSumTableItem } from '../../UI';
import { useLoanDetailsSumTable } from '../hooks';

export function LoanDetailsSumTable({ className, householdID, hideOnEmpty }: LoanDetailsSumTableProps) {
  const {
    tUI,
    loans,
    isLoading,
    annualContributions,
    monthlyContributionsDisplay,
    displayLoanAmountSumTableItemSubtitle,
  } = useLoanDetailsSumTable({ householdID });

  return (
    <ContributionsSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty}
      sum={annualContributions}
      sumAppendix={monthlyContributionsDisplay}
      title={tUI('loan-details-sum-table-title')}
      sumTitle={tUI('loan-details-sum-table-total-title')}
      headers={[
        tUI('contributions-sum-table-account-header'),
        tUI('contributions-sum-table-balance-header'),
        tUI('contributions-sum-table-contributions-payments'),
      ]}
    >
      {loans.map((loan) => (
        <ContributionsSumTableItem
          key={loan.id}
          title={loan.name}
          balance={getLoanBalance(loan)}
          lastBalanceUpdateISO={getLastLoanUpdateDate(loan)}
          subtitle={displayLoanAmountSumTableItemSubtitle(loan)}
          className={clsx('!items-center', '[&_.ContributionsSumTableItem-Title]:line-clamp-2')}
          contributions={getLoanPaymentsAndExtraPayments(loan, { frequency: RecurringFrequency.Monthly })}
        />
      ))}
    </ContributionsSumTable>
  );
}
