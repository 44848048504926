import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCashAccountForm } from '../hooks';
import { CashAccountFormContextProvider } from '../context';

interface CashAccountFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useCashAccountFormReturn: ReturnType<typeof useCashAccountForm>;
}

export const CashAccountForm: FC<CashAccountFormProps> = ({
  children,
  className,
  useCashAccountFormReturn: { formMethods, cashAccount, householdID, onSubmit },
}) => {
  return (
    <FormProvider {...formMethods}>
      <CashAccountFormContextProvider cashAccount={cashAccount} householdID={householdID}>
        <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </CashAccountFormContextProvider>
    </FormProvider>
  );
};
