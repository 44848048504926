import { ElementsIconProps } from '../components/Icon/types';

export function CheckListIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="checklist_24px">
        <g id="Color">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.47 4.63C10.86 5.02 10.86 5.65 10.47 6.04L6.24 10.29C5.85 10.68 5.22 10.68 4.82 10.29L2.7 8.16C2.31 7.77 2.31 7.14 2.7 6.75C3.09 6.36 3.72 6.36 4.11 6.75L5.53 8.17L9.07 4.63C9.45 4.25 10.09 4.25 10.47 4.63ZM21 7C21.55 7 22 7.45 22 8C22 8.55 21.55 9 21 9H14C13.45 9 13 8.55 13 8C13 7.45 13.45 7 14 7H21ZM14 17C13.45 17 13 16.55 13 16C13 15.45 13.45 15 14 15H21C21.55 15 22 15.45 22 16C22 16.55 21.55 17 21 17H14ZM10.48 14.05C10.87 13.66 10.87 13.03 10.48 12.64C10.09 12.25 9.45 12.25 9.07 12.63L5.53 16.17L4.11 14.75C3.72 14.36 3.09 14.36 2.7 14.75C2.31 15.14 2.31 15.77 2.7 16.16L4.83 18.3C5.23 18.69 5.86 18.69 6.25 18.3L10.48 14.05Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
