import { ElementsIconProps } from '../components/Icon/types';

export function MessagesCustomReversedIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="messages_custom_reversed_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.02379 21.9537C8.3402 22.5495 7.93004 22.8229 7.3734 22.8229C6.58239 22.8229 6.1234 22.2662 6.1234 21.3971V19.0045H5.52148C2.70898 19.0045 0.999998 17.3151 0.999998 14.4049V6.6418C0.999998 3.74141 2.66015 2.04219 5.59961 2.04219H18.3812C21.3207 2.04219 23.0018 3.74141 23.0018 6.6418V14.4049C23.0018 17.3053 21.3207 19.0045 18.3812 19.0045H12.3636L9.02379 21.9537ZM7.86168 17.905V20.5808L10.9086 17.5632C11.3773 17.0945 11.6605 16.9968 12.305 16.9968H18.304C20.1302 16.9968 21.0018 16.0691 21.0018 14.2917V6.70039C21.0018 4.92305 20.1302 3.99531 18.304 3.99531H5.70214C3.8662 3.99531 2.99706 4.92305 2.99706 6.70039V14.2917C2.99706 16.0691 3.8662 16.9968 5.70214 16.9968H6.95348C7.51989 16.9968 7.86168 17.3093 7.86168 17.905ZM18.0018 8.75C18.0018 8.33579 17.6661 8 17.2518 8H6.75184C6.33763 8 6.00184 8.33579 6.00184 8.75C6.00184 9.16421 6.33763 9.5 6.75184 9.5H17.2518C17.6661 9.5 18.0018 9.16421 18.0018 8.75ZM14.6786 11.5C15.0928 11.5 15.4286 11.8358 15.4286 12.25C15.4286 12.6642 15.0928 13 14.6786 13H6.75C6.33578 13 6 12.6642 6 12.25C6 11.8358 6.33578 11.5 6.75 11.5H14.6786Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
