import { useTranslation } from 'react-i18next';
import { useModalClickAway } from '../..';
import {
  TargetScoreRangesFormSubmitData,
  getTargetScoreRangesFormFieldPathRange,
  useClientTargetScoreRangesForm,
  useHeadOfHouseholdMaxTargetScoreAgeGroup,
  useUpdateTargetScoreRangesForClient,
  useResetTargetScoreRangeValueForClient,
  useTargetScoreRanges,
  targetScoreRangesFormDefaultValues,
  getTargetScoreRangesInputForAgeGroup,
  getTargetScoreRangesFormRangesMinMaxFieldPaths,
  getTypeForSymbol,
} from '../../../../TargetScore';
import { useEffect, useMemo } from 'react';
import { ScorecardElementSymbol } from '../../../../Scorecard';

export interface UseEditTargetScoreRangeModalArgs {
  firmID: string;
  firmClientID: string;
  closeModal: () => void;
  element: ScorecardElementSymbol;
}

export function useEditTargetScoreRangeModal({
  firmID,
  element,
  closeModal,
  firmClientID,
}: UseEditTargetScoreRangeModalArgs) {
  const { t: tCommon } = useTranslation('common');
  const { t: tTargetScore } = useTranslation('targetScore');
  const { modalRef } = useModalClickAway({ onClickAway: closeModal });
  const { updateTargetScoreRangesForClient } = useUpdateTargetScoreRangesForClient();
  const { resetTargetScoreRangeValueForClient } = useResetTargetScoreRangeValueForClient();
  const { ageGroup } = useHeadOfHouseholdMaxTargetScoreAgeGroup({ firmClientID, firmID });
  const fieldPathRange = getTargetScoreRangesFormFieldPathRange(element, ageGroup);
  const minMaxFieldPaths = getTargetScoreRangesFormRangesMinMaxFieldPaths(element, ageGroup);

  const { targetScoreRangesData } = useTargetScoreRanges({ clientID: firmClientID });
  const targetScoreRanges = targetScoreRangesData?.appliedTemplate.derivedRanges;
  const defaultValues = useMemo(() => targetScoreRangesFormDefaultValues(targetScoreRanges), [targetScoreRanges]);

  const handleSubmit = (data: TargetScoreRangesFormSubmitData) => {
    // If the form values have been set back to the default, call reset instead of update
    if (
      data.formValues.ranges[minMaxFieldPaths.min] &&
      data.formValues.ranges[minMaxFieldPaths.min] == defaultValues.ranges?.[minMaxFieldPaths.min] &&
      data.formValues.ranges[minMaxFieldPaths.max] == defaultValues.ranges?.[minMaxFieldPaths.max]
    ) {
      resetTargetScoreRangeValueForClient.mutate({
        clientID: data.firmClientID,
        value: getTypeForSymbol(element, ageGroup),
      });
    } else {
      updateTargetScoreRangesForClient.mutate({
        clientID: data.firmClientID,
        changes: getTargetScoreRangesInputForAgeGroup({
          ageGroup,
          formValues: data.formValues,
          previousRanges: data.targetScoreRanges,
        }),
      });
    }
    closeModal();
  };

  const clientTargetScoreRangesFormReturn = useClientTargetScoreRangesForm({ firmID, firmClientID, handleSubmit });
  const isUpdating = updateTargetScoreRangesForClient.isLoading;

  useEffect(() => {
    if (!clientTargetScoreRangesFormReturn.formReady) return;
    clientTargetScoreRangesFormReturn.formMethods.setFocus(fieldPathRange.min, { shouldSelect: true });
  }, [
    fieldPathRange.min,
    clientTargetScoreRangesFormReturn.formReady,
    clientTargetScoreRangesFormReturn.formMethods.setFocus,
  ]);

  return { tTargetScore, tCommon, modalRef, clientTargetScoreRangesFormReturn, isUpdating };
}
