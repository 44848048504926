import EmptyIllustration from '../../../assets/EmptyIllustration';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import { FrustratedManFilingTaxes } from '../../../assets';
import {
  AnimatedFabDivider,
  ClientTaxRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
} from '../../components';
import { AddIcon } from '../../icons/add';
import { TaxReturnSummaryCard } from './components';
import { useTaxRateElementDetailViewIndexPage, UseTaxRateElementDetailViewIndexPageArgs } from './hooks';
import { ElementDetailViewIndexPageCommonProps } from '../shared';

interface TaxRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseTaxRateElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {
  supportEdit?: boolean;
  supportCreate?: boolean;
}

export function TaxRateElementDetailViewIndexPage({
  firmID,
  supportEdit,
  firmClientID,
  supportCreate,
  downloadReportButton,
  showScoreHealthRange,
}: TaxRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID, sortedTaxes, isLoading } = useTaxRateElementDetailViewIndexPage({
    firmID,
    firmClientID,
  });

  if (isLoading || !householdID || !firmClientID || !firmID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('tax-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('tax-rate-element-detail-view-header-tooltip-description')}
      />

      <ScorecardElementCalculationGroup className="mb-12" element="Tr" householdID={householdID} />
      {showScoreHealthRange && <ClientTaxRateScoreHealthRange firmClientID={firmClientID} firmID={firmID} />}
      <ElementEducationVideoTile
        thumbNail={<FrustratedManFilingTaxes />}
        body={tPages('tax-rate-element-detail-view-learn-more-body')}
        label={tPages('tax-rate-element-detail-view-learn-more-label')}
      />
      {supportCreate ? (
        <AnimatedFabDivider
          to="add"
          hoverWidth={166}
          className="mt-12"
          label={tPages('tax-rate-element-detail-view-fab-label')}
          icon={<AddIcon className="text-darkMode-gray-medium mix-blend-difference" />}
        />
      ) : (
        <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
      )}
      {sortedTaxes.length ? (
        <div className="grid grid-flow-row gap-6 mt-12" style={{ gridTemplateColumns: '1fr 1fr' }}>
          {sortedTaxes.map((t) => (
            <TaxReturnSummaryCard key={t.id} taxReturnSummary={t} supportEdit={supportEdit} householdID={householdID} />
          ))}
        </div>
      ) : (
        <EmptyIllustration className="mx-auto mt-14 text-elements-Tr" />
      )}
    </ElementDetailViewIndexPageContainer>
  );
}
