import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { InvestmentMix } from '../../generated/graphql';
import { InvestmentFormValues, getInvestmentMixDropdownOptionSubtitle } from '..';
import { displayInvestmentMix } from '../display';

interface InvestmentMixDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const InvestmentMixDropdown: FC<InvestmentMixDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tCommon } = useTranslation('common');
  const { t: tInvestment } = useTranslation('investment');
  const { control } = useFormContext<InvestmentFormValues>();
  const { field, fieldState } = useController<InvestmentFormValues, 'investmentMix'>({
    control,
    name: 'investmentMix',
    rules: { required: true },
  });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      withClearSelection
      onChange={(value) => field.onChange(value)}
      label={tForm('investment-mix-dropdown-label')}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
    >
      <DropdownOption
        id={InvestmentMix.DontKnow}
        value={InvestmentMix.DontKnow}
        label={displayInvestmentMix(InvestmentMix.DontKnow, tInvestment)}
      />
      <DropdownOption
        id={InvestmentMix.VeryConservative}
        value={InvestmentMix.VeryConservative}
        label={displayInvestmentMix(InvestmentMix.VeryConservative, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.VeryConservative, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.Conservative}
        value={InvestmentMix.Conservative}
        label={displayInvestmentMix(InvestmentMix.Conservative, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.Conservative, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.Moderate}
        value={InvestmentMix.Moderate}
        label={displayInvestmentMix(InvestmentMix.Moderate, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.Moderate, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.ModerateGrowth}
        value={InvestmentMix.ModerateGrowth}
        label={displayInvestmentMix(InvestmentMix.ModerateGrowth, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.ModerateGrowth, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.Balanced}
        value={InvestmentMix.Balanced}
        label={displayInvestmentMix(InvestmentMix.Balanced, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.Balanced, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.BalancedGrowth}
        value={InvestmentMix.BalancedGrowth}
        label={displayInvestmentMix(InvestmentMix.BalancedGrowth, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.BalancedGrowth, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.Growth}
        value={InvestmentMix.Growth}
        label={displayInvestmentMix(InvestmentMix.Growth, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.Growth, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.AggressiveGrowth}
        value={InvestmentMix.AggressiveGrowth}
        label={displayInvestmentMix(InvestmentMix.AggressiveGrowth, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.AggressiveGrowth, tCommon)}
      />
      <DropdownOption
        id={InvestmentMix.EquityGrowth}
        value={InvestmentMix.EquityGrowth}
        label={displayInvestmentMix(InvestmentMix.EquityGrowth, tInvestment)}
        subtitle={getInvestmentMixDropdownOptionSubtitle(InvestmentMix.EquityGrowth, tCommon)}
      />
    </Dropdown>
  );
};
