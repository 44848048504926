import { sub } from 'date-fns';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import { RecurringFrequency } from '../../../generated/graphql';
import {
  Divider,
  EditSpendingWidget,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
  IncomeUpdatesTable,
  SpendingUpdatesTable,
  ClientBurnRateScoreHealthRange,
} from '../../components';
import { UseBurnRateElementDetailViewIndexPageArgs, useBurnRateElementDetailViewIndexPage } from './hooks';
import { ElementDetailViewIndexPageCommonProps } from '../shared';

interface BurnRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseBurnRateElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function BurnRateElementDetailViewIndexPage({
  firmID,
  firmClientID,
  showScoreHealthRange,
  downloadReportButton,
}: BurnRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useBurnRateElementDetailViewIndexPage({
    firmClientID,
    firmID,
  });

  if (!firmClientID || !firmID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('burn-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('burn-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Br" householdID={householdID} />
      {showScoreHealthRange && <ClientBurnRateScoreHealthRange firmClientID={firmClientID} firmID={firmID} />}
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('burn-rate-element-detail-view-learn-more-body')}
        label={tPages('burn-rate-element-detail-view-learn-more-label')}
      />
      <EditSpendingWidget firmID={firmID} firmClientID={firmClientID} toFrequency={RecurringFrequency.Monthly} />
      <SpendingUpdatesTable
        firmID={firmID}
        className="mt-11"
        firmClientID={firmClientID}
        formatDistanceStrictOptions={{ unit: 'month' }}
        fromISODate={sub(new Date(), { years: 1 }).toISOString()}
      />
      <IncomeUpdatesTable className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
