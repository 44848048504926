import { ElementsIconProps } from '../components/Icon/types';

export function AutoRenewIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="autorenew_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.99983V7.78983C12 8.23983 12.54 8.45983 12.85 8.13983L15.64 5.34983C15.84 5.14983 15.84 4.83983 15.64 4.63983L12.85 1.84983C12.54 1.53983 12 1.75983 12 2.20983V3.99983C7.58 3.99983 4 7.57983 4 11.9998C4 13.0398 4.2 14.0398 4.57 14.9498C4.84 15.6198 5.7 15.7998 6.21 15.2898C6.48 15.0198 6.59 14.6098 6.44 14.2498C6.15 13.5598 6 12.7898 6 11.9998C6 8.68983 8.69 5.99983 12 5.99983ZM17.79 8.70983C17.52 8.97983 17.41 9.39983 17.56 9.74983C17.84 10.4498 18 11.2098 18 11.9998C18 15.3098 15.31 17.9998 12 17.9998V16.2098C12 15.7598 11.46 15.5398 11.15 15.8598L8.36 18.6498C8.16 18.8498 8.16 19.1598 8.36 19.3598L11.15 22.1498C11.46 22.4598 12 22.2398 12 21.7998V19.9998C16.42 19.9998 20 16.4198 20 11.9998C20 10.9598 19.8 9.95983 19.43 9.04983C19.16 8.37983 18.3 8.19983 17.79 8.70983Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_48"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="1"
          width="16"
          height="22"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.99983V7.78983C12 8.23983 12.54 8.45983 12.85 8.13983L15.64 5.34983C15.84 5.14983 15.84 4.83983 15.64 4.63983L12.85 1.84983C12.54 1.53983 12 1.75983 12 2.20983V3.99983C7.58 3.99983 4 7.57983 4 11.9998C4 13.0398 4.2 14.0398 4.57 14.9498C4.84 15.6198 5.7 15.7998 6.21 15.2898C6.48 15.0198 6.59 14.6098 6.44 14.2498C6.15 13.5598 6 12.7898 6 11.9998C6 8.68983 8.69 5.99983 12 5.99983ZM17.79 8.70983C17.52 8.97983 17.41 9.39983 17.56 9.74983C17.84 10.4498 18 11.2098 18 11.9998C18 15.3098 15.31 17.9998 12 17.9998V16.2098C12 15.7598 11.46 15.5398 11.15 15.8598L8.36 18.6498C8.16 18.8498 8.16 19.1598 8.36 19.3598L11.15 22.1498C11.46 22.4598 12 22.2398 12 21.7998V19.9998C16.42 19.9998 20 16.4198 20 11.9998C20 10.9598 19.8 9.95983 19.43 9.04983C19.16 8.37983 18.3 8.19983 17.79 8.70983Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_48)"></g>
      </g>
    </svg>
  );
}
