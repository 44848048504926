import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSubmitOnChange } from '../../UI/components/Form/hooks';
import { FirmClient, RecurringFrequency } from '../../generated/graphql';
import { useUpdateClientSpendingService } from '../service';
import {
  ClientSpendingFormValues,
  UpdateClientSpendingServiceMutationOptions,
} from '../types';
import { clientSpendingFormDefaultValues } from '../util';

export interface UseClientSpendingFormArgs {
  firmClient: FirmClient;
  saveOnChange?: boolean;
  onChange?: (data: ClientSpendingFormValues) => void;
  updateClientSpendingServiceMutationOptions?: UpdateClientSpendingServiceMutationOptions;
}

export const useClientSpendingForm = ({
  onChange,
  firmClient,
  saveOnChange,
  updateClientSpendingServiceMutationOptions,
}: UseClientSpendingFormArgs) => {
  const { updateClientSpendingService } = useUpdateClientSpendingService();

  const methods = useForm<ClientSpendingFormValues>({
    defaultValues: clientSpendingFormDefaultValues(firmClient),
  });

  const onSubmit: SubmitHandler<ClientSpendingFormValues> = useCallback(
    (data) => {
      onChange?.(data);
      if (!firmClient.household) return;

      updateClientSpendingService.mutate(
        {
          householdID: firmClient.household.id,
          spendingInCents: (data.spending?.amount ?? 0) * 100,
          spendingDataPointsGroupID: firmClient.household.spending.id,
          frequency: data.spending?.frequency ?? RecurringFrequency.Annually,
        },
        updateClientSpendingServiceMutationOptions
      );
    },
    [firmClient]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: methods.watch,
    enabled: saveOnChange,
    handleSubmit: methods.handleSubmit,
  });

  return { formMethods: methods, onSubmit };
};
