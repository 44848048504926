import { FC } from 'react';
import { InvestmentFormValues } from '..';
import { PercentageInput } from '../../UI';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

interface InvestmentOwnershipAllocationInputProps {
  className?: string;
}

export const InvestmentOwnershipAllocationInput: FC<InvestmentOwnershipAllocationInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<InvestmentFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<InvestmentFormValues, 'ownershipAllocation'>({
    control,
    name: 'ownershipAllocation',
    rules: { required: true },
  });

  return (
    <PercentageInput
      {...field}
      decimalsLimit={3}
      className={className}
      placeholder={tForm('general-required-placeholder')}
      label={tForm('general-ownership-allocation-input')}
      status={errors.ownershipAllocation ? 'errored' : 'normal'}
    />
  );
};
