import { TargetScoreInputContainer } from '../../UI';
import { TargetScoreTermRateInput } from './TargetScoreRangeTermInput';
import { TargetScoreRangeRateInput } from './TargetScoreRangeRateInput';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { ScorecardElementSymbol, isScorecardTermElement } from '../../Scorecard';

export interface TargetScoreRangeOperatorInputProps<
  formValues extends FieldValues,
  minPath extends FieldPath<formValues>,
  maxPath extends FieldPath<formValues>
> {
  modified?: boolean;
  showErrorState: boolean;
  element: ScorecardElementSymbol;
  operatorToggleButton: React.ReactNode;
  minField: ControllerRenderProps<formValues, minPath>;
  maxField: ControllerRenderProps<formValues, maxPath>;
}

export function TargetScoreRangeOperatorInput<
  formValues extends FieldValues,
  minPath extends FieldPath<formValues>,
  maxPath extends FieldPath<formValues>
>({
  element,
  modified,
  maxField,
  minField,
  showErrorState,
  operatorToggleButton,
}: TargetScoreRangeOperatorInputProps<formValues, minPath, maxPath>) {
  return (
    <TargetScoreInputContainer element={element} showErrorState={showErrorState} highlight={modified}>
      {isScorecardTermElement(element) ? (
        <TargetScoreTermRateInput {...minField} highlight={modified} />
      ) : (
        <TargetScoreRangeRateInput {...minField} highlight={modified} />
      )}
      {operatorToggleButton}
      {isScorecardTermElement(element) ? (
        <TargetScoreTermRateInput {...maxField} highlight={modified} />
      ) : (
        <TargetScoreRangeRateInput {...maxField} highlight={modified} />
      )}
    </TargetScoreInputContainer>
  );
}
