import { useTaxReturnSummaries } from '../../../../TaxReturnSummary';
import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseTaxRateElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useTaxRateElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseTaxRateElementDetailViewIndexPageArgs
) {
  const { householdID, ...useElementDetailViewIndexPageReturn } = useElementDetailViewIndexPage(
    useElementDetailViewIndexPageArgs
  );
  const { taxReturnSummaries, taxReturnSummariesQuery } = useTaxReturnSummaries({ householdID });

  const sortedTaxes = [...taxReturnSummaries].sort((a, b) => b.year - a.year);
  const isLoading = taxReturnSummariesQuery.isLoading;

  return {
    isLoading,
    householdID,
    sortedTaxes,
    ...useElementDetailViewIndexPageReturn,
  };
}
