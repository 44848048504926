import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputElementProps, InputProps, MonetaryInput } from '../../UI';
import { RealEstateFormValues } from '../types';
import { FC } from 'react';

type RealEstateEstimatedValueInputProps = Pick<InputProps, 'className'> &
  Pick<InputElementProps, 'required'>;

export const RealEstateEstimatedValueInput: FC<
  RealEstateEstimatedValueInputProps
> = ({ className, required = true }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<RealEstateFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<RealEstateFormValues, 'estimatedValue'>({
    control,
    rules: { required },
    name: 'estimatedValue',
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={errors.estimatedValue ? 'errored' : 'normal'}
      label={tForm('real-estate-property-estimated-value-input-label')}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
};
