import { ElementsIconProps } from '../components/Icon/types';

export function ArrowLeftIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_left_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5862 8.70624L9.99624 11.2962C9.60624 11.6862 9.60624 12.3162 9.99624 12.7062L12.5862 15.2962C13.2162 15.9262 14.2962 15.4762 14.2962 14.5862V9.40624C14.2962 8.51624 13.2162 8.07624 12.5862 8.70624Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_931"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="8"
          width="6"
          height="8"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5862 8.70624L9.99624 11.2962C9.60624 11.6862 9.60624 12.3162 9.99624 12.7062L12.5862 15.2962C13.2162 15.9262 14.2962 15.4762 14.2962 14.5862V9.40624C14.2962 8.51624 13.2162 8.07624 12.5862 8.70624Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_931)"></g>
      </g>
    </svg>
  );
}
