import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseQualifiedTermElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useQualifiedTermElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseQualifiedTermElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
