{
  "insurance": "Insurance",

  "layout-page-heading": "Insurance Rate",
  "layout-page-download-report": "Download Report",
  "layout-page-description-tooltip": "Measures how much insurance you have compared to how much you might need based on factors like spending, income, and net worth.",

  "InsuranceIndexPage-add-insurance-button-label": "Add Insurance Policy",

  "ChartSegmentedControl-insurance-rate-option": "Insurance Rate",
  "ChartSegmentedControl-life-option": "Life",
  "ChartSegmentedControl-disability-option": "Disability",
  "ChartSegmentedControl-liability-option": "Liability",

  "grouping-empty": "No {{type}} policies added.",
  "grouping-total": "{{title}} Personal Coverage",
  "group-item-term-duration": "{{duration}}-Year Term",
  "group-item-term-expiration": " (Exp. {{expiration}})",
  "coverage-option": "{{years}} years",
  "policy-nickname": "Policy Nickname",
  "coverage-amount": "Coverage Amount",
  "monthly-benefit": "Monthly Benefit",
  "purchased-date": "Purchased Date",
  "length-of-coverage": "Length of Coverage",
  "expiration-date": "Expiration Date",
  "delete-policy-title": "Delete \"{{- name}}\"",
  "delete-policy-description": "Deleting this {{type}} policy will remove it from all associated calculations and cannot be undone.",
  "delete-policy": "Delete Policy",
  "add-insurance-policy": "Add Insurance Policy",
  "life-coverage": "Life Insurance Personal Coverage",
  "disability-coverage": "Disability Insurance Personal Coverage",
  "liability-coverage": "Liability Insurance Personal Coverage",
  "life-insurance": "Personal Life Insurance",
  "disability-insurance": "Personal Disability Insurance",
  "liability": "Liability",
  "term-life-insurance": "Term Life Insurance",
  "insurance-rate-element-detail-view-calculation-breakdown-life-insurance": "Life Insurance",
  "insurance-rate-element-detail-view-calculation-breakdown-disability-insurance": "Disability Insurance",
  "insurance-rate-element-detail-view-calculation-breakdown-liability-insurance": "Liability Insurance",
  "cash-value-life-insurance": "Cash-Value Life Insurance",
  "buy-sell-life-insurance": "Buy-Sell Life Insurance",
  "key-person-life-insurance": "Key Person Life Insurance",
  "personal-disability": "Personal Disability",
  "business-overhead": "Business Overhead",
  "personal-umbrella": "Personal Umbrella",
  "malpractice": "Malpractice",
  "business-liability": "Business Liability",
  "liability-insurance": "Liability Insurance",
  "personal-umbrella-insurance": "Personal Umbrella",
  "empty-view-description": "Help your client get their insurance policies organized so they can understand if their coverage is appropriate for their situation.",

  "insurance-form-related-documents": "Related Documents",
  "insurance-form-add-related-documents": "Add Related Documents",

  "calculation-factors-box-title": "Calculation Factors",
  "calculation-factors-box-liability": "Personal Liability Insurance Coverage",
  "calculation-factors-box-disability": "{{- givenName}}’s Personal Disability Insurance Coverage",
  "calculation-factors-box-life": "{{- givenName}}’s Personal Life Insurance Coverage",

  "calculation-breakdown-person-total-coverage": "{{- givenName}}'s Total Personal Coverage",
  "calculation-breakdown-total-coverage": "Total Personal Coverage",
  "calculation-breakdown-estimated-needed": "Estimated Needed",
  "calculation-breakdown-rate": "Rate",

  "life-calculation-breakdown-total-coverage-tooltip": "View personal life insurance policies",
  "life-calculation-breakdown-needs-tooltip": "View calculation details",

  "disability-calculation-breakdown-incomes-title": "{{- givenName}}'s Income",
  "disability-calculation-breakdown-total-coverage-tooltip": "View personal disability insurance policies",
  "disability-calculation-breakdown-incomes-tooltip": "View income sources",
  "disability-calculation-breakdown-incomes-tooltip-total-row": "Current Income",
  "disability-calculation-breakdown-incomes-empty": "{{- givenName}} has no income",

  "liability-calculation-breakdown-total-coverage-tooltip": "View personal liability insurance policies",
  "liability-calculation-breakdown-net-worth-title": "Current Net Worth",

  "life-coverage-click-menu-title": "{{- givenName}}'s Personal Life Coverage",
  "life-coverage-click-menu-no-policies": "{{- givenName}} has no personal life insurance",

  "disability-coverage-click-menu-title": "{{- givenName}}'s Personal Disability Coverage",
  "disability-coverage-click-menu-no-policies": "{{- givenName}} has no personal disability insurance",

  "liability-coverage-click-menu-title": "Personal Liability Coverage",
  "liability-coverage-click-menu-no-policies": "No personal liability insurance",

  "estimation-click-menu-title": "Estimated Life Insurance Need",
  "estimation-click-menu-spending-needs": "Spending Needs",
  "estimation-click-menu-adjusted-net-worth": "Adjusted Net Worth",
  "estimation-click-menu-adjusted-net-worth-business-equity": "Business Equity",
  "estimation-click-menu-adjusted-net-worth-individual-business-equity": "{{- businessName}} Equity",
  "estimation-click-menu-adjusted-net-worth-real-estate": "Primary Real Estate Equity",
  "estimation-click-menu-adjusted-net-worth-real-estate-equity": "{{- estateName}} Equity",
  "estimation-click-menu-net-worth": "Net Worth",
  "estimation-click-menu-estimated-need": "Estimated Need",
  "estimation-click-menu-spending-needs-footnote": "a",
  "estimation-click-menu-adjusted-net-worth-footnote": "b",
  "estimation-click-menu-spending-needs-annual-spending": "Annual Spending",
  "estimation-click-menu-spending-multiplier": "30yrs",
  "estimation-click-menu-spending-estimated-need": "Estimated Spending Needs",
  "estimation-click-menu-business": "Business",

  "insurance-calculation-tooltip-body": "Insurance rate is an average of all insurance coverages.",

  "error-unable-to-create": "Unable to create insurance policy. Please try again later",
  "error-unable-to-delete": "Unable to delete insurance policy. Please try again later",
  "error-unable-to-update": "Unable to update insurance policy. Please try again later",

  "learn-more-label": "learn more",
  "learn-more-body": "Are you comfortable with the amount of risk you're taking?",

  "insurance-index-page-calculation-details-heading": "Calculation Details"
}
