import { ElementsIconProps } from '../components/Icon/types';

export function DraftsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="drafts_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.99 9.34375C21.99 8.62375 21.62 7.99375 21.05 7.64375L13.01 2.93375C12.39 2.56375 11.61 2.56375 10.99 2.93375L2.95 7.64375C2.38 7.99375 2 8.62375 2 9.34375V19.3438C2 20.4438 2.9 21.3438 4 21.3438H20C21.1 21.3438 22 20.4438 22 19.3438L21.99 9.34375ZM10.94 13.6838L3.74 9.18375L10.99 4.93375C11.61 4.56375 12.39 4.56375 13.01 4.93375L20.26 9.18375L13.06 13.6838C12.41 14.0837 11.59 14.0837 10.94 13.6838Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_832"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.99 9.34375C21.99 8.62375 21.62 7.99375 21.05 7.64375L13.01 2.93375C12.39 2.56375 11.61 2.56375 10.99 2.93375L2.95 7.64375C2.38 7.99375 2 8.62375 2 9.34375V19.3438C2 20.4438 2.9 21.3438 4 21.3438H20C21.1 21.3438 22 20.4438 22 19.3438L21.99 9.34375ZM10.94 13.6838L3.74 9.18375L10.99 4.93375C11.61 4.56375 12.39 4.56375 13.01 4.93375L20.26 9.18375L13.06 13.6838C12.41 14.0837 11.59 14.0837 10.94 13.6838Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_832)"></g>
      </g>
    </svg>
  );
}
