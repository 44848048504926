import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSetFormDefaultValues } from '../../UI';
import { useFormMemoDefaultValues, useSubmitOnChange } from '../../UI/';
import { CashAccountFormSubmitData, CashAccountFormValues } from '../types';
import { cashAccountFormDefaultValues } from '../util';
import { useCashAccount } from '../queries';

export interface UseCashAccountFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  cashAccountID?: string | null;
  defaultValueOverrides?: Partial<CashAccountFormValues>;
  handleSubmit?: (data: CashAccountFormSubmitData) => void;
}

export const useCashAccountForm = ({
  householdID,
  saveOnChange,
  handleSubmit,
  cashAccountID,
  defaultValueOverrides,
}: UseCashAccountFormArgs) => {
  const formMethods = useForm<CashAccountFormValues>();
  const { cashAccount, cashAccountQuery } = useCashAccount({ cashAccountID, householdID });
  const defaultValues = useFormMemoDefaultValues(cashAccountFormDefaultValues(cashAccount, defaultValueOverrides));

  const isLoadingFormData = cashAccountQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const onSubmit: SubmitHandler<CashAccountFormValues> = useCallback(
    (data) => {
      handleSubmit?.({ formValues: data, householdID, changeToken: cashAccount?.changeToken, cashAccountID });
    },
    [cashAccountID, handleSubmit, householdID, cashAccount?.changeToken]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    enabled: saveOnChange,
    handleSubmit: formMethods.handleSubmit,
  });

  return { formMethods, onSubmit, isLoadingFormData, formReady, cashAccount, householdID };
};
