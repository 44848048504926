import { ElementsIconProps } from '../components/Icon/types';

export function FirstPageIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="first_page_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6988 15.89L13.8188 12L17.7088 8.11C18.0988 7.72 18.0988 7.09 17.7088 6.7C17.5219 6.51275 17.2683 6.40751 17.0038 6.40751C16.7393 6.40751 16.4856 6.51275 16.2988 6.7L11.7088 11.29C11.3188 11.68 11.3188 12.31 11.7088 12.7L16.2988 17.29C16.6888 17.68 17.3188 17.68 17.7088 17.29C18.0888 16.91 18.0888 16.27 17.6988 15.89ZM6.99878 6C7.54878 6 7.99878 6.45 7.99878 7V17C7.99878 17.55 7.54878 18 6.99878 18C6.44878 18 5.99878 17.55 5.99878 17V7C5.99878 6.45 6.44878 6 6.99878 6Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_980"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="6"
          width="14"
          height="12"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6988 15.89L13.8188 12L17.7088 8.11C18.0988 7.72 18.0988 7.09 17.7088 6.7C17.5219 6.51275 17.2683 6.40751 17.0038 6.40751C16.7393 6.40751 16.4856 6.51275 16.2988 6.7L11.7088 11.29C11.3188 11.68 11.3188 12.31 11.7088 12.7L16.2988 17.29C16.6888 17.68 17.3188 17.68 17.7088 17.29C18.0888 16.91 18.0888 16.27 17.6988 15.89ZM6.99878 6C7.54878 6 7.99878 6.45 7.99878 7V17C7.99878 17.55 7.54878 18 6.99878 18C6.44878 18 5.99878 17.55 5.99878 17V7C5.99878 6.45 6.44878 6 6.99878 6Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_980)"></g>
      </g>
    </svg>
  );
}
