import { StudentLoansSummarySumTable } from '../../../TtDetails';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewStudentLoansPageProps } from './types';
import { SummaryPageContainer } from './components';

export function TotalTermElementDetailViewStudentLoansPage({
  householdID,
}: TotalTermElementDetailViewStudentLoansPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-student-loans-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-student-loans-summary-page-empty-description')}
    >
      <StudentLoansSummarySumTable hideOnEmpty className="mt-10" householdID={householdID} />
    </SummaryPageContainer>
  );
}
