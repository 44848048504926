import { ElementsIconProps } from '../components/Icon/types';

export function MergeDirectionIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="merge_direction_24px">
        <g id="shortcut_black_24dp-2">
          <path
            id="Path"
            d="M10.29 3.71L6.7 7.3C6.08 7.92 6.52 9 7.41 9H10V16C10 18.76 12.24 21 15 21H18C18.55 21 19 20.55 19 20C19 19.45 18.55 19 18 19H15C13.35 19 12 17.65 12 16V9H14.59C15.48 9 15.93 7.92 15.3 7.29L11.71 3.7C11.32 3.32 10.68 3.32 10.29 3.71Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
