import { ElementsIconProps } from '../components/Icon/types';

export function DownloadOnAppStoreButton(props: ElementsIconProps) {
  return (
    <svg
      width="180"
      height="61"
      viewBox="0 0 180 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M165.202 0.317578H14.302C13.752 0.317578 13.2085 0.317578 12.6599 0.320578C12.2007 0.323578 11.7451 0.332293 11.2815 0.339628C10.2743 0.351481 9.26941 0.440092 8.27565 0.604693C7.28329 0.772877 6.32201 1.08995 5.42433 1.54519C4.52775 2.00429 3.70852 2.60083 2.99636 3.31318C2.28045 4.02353 1.68369 4.8446 1.22903 5.74482C0.7731 6.64324 0.456961 7.60599 0.291525 8.5998C0.124516 9.59236 0.0346476 10.5964 0.022725 11.6028C0.008805 12.0627 0.007335 12.5241 0 12.9841V47.6555C0.007335 48.1212 0.008805 48.5724 0.022725 49.0383C0.0346512 50.0447 0.12452 51.0487 0.291525 52.0412C0.456504 53.0356 0.772662 53.9989 1.22903 54.8976C1.68349 55.7949 2.28033 56.6127 2.99636 57.3191C3.70582 58.0346 4.52558 58.6315 5.42433 59.0871C6.322 59.5436 7.28318 59.8626 8.27565 60.0333C9.26958 60.1966 10.2743 60.2853 11.2815 60.2985C11.7451 60.3087 12.2007 60.3146 12.6599 60.3146C13.2085 60.3176 13.752 60.3176 14.302 60.3176H165.202C165.741 60.3176 166.289 60.3176 166.828 60.3146C167.285 60.3146 167.754 60.3087 168.211 60.2985C169.216 60.286 170.219 60.1973 171.211 60.0333C172.207 59.8613 173.171 59.5425 174.073 59.0871C174.971 58.6313 175.79 58.0344 176.499 57.3191C177.213 56.6099 177.811 55.7928 178.271 54.8976C178.724 53.9982 179.038 53.035 179.2 52.0412C179.367 51.0485 179.46 50.0448 179.479 49.0383C179.484 48.5724 179.484 48.1212 179.484 47.6555C179.496 47.1105 179.496 46.5686 179.496 46.0148V14.6218C179.496 14.0725 179.496 13.5275 179.484 12.9841C179.484 12.5241 179.484 12.0627 179.479 11.6027C179.46 10.5962 179.367 9.59243 179.2 8.59974C179.037 7.6065 178.724 6.64383 178.271 5.74476C177.346 3.94037 175.877 2.47162 174.073 1.54506C173.171 1.09093 172.207 0.773938 171.211 0.604558C170.219 0.439232 169.216 0.350589 168.211 0.339418C167.754 0.332098 167.285 0.323308 166.828 0.320383C166.289 0.317383 165.741 0.317383 165.202 0.317383V0.317578Z"
        fill="black"
      />
      <path
        d="M12.6673 59.0051C12.2103 59.0051 11.7643 58.9992 11.3109 58.989C10.3715 58.9768 9.43441 58.895 8.50716 58.7444C7.64254 58.5955 6.80496 58.3185 6.02205 57.9225C5.24631 57.5298 4.53877 57.015 3.92655 56.3976C3.30546 55.7875 2.78837 55.0799 2.39578 54.3029C1.99886 53.5207 1.72417 52.6824 1.58128 51.8171C1.42697 50.8872 1.34348 49.947 1.33153 49.0046C1.32202 48.6882 1.30957 47.6349 1.30957 47.6349V12.9841C1.30957 12.9841 1.32283 11.947 1.33161 11.6423C1.34304 10.7013 1.42605 9.76255 1.5799 8.83416C1.72306 7.96644 1.99796 7.12569 2.39509 6.34101C2.78624 5.56448 3.30045 4.85636 3.9178 4.24409C4.53445 3.626 5.24425 3.10847 6.02131 2.71038C6.80243 2.31571 7.63842 2.04066 8.50129 1.89447C9.43159 1.74232 10.372 1.66006 11.3146 1.64838L12.668 1.63007H166.821L168.191 1.64912C169.125 1.66021 170.056 1.74174 170.978 1.893C171.85 2.04103 172.694 2.31799 173.484 2.71478C175.041 3.51705 176.308 4.78631 177.107 6.34467C177.498 7.12393 177.768 7.95783 177.91 8.81805C178.065 9.75405 178.153 10.7002 178.17 11.6489C178.175 12.0737 178.175 12.53 178.175 12.9841C178.187 13.5466 178.187 14.082 178.187 14.6218V46.0148C178.187 46.5597 178.187 47.0915 178.175 47.6276C178.175 48.1154 178.175 48.5622 178.169 49.0221C178.151 49.9539 178.066 50.8831 177.913 51.8024C177.773 52.674 177.499 53.5188 177.103 54.3074C176.707 55.076 176.193 55.7775 175.579 56.3859C174.966 57.0066 174.258 57.5245 173.48 57.9197C172.692 58.3187 171.849 58.5967 170.978 58.7444C170.051 58.8958 169.114 58.9776 168.174 58.989C167.735 58.9992 167.275 59.0051 166.828 59.0051L165.202 59.0081L12.6673 59.0051Z"
        fill="white"
      />
      <path
        d="M37.4951 30.1516C37.5292 27.5054 38.9165 25.061 41.1709 23.675C39.7422 21.6346 37.435 20.3873 34.9453 20.3094C32.326 20.0344 29.7867 21.8768 28.4518 21.8768C27.0912 21.8768 25.0362 20.3367 22.8231 20.3822C19.9114 20.4763 17.2627 22.0916 15.8461 24.6373C12.8294 29.8603 15.0796 37.5363 17.9694 41.7583C19.4152 43.8256 21.1049 46.1349 23.3159 46.053C25.4795 45.9632 26.2876 44.6733 28.8992 44.6733C31.4866 44.6733 32.2447 46.053 34.5006 46.0009C36.8223 45.9632 38.285 43.9244 39.6801 41.8375C40.7189 40.3644 41.5183 38.7364 42.0486 37.0136C39.2912 35.8474 37.4983 33.1455 37.4951 30.1516Z"
        fill="black"
      />
      <path
        d="M33.2343 17.5328C34.5001 16.0132 35.1238 14.06 34.9728 12.088C33.0388 12.2911 31.2524 13.2154 29.9695 14.6767C28.7026 16.1185 28.0608 18.0047 28.1854 19.9199C30.1453 19.9401 32.0062 19.0603 33.2343 17.5328Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.3525 41.027H63.4526L65.1562 46.0617H68.2148L61.4897 27.4347H58.3652L51.6401 46.0617H54.6475L56.3525 41.027ZM62.7159 38.7038H57.0879L59.8638 30.5329H59.9415L62.7159 38.7038Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.0713 46.2038C80.48 46.2038 82.7388 43.4924 82.7388 39.2722C82.7388 35.0637 80.4668 32.3391 77.02 32.3391C75.2464 32.2819 73.585 33.2043 72.6958 34.7399H72.6445V32.4812H69.9463V50.5544H72.7339V43.8278H72.7984C73.6498 45.3731 75.3094 46.296 77.0713 46.2038ZM76.2847 34.715C78.4527 34.715 79.8736 36.5227 79.8736 39.2722H79.8735C79.8735 42.0466 78.4527 43.841 76.2847 43.841C74.1548 43.841 72.7222 42.0085 72.7222 39.2722C72.7222 36.5608 74.1548 34.715 76.2847 34.715Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0186 46.2038C95.4273 46.2038 97.6868 43.4924 97.6868 39.2722C97.6868 35.0637 95.4141 32.3391 91.9673 32.3391C90.1937 32.2819 88.5323 33.2043 87.6431 34.7399H87.5918V32.4812H84.8936V50.5544H87.6812V43.8278H87.7457C88.597 45.3731 90.2567 46.296 92.0186 46.2038ZM91.232 34.715C93.4 34.715 94.8209 36.5227 94.8209 39.2722C94.8209 42.0466 93.4 43.841 91.232 43.841C89.1021 43.841 87.6695 42.0085 87.6695 39.2722C87.6695 36.5608 89.1021 34.715 91.232 34.715Z"
        fill="black"
      />
      <path
        d="M107.564 40.8718C107.771 42.719 109.565 43.9318 112.017 43.9318C114.367 43.9318 116.057 42.7189 116.057 41.0534C116.057 39.6076 115.038 38.7419 112.624 38.1486L110.21 37.567C106.789 36.7409 105.201 35.1412 105.201 32.5455C105.201 29.3317 108.002 27.1241 111.978 27.1241C115.915 27.1241 118.614 29.3317 118.704 32.5455H115.89C115.722 30.6866 114.185 29.5646 111.94 29.5646C109.694 29.5646 108.158 30.6998 108.158 32.3522C108.158 33.6691 109.139 34.444 111.54 35.0372L113.592 35.5411C117.414 36.4449 119 37.9801 119 40.7047C119 44.1895 116.226 46.3721 111.811 46.3721C107.68 46.3721 104.891 44.2408 104.711 40.8716L107.564 40.8718Z"
        fill="black"
      />
      <path
        d="M125.019 29.2673V32.4811H127.602V34.6887H125.019V42.1755C125.019 43.3386 125.536 43.8806 126.672 43.8806C126.978 43.8752 127.284 43.8537 127.589 43.8161V46.0104C127.078 46.1058 126.559 46.149 126.04 46.1393C123.291 46.1393 122.218 45.1066 122.218 42.4728V34.6887H120.244V32.4811H122.218V29.2673H125.019Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.537 32.3142C131.613 32.3142 129.096 34.9992 129.096 39.2722C129.096 43.5568 131.588 46.2302 135.537 46.2302C139.488 46.2302 141.979 43.5569 141.979 39.2722C141.979 34.9992 139.474 32.3142 135.537 32.3142ZM135.537 34.6111C137.796 34.6111 139.139 36.341 139.139 39.2722H139.139C139.139 42.215 137.796 43.9319 135.537 43.9319C133.278 43.9319 131.935 42.215 131.935 39.2722C131.935 36.3542 133.278 34.6111 135.537 34.6111Z"
        fill="black"
      />
      <path
        d="M144.278 32.4812H146.936V34.7927H147.001C147.372 33.3046 148.735 32.2805 150.267 32.3391C150.589 32.3379 150.909 32.3728 151.222 32.4431V35.0505C150.817 34.9266 150.394 34.8697 149.97 34.882C149.15 34.8488 148.357 35.1754 147.799 35.7761C147.24 36.3768 146.972 37.1916 147.065 38.0065V46.0617H144.278L144.278 32.4812Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.227 46.2302C161.299 46.2302 163.7 44.5383 164.075 42.0729L161.428 42.0729C160.92 43.333 159.645 44.1098 158.292 43.9831C157.277 44.0204 156.296 43.6202 155.597 42.8842C154.898 42.1483 154.549 41.1475 154.638 40.1364V39.968H164.23V39.0129C164.23 34.8952 161.867 32.3141 158.11 32.3141C154.29 32.3141 151.824 35.0768 151.824 39.3366C151.824 43.5832 154.277 46.2302 158.227 46.2302ZM161.441 38.0197L154.652 38.0197C154.649 37.1028 155.013 36.223 155.663 35.5757C156.312 34.9285 157.193 34.5675 158.11 34.5729C159.02 34.5534 159.896 34.9142 160.529 35.5685C161.161 36.2228 161.492 37.1112 161.441 38.0197Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8976 14.6409C59.0818 13.7794 57.9227 13.3291 56.7393 13.4141H53.5063V22.3643H56.7393C59.4053 22.3643 60.9507 20.7207 60.9507 17.8613C61.0999 16.6843 60.7134 15.5024 59.8976 14.6409ZM56.584 21.0986H54.8965V14.6789H56.584C57.4252 14.6322 58.2433 14.9639 58.8145 15.5833C59.3857 16.2026 59.6502 17.0449 59.5356 17.8796C59.6591 18.7178 59.3984 19.567 58.8258 20.1914C58.2532 20.8159 57.4297 21.1491 56.584 21.0986Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0191 15.9605C62.9857 16.6017 62.4052 17.7734 62.521 18.9841C62.403 20.1961 62.9829 21.37 64.0173 22.0127C65.0516 22.6553 66.361 22.6553 67.3953 22.0127C68.4296 21.37 69.0096 20.1961 68.8916 18.9841C69.0074 17.7734 68.4269 16.6017 67.3935 15.9605C66.3601 15.3192 65.0525 15.3192 64.0191 15.9605ZM65.7085 16.6638C66.8628 16.6638 67.5205 17.52 67.5205 18.9841H67.5205C67.5205 20.4541 66.8628 21.3095 65.7085 21.3095C64.5498 21.3095 63.898 20.4599 63.898 18.9841C63.898 17.52 64.5498 16.6638 65.7085 16.6638Z"
        fill="black"
      />
      <path
        d="M77.3599 22.3642H75.977L74.5811 17.3896H74.4756L73.0855 22.3642H71.7158L69.854 15.6098H71.2061L72.416 20.7638H72.5156L73.9043 15.6098H75.1831L76.5718 20.7638H76.6772L77.8813 15.6098H79.2143L77.3599 22.3642Z"
        fill="black"
      />
      <path
        d="M80.7803 15.6098H82.0635V16.6828H82.1631C82.5095 15.8928 83.3189 15.4095 84.1787 15.4794C84.8444 15.4294 85.4968 15.6848 85.9516 16.1735C86.4064 16.6622 86.6143 17.3312 86.5166 17.9916V22.3641H85.1836V18.3264C85.1836 17.2409 84.7119 16.7011 83.7261 16.7011C83.2745 16.6801 82.8363 16.8573 82.5263 17.1863C82.2163 17.5153 82.0655 17.9633 82.1133 18.4128V22.3642H80.7803L80.7803 15.6098Z"
        fill="black"
      />
      <path
        d="M88.6406 12.9731H89.9736V22.3642H88.6406V12.9731Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.325 15.9604C92.2915 16.6016 91.7109 17.7733 91.8266 18.9841C91.7087 20.1961 92.2888 21.37 93.3232 22.0126C94.3576 22.6553 95.667 22.6553 96.7013 22.0126C97.7357 21.37 98.3158 20.1961 98.1979 18.9841C98.3137 17.7733 97.7331 16.6016 96.6996 15.9604C95.6661 15.3191 94.3584 15.3191 93.325 15.9604ZM95.0141 16.6638C96.1684 16.6638 96.8261 17.52 96.8261 18.9841H96.8261C96.8261 20.4541 96.1684 21.3095 95.0141 21.3095C93.8555 21.3095 93.2036 20.4599 93.2036 18.9841C93.2036 17.52 93.8554 16.6638 95.0141 16.6638Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.114 18.4377C100.507 18.5373 99.6013 19.2382 99.6013 20.4541C99.6036 21.0305 99.8496 21.5792 100.279 21.9643C100.708 22.3494 101.279 22.5351 101.853 22.4755C102.671 22.5133 103.446 22.1082 103.882 21.415H103.987V22.3642H105.27V17.7492C105.27 16.3225 104.315 15.4795 102.622 15.4795C101.09 15.4795 99.9983 16.2236 99.8621 17.3838H101.153C101.301 16.9062 101.816 16.633 102.56 16.633C103.471 16.633 103.943 17.0358 103.943 17.7492V18.3322L102.114 18.4377ZM103.943 19.3122V19.8769C103.914 20.3074 103.711 20.7076 103.382 20.986C103.052 21.2643 102.623 21.3969 102.194 21.3535C101.469 21.3535 100.942 20.9997 100.942 20.3918C100.942 19.7963 101.364 19.4799 102.294 19.4177L103.943 19.3122Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.826 15.4978C108.119 15.4978 107.022 16.8498 107.022 18.9841C107.022 21.1235 108.108 22.4756 109.826 22.4755C110.697 22.5076 111.514 22.0537 111.947 21.297H112.052V22.3642H113.33V12.9731H111.997V16.6828H111.897C111.495 15.9198 110.688 15.4581 109.826 15.4978ZM110.204 21.2788C109.075 21.2788 108.399 20.4167 108.399 18.9841H108.399C108.399 17.5632 109.082 16.6953 110.204 16.6953C111.32 16.6953 112.022 17.5822 112.022 18.99C112.022 20.4043 111.327 21.2788 110.204 21.2788Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.343 15.9605C119.31 16.6017 118.729 17.7734 118.845 18.9841C118.727 20.1961 119.307 21.37 120.341 22.0127C121.376 22.6553 122.685 22.6553 123.719 22.0127C124.754 21.37 125.334 20.1961 125.216 18.9841C125.331 17.7734 124.751 16.6017 123.717 15.9605C122.684 15.3192 121.376 15.3192 120.343 15.9605ZM122.032 16.6638C123.187 16.6638 123.844 17.52 123.844 18.9841C123.844 20.4541 123.187 21.3095 122.032 21.3095C120.874 21.3095 120.222 20.4599 120.222 18.9841C120.222 17.52 120.874 16.6638 122.032 16.6638Z"
        fill="black"
      />
      <path
        d="M127.004 15.6098H128.287V16.6828H128.387C128.733 15.8928 129.543 15.4095 130.403 15.4794C131.068 15.4294 131.721 15.6848 132.175 16.1735C132.63 16.6622 132.838 17.3312 132.74 17.9916V22.3641H131.407V18.3264C131.407 17.2409 130.936 16.7011 129.95 16.7011C129.498 16.6801 129.06 16.8573 128.75 17.1863C128.44 17.5153 128.289 17.9633 128.337 18.4128V22.3642H127.004V15.6098Z"
        fill="black"
      />
      <path
        d="M140.273 13.9282V15.6406H141.736V16.7634H140.273V20.2365C140.273 20.9441 140.564 21.2539 141.228 21.2539C141.398 21.2533 141.567 21.2431 141.736 21.2231V22.3335C141.497 22.3763 141.254 22.3991 141.011 22.4016C139.529 22.4016 138.938 21.8801 138.938 20.5778V16.7633H137.866V15.6405H138.938V13.9282H140.273Z"
        fill="black"
      />
      <path
        d="M143.557 12.9731H144.878V16.6953H144.984C145.347 15.8983 146.17 15.4145 147.043 15.4853C147.705 15.4493 148.349 15.7103 148.798 16.1972C149.248 16.6842 149.458 17.3463 149.369 18.0034V22.3642H148.035V18.3322C148.035 17.2533 147.532 16.707 146.591 16.707C146.127 16.6689 145.67 16.8375 145.342 17.1677C145.014 17.4978 144.849 17.9559 144.89 18.4194V22.3642H143.557L143.557 12.9731Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.215 22.4946C155.535 22.6377 156.768 21.8146 157.142 20.5405L155.859 20.5404C155.539 21.1141 154.904 21.4376 154.252 21.3593C153.749 21.3649 153.267 21.158 152.924 20.7896C152.582 20.4213 152.411 19.9255 152.453 19.4243V19.3496H157.222V18.8845C157.222 16.7635 156.089 15.4795 154.209 15.4795C153.306 15.4718 152.444 15.8562 151.847 16.5333C151.249 17.2103 150.975 18.1133 151.095 19.0083C150.972 19.9008 151.247 20.8023 151.848 21.4737C152.449 22.145 153.314 22.5184 154.215 22.4946ZM155.864 18.3637H152.453C152.447 17.898 152.629 17.4495 152.958 17.1201C153.288 16.7906 153.736 16.6081 154.202 16.614C154.661 16.6034 155.104 16.7875 155.421 17.1207C155.737 17.4539 155.898 17.9053 155.864 18.3637Z"
        fill="black"
      />
    </svg>
  );
}
