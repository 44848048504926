import { ElementsIconProps } from '../components/Icon/types';

export function RocketLaunchIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_313_772)">
        <path
          d="M9.19 6.35001C7.15 8.64001 5.75 11.93 5.62 12.24L3.36 11.27C2.71 10.99 2.55 10.14 3.05 9.64001L6.06 6.63001C6.53 6.16001 7.21 5.95001 7.87 6.08001L9.19 6.35001ZM10.68 16.51C10.98 16.81 11.42 16.89 11.8 16.71C12.96 16.17 15.45 14.9 17.06 13.29C21.65 8.7 21.69 4.96001 21.42 3.36001C21.35 2.96001 21.03 2.64001 20.63 2.57001C19.03 2.30001 15.29 2.34 10.7 6.93001C9.09 8.54001 7.83 11.03 7.28 12.19C7.1 12.57 7.19 13.02 7.48 13.31L10.68 16.51ZM17.65 14.81C15.36 16.85 12.07 18.25 11.76 18.38L12.73 20.64C13.01 21.29 13.86 21.45 14.36 20.95L17.37 17.94C17.84 17.47 18.05 16.79 17.92 16.13L17.65 14.81ZM8.94 17.41C9.14 18.47 8.79 19.45 8.12 20.12C7.35 20.89 4.96 21.46 3.41 21.76C2.72 21.89 2.11 21.28 2.24 20.59C2.54 19.04 3.1 16.65 3.88 15.88C4.55 15.21 5.53 14.86 6.59 15.06C7.76 15.28 8.72 16.24 8.94 17.41ZM13 9.00001C13 7.90001 13.9 7.00001 15 7.00001C16.1 7.00001 17 7.90001 17 9.00001C17 10.1 16.1 11 15 11C13.9 11 13 10.1 13 9.00001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_313_772">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
