import { useRtDetails } from '../queries';
import { MonetaryAmountDataPointValue, RealEstateEquityLineItem } from '../../generated/graphql';
import { FinancialSummaryTableRow } from '../../UI';
import { displayRelativeDateFromNow } from '../../util';
import { useTranslation } from 'react-i18next';
import { displayAddress } from '../../RealEstateProperty';

export const useRealEstateEquityDetails = (householdID: string) => {
  const { rtDetails } = useRtDetails({
    householdID,
    includeRealEstate: true,
    includeTotalRealEstateEquity: true,
    includeTotalRealEstateDebt: true,
    includeTotalRealEstateValue: true,
  });
  const { t } = useTranslation('pages');

  const getRows = (property: RealEstateEquityLineItem) => {
    let loans: FinancialSummaryTableRow[];
    if (property.loans?.length > 0) {
      loans = property.loans.map((loan) => ({
        amountLabel:
          (loan.balance.latestDataPoint?.dateTime &&
            displayRelativeDateFromNow(loan.balance.latestDataPoint?.dateTime)) ||
          '',
        amount: {
          currencyCode: (loan.balance.latestDataPoint?.data as MonetaryAmountDataPointValue)?.value?.currencyCode,
          value: -1 * ((loan.balance.latestDataPoint?.data as MonetaryAmountDataPointValue)?.value?.value || 0),
        },
        label: loan.name,
      }));
    } else {
      loans = [
        {
          label: t('real-estate-term-element-detail-view-details-table-no-loans'),
          amountLabel: displayRelativeDateFromNow(property.realEstate.value.latestDataPoint?.dateTime),
          amount: {
            currencyCode: (property.realEstate.value.latestDataPoint?.data.value as MonetaryAmountDataPointValue).value
              .currencyCode,
            value: 0,
          },
        },
      ];
    }
    return [
      {
        label: t('real-estate-term-element-detail-view-details-table-estimated-value'),
        amount: (property.realEstate.value.latestDataPoint?.data as MonetaryAmountDataPointValue)?.value,
        amountLabel: displayRelativeDateFromNow(property.realEstate.value.latestDataPoint?.dateTime),
      } as FinancialSummaryTableRow,
    ].concat(...loans);
  };

  const getDescription = (property: RealEstateEquityLineItem) => {
    return (property.realEstate.address && displayAddress(property.realEstate.address)) || '';
  };

  return {
    realEstate: rtDetails?.realEstate || [],
    getRows,
    totalLabel: t('real-estate-term-element-detail-view-details-table-total-equity'),
    getDescription,
    tableHeader: t('real-estate-term-element-detail-view-details-table-header'),
  };
};
