import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  CreateRealEstateInput,
  DeleteRealEstateInput,
  UpdateRealEstateInput,
} from '../generated/graphql';
import {
  clientCreateRealEstateProperty,
  clientDeleteRealEstateProperty,
  clientUpdateRealEstateProperty,
  useGraphqlClient,
} from '../graphql';
import { realEstatePropertyKeys } from './queryKeys';

export const useCreateRealEstateProperty = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const createRealEstateProperty = useMutation({
    mutationFn: (input: CreateRealEstateInput) => {
      setHouseholdID(input.householdID);
      return clientCreateRealEstateProperty(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        realEstatePropertyKeys.realEstateProperties(householdID)
      );
    },
  });

  return { createRealEstateProperty };
};

export const useUpdateRealEstateProperty = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const updateRealEstateProperty = useMutation({
    mutationFn: (input: UpdateRealEstateInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateRealEstateProperty(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        realEstatePropertyKeys.realEstateProperties(householdID)
      );
    },
  });

  return { updateRealEstateProperty };
};

export const useDeleteRealEstateProperty = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const deleteRealEstateProperty = useMutation({
    mutationFn: (input: DeleteRealEstateInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteRealEstateProperty(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        realEstatePropertyKeys.realEstateProperties(householdID)
      );
    },
  });

  return { deleteRealEstateProperty };
};
