import { TFunction } from 'i18next';
import { DefaultValues } from 'react-hook-form';
import { recurringMonetaryAmountDataPointValue } from '../DataPoint/util';
import { recurringMonetaryAmountDataPointFormDefaultValue } from '../UI';
import {
  IncomeSource,
  IncomeSourceType,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import {
  addRecurringMonetaryAmounts,
  recurringMonetaryAmountConverter,
} from '../util';
import { IncomeSourceFormValues } from './types';

export function incomeSourceFormDefaultValues(
  incomeSource: IncomeSource | undefined | null,
  defaultValues?: IncomeSourceFormValues
): DefaultValues<IncomeSourceFormValues> {
  if (!incomeSource) return { ...defaultValues };

  return {
    nickname: incomeSource.name,
    incomeSourceType: incomeSource.sourceType,
    income: recurringMonetaryAmountDataPointFormDefaultValue(
      incomeSource.amountDataPointGroup.latestDataPoint
    ),
    ownerPersonID: incomeSource.personID,
    notes: incomeSource.notes,
  };
}

export const totalHouseholdIncome = (
  incomeSources: IncomeSource[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
) => {
  const amounts: RecurringMonetaryAmount[] = [];

  for (const incomeSource of incomeSources) {
    const incomeRecurringAmount = recurringMonetaryAmountDataPointValue(
      incomeSource.amountDataPointGroup.latestDataPoint
    );
    if (incomeRecurringAmount) amounts.push(incomeRecurringAmount);
  }

  return addRecurringMonetaryAmounts(amounts, toFrequency);
};

export const incomeSourceLatestAmount = (incomeSource: IncomeSource) =>
  recurringMonetaryAmountDataPointValue(
    incomeSource.amountDataPointGroup.latestDataPoint
  );

export const totalIncomeForPerson = (
  personID: string | undefined,
  incomeSources: IncomeSource[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
) => {
  const forPersonAmounts = incomeSources
    .filter((incomeSource) => incomeSource.personID === personID)
    .map((incomeSource) =>
      recurringMonetaryAmountConverter(
        incomeSourceLatestAmount(incomeSource),
        toFrequency
      )
    );

  return addRecurringMonetaryAmounts(forPersonAmounts, toFrequency);
};

export function displayIncomeSourceType(
  incomeSourceType: IncomeSourceType,
  t: TFunction<'incomeSource'>
) {
  switch (incomeSourceType) {
    case IncomeSourceType.Salary:
      return t('income-source-type-salary');
    case IncomeSourceType.SelfEmployment:
      return t('income-source-type-self-employment');
    case IncomeSourceType.Other:
      return t('income-source-type-other');
    default:
      return '';
  }
}
