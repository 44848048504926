import { ElementsIconProps } from '../components/Icon/types';

export function UnfoldMoreIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="unfold_more_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.82999L14.46 8.28999C14.85 8.67999 15.48 8.67999 15.87 8.28999C16.26 7.89999 16.26 7.26999 15.87 6.87999L12.7 3.69999C12.5132 3.51274 12.2595 3.40751 11.995 3.40751C11.7305 3.40751 11.4768 3.51274 11.29 3.69999L8.12001 6.87999C7.73001 7.26999 7.73001 7.89999 8.12001 8.28999C8.51001 8.67999 9.14001 8.67999 9.53001 8.28999L12 5.82999ZM12 18.17L9.54001 15.71C9.35318 15.5227 9.09953 15.4175 8.83501 15.4175C8.5705 15.4175 8.31685 15.5227 8.13001 15.71C7.74001 16.1 7.74001 16.73 8.13001 17.12L11.3 20.3C11.69 20.69 12.32 20.69 12.71 20.3L15.88 17.13C16.27 16.74 16.27 16.11 15.88 15.72C15.6932 15.5327 15.4395 15.4275 15.175 15.4275C14.9105 15.4275 14.6568 15.5327 14.47 15.72L12 18.17Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
