import { TFunction } from 'i18next';
import { RecurringFrequency } from '../../generated/graphql';

export function displayRecurringFrequency(
  recurringFrequency: RecurringFrequency | undefined | null,
  t: TFunction<'UI'>
) {
  switch (recurringFrequency) {
    case RecurringFrequency.Annually:
      return t('recurring-frequency-annually');
    case RecurringFrequency.Monthly:
      return t('recurring-frequency-monthly');
    case RecurringFrequency.Biweekly:
      return t('recurring-frequency-biweekly');
    case RecurringFrequency.Quarterly:
      return t('recurring-frequency-quarterly');
    case RecurringFrequency.Unknown:
    default:
      return '';
  }
}
