import { useQuery } from '@tanstack/react-query';
import {
  clientGetInsurancePolicies,
  clientGetInsurancePolicyById,
  useGraphqlClient,
} from '../graphql';
import { insuranceKeys } from './queryKeys';
import { InsurancePolicy } from '../generated/graphql';

export const useInsurancePolicies = (householdID?: string) => {
  const gqlClient = useGraphqlClient();

  const insurancePoliciesQuery = useQuery({
    enabled: !!householdID,
    queryKey: insuranceKeys.insurancePolicies(householdID),
    queryFn: () => {
      if (householdID)
        return clientGetInsurancePolicies(gqlClient, { householdID });
    },
  });

  return {
    insurancePolicies: insurancePoliciesQuery.data?.items || [],
    insurancePoliciesQuery,
  };
};

export const useInsurancePolicy = (
  householdID?: string,
  insurancePolicyId?: string
) => {
  if (!householdID || !insurancePolicyId) {
    return {
      insurancePolicy: null,
      insurancePolicyQueryResult: null,
    };
  }
  const gqlClient = useGraphqlClient();

  const insurancePolicyQueryResult = useQuery<InsurancePolicy>({
    queryKey: insuranceKeys.insurancePolicy(insurancePolicyId),
    queryFn: async () => {
      const result = await clientGetInsurancePolicyById(
        gqlClient,
        insurancePolicyId,
        householdID
      );
      return result.insurancePolicy;
    },
  });

  return {
    insurancePolicy: insurancePolicyQueryResult.data,
    insurancePolicyQueryResult,
  };
};
