import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { UseLoanFormArgs, useLoanForm } from '../hooks';

interface LoanFormProps extends UseLoanFormArgs {
  children: React.ReactNode;
  className?: HTMLFormElement['className'];
}

export const LoanForm: FC<LoanFormProps> = ({
  children,
  className,
  ...useLoanFormArgs
}) => {
  const { formMethods, onSubmit } = useLoanForm(useLoanFormArgs);

  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};
