import { createContext, FC, PropsWithChildren } from 'react';
import { Business, FirmClient, InsurancePolicy, Loan, RealEstate, Scorecard } from '../../../../generated/graphql';
import { IFeatureFlagsContext } from '../../../../FeatureFlags';

export interface IInsuranceElementDetailViewContext
  extends Pick<IFeatureFlagsContext, 'allowBAA' | 'allowTargetScoreRanges' | 'showElementDetailViewVideoThumbnails'> {
  firmClient: FirmClient;
  personalRealEstateProperties: RealEstate[];
  scorecard: Scorecard;
  businessRealEstateProperties: RealEstate[];
  loans: Loan[];
  businesses: Business[];
  householdID: string;
  insurancePolicies: InsurancePolicy[];
  routes?: InsuranceRoutes;
  supportEdit: boolean;
  supportCreate: boolean;
}

export interface InsuranceRoutes {
  editInsurance?: (params: { clientID: string; insuranceID: string }) => string;
}

export const InsuranceElementDetailViewContext = createContext<IInsuranceElementDetailViewContext | null>(null);

export const InsuranceElementDetailViewContextProvider: FC<PropsWithChildren<IInsuranceElementDetailViewContext>> = ({
  children,
  ...props
}) => {
  return (
    <InsuranceElementDetailViewContext.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </InsuranceElementDetailViewContext.Provider>
  );
};
