import { LocalThemeContextProvider } from '../../context';
import {
  QtAfterTaxInvestmentsSumTable,
  QtOtherInvestmentsSumTable,
  QtPreTaxInvestmentsSumTable,
  RetirementAccountsSummaryProportionChart,
} from '../../../QtDetails';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import ComputerDeskPicture from '../../../assets/ComputerDeskPicture';
import {
  ClientQualifiedTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainerProps,
  ElementDetailViewIndexPageHeader,
  ElementEducationVideoTile,
} from '../../components';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { UseQualifiedTermElementDetailViewIndexPageArgs, useQualifiedTermElementDetailViewIndexPage } from './hooks';
import { hiContrastElementsColors } from '../../../util';

interface QualifiedTermElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseQualifiedTermElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function QualifiedTermElementDetailViewIndexPage({
  firmID,
  firmClientID,
  downloadReportButton,
  showScoreHealthRange,
}: QualifiedTermElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useQualifiedTermElementDetailViewIndexPage({
    firmID,
    firmClientID,
  });

  if (!householdID || !firmID || !firmClientID) return null;

  return (
    <>
      <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
        <ElementDetailViewIndexPageHeader downloadReportButton={downloadReportButton} />
      </LocalThemeContextProvider>
      <div className="px-12">
        <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
          <ElementDetailViewHeader
            tooltipDescription={tPages('qualified-term-element-detail-view-header-tooltip-description')}
            title={tScorecard('qualified-term')}
            tooltipContainerClassName="!w-[312px]"
          />
        </LocalThemeContextProvider>
        <ScorecardElementCalculationGroup className="mb-12" element="Qt" householdID={householdID} />
        {showScoreHealthRange && <ClientQualifiedTermScoreHealthRange firmID={firmID} firmClientID={firmClientID} />}
        <ElementEducationVideoTile
          label={tPages('qualified-term-element-detail-view-learn-more-label')}
          body={tPages('qualified-term-element-detail-view-learn-more-body')}
          thumbNail={<ComputerDeskPicture className="h-[118px]" />}
        />
        <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
        <div className="grid gap-6 mt-3 peer/sumTables">
          <QtAfterTaxInvestmentsSumTable householdID={householdID} />
          <QtPreTaxInvestmentsSumTable householdID={householdID} />
          <QtOtherInvestmentsSumTable householdID={householdID} />
        </div>
        <RetirementAccountsSummaryProportionChart
          className="peer-empty/sumTables:mt-0 mt-8"
          householdID={householdID}
        />
      </div>
    </>
  );
}
