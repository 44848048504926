import { forwardRef } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { InputProps } from './Input';
import { InputContainer } from './components';
import { elementsInputClasses } from './util';

export interface MonetaryInputProps
  extends Omit<InputProps, 'inputProps' | 'onChange'> {
  value?: number | null;
  onChange?: (value: number | null) => void;
  defaultValue?: string | number | undefined;
  inputProps?: Omit<
    CurrencyInputProps,
    | 'onClick'
    | 'name'
    | 'disabled'
    | 'onChange'
    | 'onBlur'
    | 'id'
    | 'defaultValue'
  >;
}

export const MonetaryInput = forwardRef<HTMLInputElement, MonetaryInputProps>(
  (
    {
      name,
      value,
      defaultValue,
      onBlur,
      onChange,
      inputProps,
      ...inputContainerProps
    },
    ref
  ) => {
    return (
      <InputContainer
        isReadOnly={!!inputProps?.readOnly}
        {...inputContainerProps}
      >
        <CurrencyInput
          ref={ref}
          id={name}
          prefix="$"
          name={name}
          value={value ?? undefined}
          onBlur={onBlur}
          decimalsLimit={2}
          onValueChange={(numberString) => {
            const number = Number(numberString);
            onChange?.(isNaN(number) ? null : number);
          }}
          defaultValue={defaultValue}
          className={elementsInputClasses.input}
          {...inputProps}
        />
      </InputContainer>
    );
  }
);
