import {
  displayCashAccountType,
  getCashAccountValue,
  getLastCashAccountUpdateDate,
} from '../../CashAccount';
import {
  AmountSumTable,
  AmountSumTableItem,
  AmountSumTableProps,
} from '../../UI';
import { UseBusinessCashSumTableArgs, useBusinessCashSumTable } from '../hooks';

interface BusinessCashSumTableProps
  extends UseBusinessCashSumTableArgs,
    Pick<AmountSumTableProps, 'hideOnEmpty'> {
  className?: string;
}

export function BusinessCashSumTable({
  className,
  hideOnEmpty,
  ...useBusinessCashSumTableArgs
}: BusinessCashSumTableProps) {
  const { isLoading, ltDetails, tUI, tCashAccount } = useBusinessCashSumTable(
    useBusinessCashSumTableArgs
  );

  return (
    <AmountSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={ltDetails?.totalBusinessCash}
      title={tUI('business-cash-table-title')}
      sumTitle={tUI('business-cash-table-sum-title')}
    >
      {ltDetails?.businessCashItems.map((cashAccount) => (
        <AmountSumTableItem
          key={cashAccount.id}
          title={cashAccount.name}
          amount={getCashAccountValue(cashAccount)}
          lastUpdated={getLastCashAccountUpdateDate(cashAccount)}
          subtitle={displayCashAccountType(
            cashAccount.cashAccountType,
            tCashAccount
          )}
        />
      ))}
    </AmountSumTable>
  );
}
