import { ElementsIconProps } from '../components/Icon/types';

export function ChangeAccountTypeIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="change_account_type_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.645 15.3539L17.855 18.1439C17.545 18.4639 17.005 18.2439 17.005 17.7939V16.0039H10.995C10.445 16.0039 9.99501 15.5539 9.99501 15.0039C9.99501 14.4539 10.445 14.0039 10.995 14.0039H17.005V12.2139C17.005 11.7639 17.545 11.5439 17.855 11.8639L20.645 14.6539C20.835 14.8439 20.835 15.1639 20.645 15.3539ZM6.98501 8.00394H12.995C13.545 8.00394 13.995 8.45394 13.995 9.00394C13.995 9.55394 13.545 10.0039 12.995 10.0039H6.98501V11.7939C6.98501 12.2439 6.44501 12.4639 6.13501 12.1439L3.35501 9.35394C3.16501 9.15394 3.16501 8.84394 3.35501 8.64394L6.13501 5.85394C6.44501 5.53394 6.98501 5.76394 6.98501 6.20394V8.00394Z"
          fill="#4AF196"
        />
        <mask
          id="mask0_0_615"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="5"
          width="18"
          height="14"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.645 15.3539L17.855 18.1439C17.545 18.4639 17.005 18.2439 17.005 17.7939V16.0039H10.995C10.445 16.0039 9.99501 15.5539 9.99501 15.0039C9.99501 14.4539 10.445 14.0039 10.995 14.0039H17.005V12.2139C17.005 11.7639 17.545 11.5439 17.855 11.8639L20.645 14.6539C20.835 14.8439 20.835 15.1639 20.645 15.3539ZM6.98501 8.00394H12.995C13.545 8.00394 13.995 8.45394 13.995 9.00394C13.995 9.55394 13.545 10.0039 12.995 10.0039H6.98501V11.7939C6.98501 12.2439 6.44501 12.4639 6.13501 12.1439L3.35501 9.35394C3.16501 9.15394 3.16501 8.84394 3.35501 8.64394L6.13501 5.85394C6.44501 5.53394 6.98501 5.76394 6.98501 6.20394V8.00394Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_615)"></g>
      </g>
    </svg>
  );
}
