import clsx from 'clsx';
import { MonetaryAmount, RecurringMonetaryAmount } from '../../../generated/graphql';
import { TableTitle, TableTitleProps } from './TableTitle';
import { TableRowsContainer, TableRowsContainerProps } from './TableRowsContainer';
import { AmountSumTableAcceptableTableChildren } from './types';
import { SumTableSumContainer, SumTableSumContainerProps } from './SumTableSumContainer';
import { TableContainer, TableContainerProps } from './TableContainer';
import { useDisplayAmount } from '../../../DataPoint';

export interface AmountSumTableProps
  extends TableTitleProps,
    TableRowsContainerProps<AmountSumTableAcceptableTableChildren>,
    Omit<SumTableSumContainerProps, 'sumDisplay'>,
    Omit<TableContainerProps, 'children' | 'hide'> {
  className?: string;
  hideOnEmpty?: boolean;
  sum?: MonetaryAmount | RecurringMonetaryAmount | null;
}

export function AmountSumTable({
  sum,
  title,
  children,
  sumTitle,
  isLoading,
  className,
  sumAppendix,
  hideOnEmpty,
}: AmountSumTableProps) {
  const { displayAmount } = useDisplayAmount();
  const hide = hideOnEmpty && !isLoading && (!children || (Array.isArray(children) && children.length === 0));

  return (
    <TableContainer hide={hide} isLoading={isLoading} className={clsx(className, 'AmountSumTable')}>
      <TableTitle title={title} className="AmountSumTable-TableTitle" />
      <TableRowsContainer className="AmountSumTable-TableRowsContainer">{children}</TableRowsContainer>
      <SumTableSumContainer
        sumTitle={sumTitle}
        sumAppendix={sumAppendix}
        className="AmountSumTable-SumTableSumContainer"
        sumDisplay={displayAmount(sum, {
          nullishIsDash: true,
          withoutDollarSign: true,
        })}
      />
    </TableContainer>
  );
}
