import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from '../../UI';
import { IncomeSourceType } from '../../generated/graphql';
import { IncomeSourceFormValues } from '../types';
import { displayIncomeSourceType } from '../util';

export const IncomeSourceIncomeTypeDropdown = () => {
  const { t: tForm } = useTranslation('form');
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const { control } = useFormContext<IncomeSourceFormValues>();
  const { field, fieldState } = useController<
    IncomeSourceFormValues,
    'incomeSourceType'
  >({
    control,
    name: 'incomeSourceType',
    rules: { required: true },
  });

  return (
    <Dropdown
      {...field}
      dropdownMenuClassName="!w-[220px]"
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      label={tForm('income-source-income-type-dropdown-label')}
    >
      <DropdownOption
        id={IncomeSourceType.Salary}
        value={IncomeSourceType.Salary}
        label={displayIncomeSourceType(IncomeSourceType.Salary, tIncomeSource)}
      />
      <DropdownOption
        id={IncomeSourceType.SelfEmployment}
        value={IncomeSourceType.SelfEmployment}
        label={displayIncomeSourceType(
          IncomeSourceType.SelfEmployment,
          tIncomeSource
        )}
      />
      <DropdownOption
        id={IncomeSourceType.Other}
        value={IncomeSourceType.Other}
        label={displayIncomeSourceType(IncomeSourceType.Other, tIncomeSource)}
      />
    </Dropdown>
  );
};
