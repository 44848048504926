import { ElementsIconProps } from '../components/Icon/types';

export function SupportAgentIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1524_843)">
        <path
          d="M21 12.22C21 6.73 16.74 3 12 3C7.31 3 3 6.65 3 12.28C2.4 12.62 2 13.26 2 14V16C2 17.1 2.9 18 4 18C4.55 18 5 17.55 5 17V12.19C5 8.36 7.95 5.01 11.78 4.9C15.74 4.78 19 7.96 19 11.9V19H12C11.45 19 11 19.45 11 20C11 20.55 11.45 21 12 21H19C20.1 21 21 20.1 21 19V17.78C21.59 17.47 22 16.86 22 16.14V13.84C22 13.14 21.59 12.53 21 12.22Z"
          fill="currentColor"
        />
        <path
          d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
          fill="currentColor"
        />
        <path
          d="M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z"
          fill="currentColor"
        />
        <path
          d="M18 11.03C17.52 8.18 15.04 6 12.05 6C9.02003 6 5.76003 8.51 6.02003 12.45C8.49003 11.44 10.35 9.24 10.88 6.56C12.19 9.19 14.88 11 18 11.03Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1524_843">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
