import { ElementsIconProps } from '../components/Icon/types';

export const AddIcon = (props: ElementsIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="add_24px">
      <path
        id="Color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
        fill="currentColor"
      />
      <mask
        id="mask0_0_755"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="14"
        height="14"
      >
        <path
          id="Color_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_755)"></g>
    </g>
  </svg>
);
