import { ElementsIconProps } from '../components/Icon/types';

export const ScoreHealthRangeChevronIcon = (props: ElementsIconProps) => (
  <svg
    {...props}
    width="14"
    height="24"
    fill="none"
    viewBox="0 0 14 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M3.16133 1.03985C2.57554 0.454059 1.62579 0.454059 1.04001 1.03985C0.45422 1.62563 0.45422 2.57538 1.04001 3.16117L3.16133 1.03985ZM12.0002 12L13.0608 13.0607C13.6466 12.4749 13.6466 11.5251 13.0608 10.9393L12.0002 12ZM1.04001 20.8388C0.45422 21.4246 0.45422 22.3744 1.04001 22.9602C1.62579 23.5459 2.57554 23.5459 3.16133 22.9602L1.04001 20.8388ZM1.04001 3.16117L10.9395 13.0607L13.0608 10.9393L3.16133 1.03985L1.04001 3.16117ZM10.9395 10.9393L1.04001 20.8388L3.16133 22.9602L13.0608 13.0607L10.9395 10.9393Z"
    />
  </svg>
);
