import { ElementsIconProps } from '../components/Icon/types';

export function SubdirectoryArrowRightIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="subdirectory_arrow_right_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9987 15.4188L14.4187 19.9988C14.0287 20.3888 13.3887 20.3888 12.9987 19.9988C12.6087 19.6088 12.6087 18.9688 12.9987 18.5788L15.8787 15.7088H5.70874C5.15874 15.7088 4.70874 15.2588 4.70874 14.7088V4.70876C4.70874 4.15876 5.15874 3.70876 5.70874 3.70876C6.25874 3.70876 6.70874 4.15876 6.70874 4.70876V13.7088H15.8787L12.9987 10.8388C12.6087 10.4488 12.6087 9.80876 12.9987 9.41876C13.3887 9.02876 14.0287 9.02876 14.4187 9.41876L18.9987 13.9988C19.3887 14.3888 19.3887 15.0288 18.9987 15.4188Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_1021"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="3"
          width="16"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9987 15.4188L14.4187 19.9988C14.0287 20.3888 13.3887 20.3888 12.9987 19.9988C12.6087 19.6088 12.6087 18.9688 12.9987 18.5788L15.8787 15.7088H5.70874C5.15874 15.7088 4.70874 15.2588 4.70874 14.7088V4.70876C4.70874 4.15876 5.15874 3.70876 5.70874 3.70876C6.25874 3.70876 6.70874 4.15876 6.70874 4.70876V13.7088H15.8787L12.9987 10.8388C12.6087 10.4488 12.6087 9.80876 12.9987 9.41876C13.3887 9.02876 14.0287 9.02876 14.4187 9.41876L18.9987 13.9988C19.3887 14.3888 19.3887 15.0288 18.9987 15.4188Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1021)"></g>
      </g>
    </svg>
  );
}
