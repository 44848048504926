import { getInvestmentValue } from '../util';
import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { usePrivateStockSumTable, UsePrivateStockSumTableArgs } from '../hooks';

interface PrivateStockSumTableProps extends UsePrivateStockSumTableArgs {
  className?: string;
}

export function PrivateStockSumTable({ className, householdID }: PrivateStockSumTableProps) {
  const { isLoading, privateStock, tInvestment, totalPrivateStockValue } = usePrivateStockSumTable({ householdID });

  return (
    <AmountSumTable
      hideOnEmpty
      isLoading={isLoading}
      className={className}
      sum={totalPrivateStockValue}
      title={tInvestment('private-stock-sum-table-title')}
      sumTitle={tInvestment('private-stock-sum-table-sum-title')}
    >
      {privateStock.map((investment) => (
        <AmountSumTableItem
          withChevron={false}
          key={investment.id}
          title={investment.name}
          amount={getInvestmentValue(investment)}
        />
      ))}
    </AmountSumTable>
  );
}
