import { useOtherDebtSummarySumTable } from '../hooks';
import { OtherDebtSummarySumTableProps } from '../types';
import { displayLoanType, getLoanBalance } from '../../Loan';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function OtherDebtSummarySumTable({ householdID, ...props }: OtherDebtSummarySumTableProps) {
  const { isLoading, tUI, otherDebt, tLoan, totalOtherDebt } = useOtherDebtSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      sum={totalOtherDebt}
      isLoading={isLoading}
      sumTitle={tUI('other-debt-summary-sum-table-total-title')}
    >
      {otherDebt?.map((loan) => (
        <AmountSumTableItem
          key={loan?.id}
          title={loan.name}
          amount={getLoanBalance(loan)}
          subtitle={displayLoanType(loan.loanType, tLoan)}
        />
      ))}
    </AmountSumTable>
  );
}
