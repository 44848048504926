import {
  OwnerData,
  ownersForFirmClient,
  useFirmClient,
} from '../../../../../FirmClient';
import { usePersons } from '../../../../../Person';
import { useBusinesses } from '../../../../../Business';

export const usePossibleOwners = (
  clientID: string | undefined | null,
  firmID: string | undefined | null,
  ownerOptionsFilter: (owner: OwnerData) => boolean = () => true
) => {
  const { firmClient } = useFirmClient({ firmClientID: clientID, firmID });
  const householdID = firmClient?.household?.id;
  const { persons, personsQuery } = usePersons({ householdID });
  const { businesses, businessesQuery } = useBusinesses({ householdID });
  const possibleOwners = ownersForFirmClient(
    firmClient,
    businesses,
    persons
  ).filter(ownerOptionsFilter);
  return { personsQuery, businessesQuery, possibleOwners };
};
