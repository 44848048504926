import clsx from 'clsx';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  ClientDebtRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
} from '../../components';
import { UseDebtRateElementDetailViewIndexPageArgs, useDebtRateElementDetailViewIndexPage } from './hooks';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { LoanDetailsSumTable, LoanSummaryProportionChart } from '../../../DrDetails';

interface DebtRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseDebtRateElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function DebtRateElementDetailViewIndexPage({
  firmID,
  firmClientID,
  showScoreHealthRange,
  downloadReportButton,
}: DebtRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useDebtRateElementDetailViewIndexPage({
    firmID,
    firmClientID,
  });

  if (!householdID || !firmClientID || !firmID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('debt-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('debt-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Dr" householdID={householdID} />
      {showScoreHealthRange && <ClientDebtRateScoreHealthRange firmClientID={firmClientID} firmID={firmID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('debt-rate-element-detail-view-learn-more-body')}
        label={tPages('debt-rate-element-detail-view-learn-more-label')}
        className={clsx(
          '[&_.ElementEducationVideoTile-label]:mix-blend-plus-lighter',
          '[&_.ElementEducationVideoTile-IconButton]:!text-darkMode-almostBlack',
          '[&_.ElementEducationVideoTile-IconButton_*]:!mix-blend-normal'
        )}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <LoanDetailsSumTable className="mt-12" householdID={householdID} />
      <LoanSummaryProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
