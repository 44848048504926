import { DefaultValues } from 'react-hook-form';
import {
  formOwnershipAllocationToFloat,
  formRecurringMonetaryAmountValueInCents,
  monetaryAmountDataPointFormDefaultValue,
  recurringMonetaryAmountDataPointFormDefaultValue,
} from '../UI';
import {
  Investment,
  InvestmentMix,
  InvestmentType,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import {
  InvestmentFormSubmitData,
  InvestmentFormValues,
  UseCreateInvestmentServiceInput,
  UseUpdateInvestmentServiceInput,
} from './types';
import {
  investmentMixDataPointValue,
  monetaryAmountDataPointValue,
  recurringMonetaryAmountDataPointValue,
} from '../DataPoint';
import {
  DisplayAgeOptions,
  addMonetaryAmounts,
  addRecurringMonetaryAmounts,
  displayAge,
  recurringMonetaryAmountConverter,
} from '../util';
import { differenceInMonths } from 'date-fns';
import { latestDataPointGroupUpdateDate } from '../DataPointGroup';
import {
  AFTER_TAX_SAVINGS_INVESTMENT_TYPES,
  INVESTMENT_VALUE_DECAY_LIMIT_IN_MONTHS,
  OTHER_QUALIFIED_INVESTMENT_TYPES,
  PRE_TAX_SAVINGS_INVESTMENT_TYPES,
  TAX_DEFERRED_QUALIFIED_INVESTMENT_TYPES,
  TAX_FREE_QUALIFIED_INVESTMENT_TYPES,
  EDUCATION_SAVINGS_INVESTMENT_TYPES,
} from './constants';
import { TFunction } from 'i18next';
import { ownershipTenantsInput } from '../Owner';

export function investmentFormDefaultValues(
  investment: Investment | undefined | null,
  defaultValues?: Partial<InvestmentFormValues>
): DefaultValues<InvestmentFormValues> {
  if (!investment) return { ...defaultValues };

  return {
    nickname: investment.name,
    estimatedValue: monetaryAmountDataPointFormDefaultValue(investment.value.latestDataPoint),
    recurringContribution: recurringMonetaryAmountDataPointFormDefaultValue(
      investment.recurringContribution.latestDataPoint
    ),
    investmentType: investment.investmentType,
    ...defaultValues,
  };
}

export function investmentsOfType(investments: Investment[], investmentType: InvestmentType) {
  return investments.filter((investment) => {
    return investment.investmentType === investmentType;
  });
}

export function groupInvestmentsByType(investments: Investment[]) {
  const investmentsMap: Map<InvestmentType, Investment[]> = new Map();

  investments.forEach((investment) => {
    if (!investmentsMap.has(investment.investmentType)) {
      investmentsMap.set(investment.investmentType, []);
    }
    const investmentsOfType = investmentsMap.get(investment.investmentType);
    if (investmentsOfType) {
      investmentsOfType.push(investment);
    }
  });

  return investmentsMap;
}

export function getInvestmentRecurringContributions(
  investment: Investment,
  options: { frequency?: RecurringFrequency } = {}
) {
  const value = recurringMonetaryAmountDataPointValue(investment.recurringContribution.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getInvestmentsWithRecurringContributions(investments: Investment[]) {
  return investments.filter((investment) => !!getInvestmentRecurringContributions(investment)?.amount.value);
}

export function getTotalRecurringContributionsForInvestments(
  investments: Investment[],
  options: { frequency?: RecurringFrequency } = {}
): RecurringMonetaryAmount | null {
  const contributions: RecurringMonetaryAmount[] = [];

  for (const investment of investments) {
    const investmentPayments = getInvestmentRecurringContributions(investment);
    if (investmentPayments) contributions.push(investmentPayments);
  }

  return addRecurringMonetaryAmounts(contributions, options.frequency);
}

export function getInvestmentValue(investment: Investment): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(investment.value.latestDataPoint);
}

export function getTotalValueOfInvestments(investments: Investment[]) {
  return addMonetaryAmounts(investments.map(getInvestmentValue));
}

export function isInvestmentValueDecayed(investment: Investment) {
  return (
    differenceInMonths(new Date(), latestDataPointGroupUpdateDate(investment.value) || new Date()) >=
    INVESTMENT_VALUE_DECAY_LIMIT_IN_MONTHS
  );
}

export function totalDecayedValueForInvestments(investments: Investment[]) {
  return addMonetaryAmounts(
    investments.filter(isInvestmentValueDecayed).map(getInvestmentValue).filter(Boolean) as MonetaryAmount[]
  );
}

export function displayInvestmentValueAge(investment: Investment, options?: DisplayAgeOptions) {
  return displayAge(latestDataPointGroupUpdateDate(investment.value)?.toISOString(), options);
}

export function separateToPreAndPostTaxInvestments(investments: Investment[]): {
  postTax: Investment[];
  preTax: Investment[];
  privateStock: Investment[];
} {
  const postTax: Investment[] = [];
  const preTax: Investment[] = [];
  const privateStock: Investment[] = [];

  for (const investment of investments) {
    switch (investment.investmentType) {
      case InvestmentType.UniversalTrustForMinorAccount:
      case InvestmentType.BrokerageAccount:
      case InvestmentType.Cryptocurrency:
      case InvestmentType.StockOption:
      case InvestmentType.RothIndividualRetirementAccount:
      case InvestmentType.RothSubsection_401KAccount:
      case InvestmentType.QualifiedTuitionPlan_529:
      case InvestmentType.MinorRothIndividualRetirementAccount:
        postTax.push(investment);
        break;
      case InvestmentType.PrivateStock:
        privateStock.push(investment);
        postTax.push(investment);
        break;
      default:
        preTax.push(investment);
    }
  }

  return { postTax, preTax, privateStock };
}

export const investmentsHaveOfType = (investments: Investment[], investmentType: InvestmentType) =>
  investments.some((investment) => investment.investmentType === investmentType);

export const getLastInvestmentUpdateDate = (investment: Investment): string =>
  investment.value?.latestDataPoint?.dateTime ?? investment.value?.updatedAt;

export const getTaxFreeInvestments = (investments: Investment[]) =>
  investments.filter((investment) => TAX_FREE_QUALIFIED_INVESTMENT_TYPES.includes(investment.investmentType));

export const getTaxDeferredInvestments = (investments: Investment[]) =>
  investments.filter((investment) => TAX_DEFERRED_QUALIFIED_INVESTMENT_TYPES.includes(investment.investmentType));

export const getOtherQualifiedInvestments = (investments: Investment[]) =>
  investments.filter((investment) => OTHER_QUALIFIED_INVESTMENT_TYPES.includes(investment.investmentType));

export const getAfterTaxSavingsInvestments = (investments: Investment[]) =>
  investments.filter((investment) => AFTER_TAX_SAVINGS_INVESTMENT_TYPES.includes(investment.investmentType));

export const getPreTaxSavingsInvestments = (investments: Investment[]) =>
  investments.filter((investment) => PRE_TAX_SAVINGS_INVESTMENT_TYPES.includes(investment.investmentType));

export const getEducationSavingsInvestments = (investments: Investment[]) =>
  investments.filter((investment) => EDUCATION_SAVINGS_INVESTMENT_TYPES.includes(investment.investmentType));

export function getInvestmentMix(investment: Investment): InvestmentMix | undefined {
  return investmentMixDataPointValue(investment.investmentMix.latestDataPoint);
}

export function investmentMixPercentSeparation(mix: InvestmentMix): { stocks: number; bonds: number } {
  switch (mix) {
    case InvestmentMix.VeryConservative:
      return { bonds: 1, stocks: 0 };
    case InvestmentMix.Conservative:
      return { bonds: 0.7, stocks: 0.3 };
    case InvestmentMix.Moderate:
      return { bonds: 0.6, stocks: 0.4 };
    case InvestmentMix.ModerateGrowth:
      return { bonds: 0.5, stocks: 0.5 };
    case InvestmentMix.Balanced:
      return { bonds: 0.4, stocks: 0.6 };
    case InvestmentMix.BalancedGrowth:
      return { bonds: 0.3, stocks: 0.7 };
    case InvestmentMix.Growth:
      return { bonds: 0.2, stocks: 0.8 };
    case InvestmentMix.AggressiveGrowth:
      return { bonds: 0.1, stocks: 0.9 };
    case InvestmentMix.EquityGrowth:
      return { bonds: 0, stocks: 1 };
    case InvestmentMix.DontKnow:
    default:
      return { bonds: 0, stocks: 0 };
  }
}

export function getInvestmentMixDropdownOptionSubtitle(mix: InvestmentMix, t: TFunction<'common'>) {
  const { bonds, stocks } = investmentMixPercentSeparation(mix);
  return t('investment-mix', { s: stocks * 100, b: bonds * 100 });
}

export function createInvestmentServiceInputFromForm({
  formValues,
  householdID,
}: InvestmentFormSubmitData): UseCreateInvestmentServiceInput {
  return {
    files: formValues.pendingFiles,
    investmentMix: formValues.investmentMix,
    valueInCents: formValues.estimatedValue * 100,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    recurringContributionFrequency: formValues.recurringContribution.frequency,
    createInvestmentInput: {
      householdID,
      investment: {
        notes: formValues.notes,
        name: formValues.nickname,
        investmentType: formValues.investmentType,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
      },
    },
  };
}

export function updateInvestmentServiceInputFromForm({
  formValues,
  householdID,
  changeToken,
  investmentID,
}: InvestmentFormSubmitData): UseUpdateInvestmentServiceInput {
  if (!investmentID || !changeToken) throw new Error('Missing investmentID or changeToken');

  return {
    valueInCents: formValues.estimatedValue * 100,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    recurringContributionFrequency: formValues.recurringContribution.frequency,
    updateInvestmentInput: {
      householdID,
      changeToken,
      id: investmentID,
      changes: { name: formValues.nickname },
    },
  };
}
