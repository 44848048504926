import { Outlet } from '@remix-run/react';
import { SideOver } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import { ElementDetailViewLayoutPageCommonProps } from '../shared';

interface RealEstateTermElementDetailViewLayoutPageProps extends ElementDetailViewLayoutPageCommonProps {}

export function RealEstateTermElementDetailViewLayoutPage(props: RealEstateTermElementDetailViewLayoutPageProps) {
  return (
    <SideOver className="!px-0" {...props}>
      <LocalThemeContextProvider colorPrimary={elementsColors['Rt']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
