import { ElementsIconProps } from '../components/Icon/types';

export const CloseOutlineIcon = (props: ElementsIconProps) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="close_24px">
      <g id="Group">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3 5.70999C18.1131 5.52274 17.8595 5.41751 17.595 5.41751C17.3305 5.41751 17.0768 5.52274 16.89 5.70999L12 10.59L7.10997 5.69999C6.92314 5.51274 6.66949 5.40751 6.40497 5.40751C6.14045 5.40751 5.8868 5.51274 5.69997 5.69999C5.30997 6.08999 5.30997 6.71999 5.69997 7.10999L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10999C18.68 6.72999 18.68 6.08999 18.3 5.70999Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_967"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="5"
          width="14"
          height="14"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3 5.70999C18.1131 5.52274 17.8595 5.41751 17.595 5.41751C17.3305 5.41751 17.0768 5.52274 16.89 5.70999L12 10.59L7.10997 5.69999C6.92314 5.51274 6.66949 5.40751 6.40497 5.40751C6.14045 5.40751 5.8868 5.51274 5.69997 5.69999C5.30997 6.08999 5.30997 6.71999 5.69997 7.10999L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10999C18.68 6.72999 18.68 6.08999 18.3 5.70999Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_967)"></g>
      </g>
    </g>
  </svg>
);
