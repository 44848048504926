import { useElementDetailViewIndexPage, UseElementDetailViewIndexPageArgs } from '../../shared';

export interface UseBusinessTermElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useBusinessTermElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseBusinessTermElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
