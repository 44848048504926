import { createContext, FC, ReactNode, useContext } from 'react';
import { FeatureFlags } from '../types';

export interface IFeatureFlagsContext extends FeatureFlags {
  allowBAA?: boolean;
  allowMessaging?: boolean;
  allowInsights?: boolean;
  allowDocuments?: boolean;
  disableConsolidatedAccounting?: boolean;
}

interface IFeatureFlagsContextProviderProps extends IFeatureFlagsContext, FeatureFlags {
  children?: ReactNode;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  clientGroups: false,
  allowTargetScoreRanges: false,
  showElementDetailViewVideoThumbnails: false,
});

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);

export const FeatureFlagsContextProvider: FC<IFeatureFlagsContextProviderProps> = ({ children, ...props }) => {
  return <FeatureFlagsContext.Provider value={props}>{children}</FeatureFlagsContext.Provider>;
};
