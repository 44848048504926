import {
  ContributionsSumTable,
  ContributionsSumTableItem,
  ContributionsSumTableProps,
} from '../../UI';
import {
  UseAfterTaxSavingsContributionsSumTableArgs,
  useAfterTaxSavingsContributionsSumTable,
} from '../hooks/useAfterTaxSavingsContributionsSumTable';
import {
  getInvestmentRecurringContributions,
  getInvestmentValue,
  getLastInvestmentUpdateDate,
} from '../util';
import { displayInvestmentType } from '../display';

interface AfterTaxSavingsContributionsSumTableProps
  extends UseAfterTaxSavingsContributionsSumTableArgs,
    Partial<
      Pick<ContributionsSumTableProps, 'title' | 'sumTitle' | 'hideOnEmpty'>
    > {
  className?: string;
}

export function AfterTaxSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  householdID,
  hideOnEmpty,
}: AfterTaxSavingsContributionsSumTableProps) {
  const {
    isLoading,
    tInvestment,
    annualContributions,
    investmentsWithSavings,
    monthlyContributionsDisplay,
  } = useAfterTaxSavingsContributionsSumTable({
    householdID,
  });

  return (
    <ContributionsSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty}
      sum={annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      title={
        title ?? tInvestment('after-tax-savings-contributions-sum-table-title')
      }
      sumTitle={
        sumTitle ??
        tInvestment('after-tax-savings-contributions-sum-table-sum-title')
      }
    >
      {investmentsWithSavings.map((investment) => (
        <ContributionsSumTableItem
          key={investment.id}
          title={investment.name}
          balance={getInvestmentValue(investment)}
          lastBalanceUpdateISO={getLastInvestmentUpdateDate(investment)}
          contributions={getInvestmentRecurringContributions(investment)}
          subtitle={displayInvestmentType(
            investment.investmentType,
            tInvestment
          )}
        />
      ))}
    </ContributionsSumTable>
  );
}
