import {
  RealEstateEquityDetails,
  RealEstateEquitySummaryProportionChart,
  RealEstateUnattachedLoans,
} from '../../../RtDetails';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AnimatedFabDivider,
  ClientRealEstateTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
} from '../../components';
import { useRealEstateTermElementDetailViewIndexPage } from './hooks';
import { RealEstateTermElementDetailViewIndexPageProps } from './types';

export function RealEstateTermElementDetailViewIndexPage({
  firmID,
  firmClientID,
  clickHandlers,
  downloadReportButton,
  showScoreHealthRange,
}: RealEstateTermElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useRealEstateTermElementDetailViewIndexPage({
    firmClientID,
    firmID,
  });

  if (!householdID || !firmID || !firmClientID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('real-estate-term')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('real-estate-term-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Rt" householdID={householdID} />
      {showScoreHealthRange && <ClientRealEstateTermScoreHealthRange firmID={firmID} firmClientID={firmClientID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('real-estate-term-element-detail-view-learn-more-body')}
        label={tPages('real-estate-term-element-detail-view-learn-more-label')}
      />
      <div className="mt-10 mb-10">
        {clickHandlers?.realEstateAssetOptions ? (
          <AnimatedFabDivider
            hoverWidth={280}
            label={tPages('real-estate-term-element-detail-view-fab-label')}
            icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
          />
        ) : (
          <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
        )}
      </div>
      <RealEstateEquityDetails householdID={householdID} />
      <RealEstateUnattachedLoans householdID={householdID} />
      <RealEstateEquitySummaryProportionChart householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
