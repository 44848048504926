import {
  AfterTaxInvestmentsSumTable,
  BusinessCashSumTable,
  LiquidAssetSummaryProportionChart,
  PersonalCashSumTable,
} from '../../../LtDetails';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  ClientLiquidTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
} from '../../components';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { UseLiquidTermElementDetailViewIndexPageArgs, useLiquidTermElementDetailViewIndexPage } from './hooks';

interface LiquidTermElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseLiquidTermElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function LiquidTermElementDetailViewIndexPage({
  firmID,
  firmClientID,
  downloadReportButton,
  showScoreHealthRange,
}: LiquidTermElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useLiquidTermElementDetailViewIndexPage({
    firmID,
    firmClientID,
  });

  if (!firmClientID || !firmID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        tooltipDescription={tPages('liquid-term-element-detail-view-header-tooltip-description')}
        title={tScorecard('liquid-term')}
        tooltipContainerClassName="!w-[296px]"
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Lt" householdID={householdID} />
      {showScoreHealthRange && <ClientLiquidTermScoreHealthRange firmClientID={firmClientID} firmID={firmID} />}
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <ElementEducationVideoTile
        label={tPages('liquid-term-element-detail-view-learn-more-label')}
        body={tPages('liquid-term-element-detail-view-learn-more-body')}
        thumbNail={<PenHandPaperPicture className="" />}
      />
      <div className="grid gap-6 mt-3 peer/sumTables">
        <PersonalCashSumTable householdID={householdID} />
        <BusinessCashSumTable householdID={householdID} />
        <AfterTaxInvestmentsSumTable householdID={householdID} />
      </div>
      <LiquidAssetSummaryProportionChart className="peer-empty/sumTables:mt-0 mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
