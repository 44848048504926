import { ElementsIconProps } from '../components/Icon/types';

export function ImportExportIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="import_export_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.64606 3.355L5.85606 6.145C5.53606 6.455 5.75606 6.995 6.20606 6.995H7.99606V13.005C7.99606 13.555 8.44606 14.005 8.99606 14.005C9.54606 14.005 9.99606 13.555 9.99606 13.005V6.995H11.7861C12.2361 6.995 12.4561 6.455 12.1361 6.145L9.34606 3.355C9.15606 3.165 8.83606 3.165 8.64606 3.355ZM15.9961 17.015V11.005C15.9961 10.455 15.5461 10.005 14.9961 10.005C14.4461 10.005 13.9961 10.455 13.9961 11.005V17.015H12.2061C11.7561 17.015 11.5361 17.555 11.8561 17.865L14.6461 20.645C14.8461 20.835 15.1561 20.835 15.3561 20.645L18.1461 17.865C18.4661 17.555 18.2361 17.015 17.7961 17.015H15.9961Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_607"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="14"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.64606 3.355L5.85606 6.145C5.53606 6.455 5.75606 6.995 6.20606 6.995H7.99606V13.005C7.99606 13.555 8.44606 14.005 8.99606 14.005C9.54606 14.005 9.99606 13.555 9.99606 13.005V6.995H11.7861C12.2361 6.995 12.4561 6.455 12.1361 6.145L9.34606 3.355C9.15606 3.165 8.83606 3.165 8.64606 3.355ZM15.9961 17.015V11.005C15.9961 10.455 15.5461 10.005 14.9961 10.005C14.4461 10.005 13.9961 10.455 13.9961 11.005V17.015H12.2061C11.7561 17.015 11.5361 17.555 11.8561 17.865L14.6461 20.645C14.8461 20.835 15.1561 20.835 15.3561 20.645L18.1461 17.865C18.4661 17.555 18.2361 17.015 17.7961 17.015H15.9961Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_607)"></g>
      </g>
    </svg>
  );
}
