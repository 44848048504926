import { FC } from 'react';
import { Bar } from '../types';
import { useElementSize } from '../../util';
import ReactECharts from 'echarts-for-react';
import { CHART_MIN_NUMBER_OF_LABELS, DEFAULT_CHART_GRID_BOTTOM } from '../constants';
import { GetChartSeriesOptionArgs, GetChartXAxisOptionArgs, getChartMetadata, getChartOptions } from '../util';

export interface ChartProps
  extends Pick<GetChartSeriesOptionArgs, 'barWidth' | 'barMinHeight'>,
    Pick<GetChartXAxisOptionArgs, 'rangeFilter'>,
    Partial<Pick<GetChartXAxisOptionArgs, 'numberOfLabels'>> {
  className?: string;
  data: Bar[];
}
export const Chart: FC<ChartProps> = ({
  data,
  barWidth,
  className,
  rangeFilter,
  barMinHeight,
  numberOfLabels = CHART_MIN_NUMBER_OF_LABELS,
}) => {
  const [boxRef, { width, height }] = useElementSize();

  const positiveData = data.map((d) => (d.value >= 0 ? d : null));
  const positiveMetadata = getChartMetadata(positiveData);
  
  const negativeData = data.map((d) => (d.value < 0 ? d : null));
  const negativeMetadata = getChartMetadata(negativeData);

  const positiveOptions = getChartOptions({
    xAxisArgs: { data, rangeFilter, numberOfLabels },
    yAxisArgs: {
      maxYValue: positiveMetadata.maxYValue,
      minYValue: positiveMetadata.minYValue,
      hasNegativeValues: negativeMetadata.hasValues,
      hasPositiveValues: positiveMetadata.hasValues,
    },
    seriesArgs: { data: positiveData, barWidth, barMinHeight, containerWidth: width },
    gridArgs: { overrides: { bottom: negativeMetadata.hasValues ? 8 : DEFAULT_CHART_GRID_BOTTOM } },
  });

  const negativeOptions = getChartOptions({
    gridArgs: {},
    yAxisArgs: {
      maxYValue: negativeMetadata.maxYValue,
      minYValue: negativeMetadata.minYValue,
      hasNegativeValues: negativeMetadata.hasValues,
      hasPositiveValues: positiveMetadata.hasValues,
    },
    xAxisArgs: { data, rangeFilter, numberOfLabels, overrides: { hide: true } },
    seriesArgs: { data: negativeData, barWidth, barMinHeight, containerWidth: width },
  });

  return (
    <div className={'w-full h-full'} ref={boxRef}>
      {positiveMetadata.hasValues && (
        <ReactECharts className={className} option={positiveOptions} style={{ width, height }} />
      )}
      {negativeMetadata.hasValues && (
        <ReactECharts className={className} option={negativeOptions} style={{ width, height }} />
      )}
    </div>
  );
};
