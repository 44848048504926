import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PercentageInput, PercentageInputProps } from '../../UI';
import { LoanFormValues } from '../types';

interface LoanInterestRateInputProps
  extends Pick<PercentageInputProps, 'label'> {}

export const LoanInterestRateInput: FC<LoanInterestRateInputProps> = ({
  label,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'interestRate'>({
    control,
    name: 'interestRate',
  });

  return (
    <PercentageInput
      {...field}
      decimalsLimit={3}
      status={errors.interestRate ? 'errored' : 'normal'}
      label={label ?? tForm('loan-interest-rate-input-label')}
    />
  );
};
