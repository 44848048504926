import { useStudentLoansSummarySumTable } from '../hooks';
import { StudentLoansSummarySumTableProps } from '../types';
import { displayLoanType, getLoanBalance } from '../../Loan';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function StudentLoansSummarySumTable({ householdID, ...props }: StudentLoansSummarySumTableProps) {
  const { isLoading, tUI, studentLoans, tLoan, totalStudentLoans } = useStudentLoansSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalStudentLoans}
      sumTitle={tUI('student-loans-summary-sum-table-total-title')}
    >
      {studentLoans?.map((loan) => (
        <AmountSumTableItem
          key={loan?.id}
          title={loan.name}
          amount={getLoanBalance(loan)}
          subtitle={displayLoanType(loan.loanType, tLoan)}
        />
      ))}
    </AmountSumTable>
  );
}
