{
  "annuity": "Annuity",
  "brokerage-account": "Brokerage Account",
  "cryptocurrency": "Cryptocurrency",
  "defined-benefit-plan": "Defined Benefit Plan",
  "health-savings-account": "Health Savings Account",
  "ira": "IRA",
  "minor-ira": "Minor IRA",
  "minor-roth-ira": "Minor Roth IRA",
  "private-stock": "Private Stock",
  "profit-sharing-account": "Profit Sharing Account",
  "qualified-tuition-plan-529": "Qualified Tuition Plan 529",
  "roth-ira": "Roth IRA",
  "roth-401(k)": "Roth 401(k)",
  "simple-ira": "SIMPLE IRA",
  "simplified-employee-pension": "Simplified Employee Pension",
  "stock-option": "Stock Option",
  "401(k)": "401(k)",
  "tax-sheltered-annuity-403(b)": "Tax Sheltered Annuity 403(b)",
  "universal-trust-for-minor": "Universal Trust for Minor",

  "after-tax-savings-contributions-sum-table-title": "After-Tax Savings",
  "after-tax-savings-contributions-sum-table-sum-title": "Total Annual Savings",

  "education-savings-contributions-sum-table-title": "Education Savings",
  "education-savings-contributions-sum-table-sum-title": "Total Annual Savings",

  "pre-tax-savings-contributions-sum-table-title": "Pre-Tax Savings",
  "pre-tax-savings-contributions-sum-table-sum-title": "Total Annual Savings",

  "private-stock-sum-table-title": "Private Stock",
  "private-stock-sum-table-sum-title": "Total Private Stock",

  "investment-mix-dont-know": "Don't Know",
  "investment-mix-very-conservative": "Very Conservative",
  "investment-mix-conservative": "Conservative",
  "investment-mix-moderate": "Moderate",
  "investment-mix-moderate-growth": "Moderate Growth",
  "investment-mix-balanced": "Balanced",
  "investment-mix-balanced-growth": "Balanced Growth",
  "investment-mix-growth": "Growth",
  "investment-mix-aggressive-growth": "Aggressive Growth",
  "investment-mix-equity-growth": "Equity Growth",

  "investment-mix-ratio-dont-know": "0/0",
  "investment-mix-ratio-very-conservative": "0/100",
  "investment-mix-ratio-conservative": "30/70",
  "investment-mix-ratio-moderate": "40/60",
  "investment-mix-ratio-moderate-growth": "50/50",
  "investment-mix-ratio-balanced": "60/40",
  "investment-mix-ratio-balanced-growth": "70/30",
  "investment-mix-ratio-growth": "80/20",
  "investment-mix-ratio-aggressive-growth": "90/10",
  "investment-mix-ratio-equity-growth": "100/0",

  "investment-details-table-investment-mix-subtitle": "{{- ratioDisplay}}, {{- mixDisplay}}"
}
