import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseInvestmentRealEstateLoansSummarySumTableArgs {
  householdID: string;
}

export function useInvestmentRealEstateLoansSummarySumTable({
  householdID,
}: UseInvestmentRealEstateLoansSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalInvestmentRealEstateLoans = ttDetails?.debtSummary.totalInvestmentRealEstateLoans;
  const investmentRealEstateLoans = ttDetails?.debtSummary.investmentRealEstateLoans.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return { investmentRealEstateLoans, totalInvestmentRealEstateLoans, tUI, isLoading, tLoan };
}
