import { useEffect, useMemo, useState } from 'react';
import { useScorecard } from '../queries';
import { ScorecardElementSymbol, getScorecardElementValue } from '../util';
import { MonetaryAmount } from '../../generated/graphql';
import { useDisplayMonetaryAmount } from '../../DataPoint';
import { useTranslation } from 'react-i18next';

interface DivisionElement {
  title: string;
  value: MonetaryAmount | undefined | null;
}

export interface UseScorecardElementCalculationGroupArgs {
  element: ScorecardElementSymbol;
  householdID: string | undefined | null;
}

export function useScorecardElementCalculationGroup({ element, householdID }: UseScorecardElementCalculationGroupArgs) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();
  const [numerator, setNumerator] = useState<DivisionElement>();
  const [denominator, setDenominator] = useState<DivisionElement>();
  const { scorecard, scorecardQuery } = useScorecard({ householdID });

  const isLoading = scorecardQuery.isLoading;
  const scorecardValue = getScorecardElementValue(scorecard, element);

  const getNumeratorData = useMemo((): DivisionElement => {
    switch (element) {
      case 'Lt': {
        return {
          value: scorecard?.liquidity,
          title: tScorecard('scorecard-element-calculation-group-liquid-term-numerator-title'),
        };
      }
      case 'Qt': {
        return {
          value: scorecard?.qualifiedAssets,
          title: tScorecard('scorecard-element-calculation-group-qualified-term-numerator-title'),
        };
      }
      case 'Tt': {
        return {
          value: scorecard?.netWorth,
          title: tScorecard('scorecard-element-calculation-group-total-term-numerator-title'),
        };
      }
      case 'Tr': {
        return {
          value: scorecard?.mostRecentTaxesPaid,
          title: tScorecard('scorecard-element-calculation-group-tax-rate-numerator-title'),
        };
      }
      case 'Dr': {
        return {
          value: scorecard?.annualDebtPayments,
          title: tScorecard('scorecard-element-calculation-group-debt-rate-numerator-title'),
        };
      }
      case 'Sr': {
        return {
          value: scorecard?.annualSavings,
          title: tScorecard('scorecard-element-calculation-group-savings-rate-numerator-title'),
        };
      }
      case 'Bt': {
        return {
          value: scorecard?.businessEquity,
          title: tScorecard('scorecard-element-calculation-group-business-term-numerator-title'),
        };
      }
      case 'Rt': {
        return {
          value: scorecard?.realEstateEquity,
          title: tScorecard('scorecard-element-calculation-group-real-estate-term-numerator-title'),
        };
      }
      case 'Br': {
        return {
          value: {
            currencyCode: 'USD',
            __typename: 'MonetaryAmount',
            value: scorecard?.annualExpenses?.value - scorecard?.annualDebtPayments?.value,
          },
          title: tScorecard('scorecard-element-calculation-group-burn-rate-numerator-title'),
        };
      }
      case 'Er': {
        return {
          value: scorecard?.equityMix,
          title: tScorecard('scorecard-element-calculation-group-equity-rate-numerator-title'),
        };
      }
      default: {
        return { title: '--', value: null };
      }
    }
  }, [scorecard, element]);

  const getDenominatorData = useMemo((): DivisionElement => {
    switch (element) {
      case 'Lt': {
        return {
          value: scorecard?.annualExpenses,
          title: tScorecard('scorecard-element-calculation-group-liquid-term-denominator-title'),
        };
      }
      case 'Qt': {
        return {
          value: scorecard?.annualExpenses,
          title: tScorecard('scorecard-element-calculation-group-qualified-term-denominator-title'),
        };
      }
      case 'Tt': {
        return {
          value: scorecard?.annualExpenses,
          title: tScorecard('scorecard-element-calculation-group-total-term-denominator-title'),
        };
      }
      case 'Tr': {
        return {
          value: scorecard?.mostRecentTaxedIncome,
          title: tScorecard('scorecard-element-calculation-group-tax-rate-denominator-title'),
        };
      }
      case 'Dr': {
        return {
          value: scorecard?.annualIncome,
          title: tScorecard('scorecard-element-calculation-group-debt-rate-denominator-title'),
        };
      }
      case 'Sr': {
        return {
          value: scorecard?.annualIncome,
          title: tScorecard('scorecard-element-calculation-group-savings-rate-denominator-title'),
        };
      }
      case 'Bt': {
        return {
          value: scorecard?.annualExpenses,
          title: tScorecard('scorecard-element-calculation-group-business-term-denominator-title'),
        };
      }
      case 'Rt': {
        return {
          value: scorecard?.annualExpenses,
          title: tScorecard('scorecard-element-calculation-group-real-estate-term-denominator-title'),
        };
      }
      case 'Br': {
        return {
          value: scorecard?.annualIncome,
          title: tScorecard('scorecard-element-calculation-group-burn-rate-denominator-title'),
        };
      }
      case 'Er': {
        return {
          value: scorecard?.investmentsAndCash,
          title: tScorecard('scorecard-element-calculation-group-equity-rate-denominator-title'),
        };
      }
      default: {
        return { title: '--', value: null };
      }
    }
  }, [scorecard, element]);

  useEffect(() => {
    setNumerator(getNumeratorData);
  }, [getNumeratorData]);

  useEffect(() => {
    setDenominator(getDenominatorData);
  }, [getDenominatorData]);

  return {
    isLoading,
    numerator,
    denominator,
    scorecardValue,
    displayMonetaryAmount,
  };
}
