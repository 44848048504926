import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseTotalTermElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useTotalTermElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseTotalTermElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
