import { useTranslation } from 'react-i18next';
import { useLtDetails } from '../queries';

export interface UsePersonalCashSumTableArgs {
  householdID: string | undefined | null;
}

export function usePersonalCashSumTable({
  householdID,
}: UsePersonalCashSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { ltDetails, ltDetailsQuery } = useLtDetails({
    householdID,
    includePersonalCashItems: true,
    includeTotalPersonalCash: true,
  });

  const isLoading = ltDetailsQuery.isLoading;

  return { isLoading, ltDetails, tUI, tCashAccount };
}
