import { TargetScoreOperator } from '../types';
import {
  deriveSingularInputMinMax,
  getSingularTargetScoreRangeBehaviorForElement,
  targetScoreRangeItemFormValue,
} from '../util';
import { UseDeriveTargetScoreRangeArgs, useDeriveTargetScoreRange } from './useDeriveTargetScoreRange';

interface UseIsTargetScoreRangeModifiedArgs extends UseDeriveTargetScoreRangeArgs {
  maxDirty: boolean;
  minDirty: boolean;
  minValue: number | null;
  maxValue: number | null;
  operator: TargetScoreOperator;
}

export function useIsTargetScoreRangeModified({
  element,
  maxValue,
  minValue,
  maxDirty,
  minDirty,
  ageGroup,
  operator,
}: UseIsTargetScoreRangeModifiedArgs) {
  const { targetScoreRange } = useDeriveTargetScoreRange({ ageGroup, element });

  const minResetsTo = !targetScoreRange?.customized ? targetScoreRange?.range?.min : targetScoreRange?.resetsTo?.min;
  const maxResetsTo = !targetScoreRange?.customized ? targetScoreRange?.range?.max : targetScoreRange?.resetsTo?.max;
  const formMinResetsTo = targetScoreRangeItemFormValue(minResetsTo, element);
  const formMaxResetsTo = targetScoreRangeItemFormValue(maxResetsTo, element);
  let isDirty: boolean;
  let rangeIsSameAsResetTo: boolean;
  if (operator === TargetScoreOperator.equals) {
    const singleValueBehavior = getSingularTargetScoreRangeBehaviorForElement(element);
    const evalValue = deriveSingularInputMinMax(minValue, maxValue, singleValueBehavior);
    const formResetsTo = deriveSingularInputMinMax(formMinResetsTo, formMaxResetsTo, singleValueBehavior);
    isDirty = deriveSingularInputMinMax(minDirty, maxDirty, singleValueBehavior);
    rangeIsSameAsResetTo = evalValue === formResetsTo;
  } else {
    rangeIsSameAsResetTo = minValue === formMinResetsTo && maxValue === formMaxResetsTo;
    isDirty = minDirty || maxDirty;
  }

  const isTargetScoreRangeModified =
    (targetScoreRange?.customized && !rangeIsSameAsResetTo) || (isDirty && !rangeIsSameAsResetTo);

  return {
    minResetsTo,
    maxResetsTo,
    formMinResetsTo,
    formMaxResetsTo,
    isTargetScoreRangeModified,
  };
}
