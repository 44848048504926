import { FC, createContext, useContext, useEffect, useRef } from 'react';

interface ILocalThemeContext {
  colorPrimary?: string | null;
}

interface LocalThemeContextProps extends ILocalThemeContext {
  children?: React.ReactNode;
}

const LocalThemeContext = createContext<ILocalThemeContext>({
  colorPrimary: '#4AF196',
});

export const useLocalThemeContext = () => useContext(LocalThemeContext);

export const LocalThemeContextProvider: FC<LocalThemeContextProps> = ({
  children,
  colorPrimary,
}) => {
  const localThemeContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentElement = localThemeContainerRef.current;
    if (currentElement && colorPrimary) {
      currentElement.style.setProperty(
        '--color-primary',
        hextorgb(colorPrimary).join(' ')
      );
    }
  }, [colorPrimary]);

  return (
    <LocalThemeContext.Provider value={{ colorPrimary }}>
      <div className="contents" ref={localThemeContainerRef}>
        {children}
      </div>
    </LocalThemeContext.Provider>
  );
};

const hexRegex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

// Modified Code Snippet originally by StackOverflow user rezoner: https://stackoverflow.com/a/14101452
function hextorgb(hex: string) {
  if (!hexRegex.test(hex)) {
    throw new Error('Passed string is not hex. Function: hextorgb');
  }
  return [
    // @ts-expect-error ignore type
    ('0x' + hex[1] + hex[2]) | 0,
    // @ts-expect-error ignore type
    ('0x' + hex[3] + hex[4]) | 0,
    // @ts-expect-error ignore type
    ('0x' + hex[5] + hex[6]) | 0,
  ];
}
