import { ElementsIconProps } from '../components/Icon/types';

export function VpnKeyIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vpn_key_24px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6491 10.001C11.6991 7.31104 8.89912 5.50104 5.76912 6.12104C3.47912 6.58104 1.61912 8.41104 1.13912 10.701C0.319116 14.571 3.25912 18.001 6.99912 18.001C9.60912 18.001 11.8291 16.331 12.6491 14.001H16.9991V16.001C16.9991 17.101 17.8991 18.001 18.9991 18.001C20.0991 18.001 20.9991 17.101 20.9991 16.001V14.001C22.0991 14.001 22.9991 13.101 22.9991 12.001C22.9991 10.901 22.0991 10.001 20.9991 10.001H12.6491ZM6.99912 14.001C5.89912 14.001 4.99912 13.101 4.99912 12.001C4.99912 10.901 5.89912 10.001 6.99912 10.001C8.09912 10.001 8.99912 10.901 8.99912 12.001C8.99912 13.101 8.09912 14.001 6.99912 14.001Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
