import { useInvestments } from '../queries';
import { useTranslation } from 'react-i18next';
import {
  getAfterTaxSavingsInvestments,
  getInvestmentsWithRecurringContributions,
  getTotalRecurringContributionsForInvestments,
} from '../util';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { recurringMonetaryAmountConverter } from '../../util';

export interface UseAfterTaxSavingsContributionsSumTableArgs {
  householdID: string | undefined | null;
}

export function useAfterTaxSavingsContributionsSumTable({
  householdID,
}: UseAfterTaxSavingsContributionsSumTableArgs) {
  const { displayRecurringMonetaryAmount } =
    useDisplayRecurringMonetaryAmount();
  const { t: tInvestment } = useTranslation('investment');
  const { investments, investmentsQuery } = useInvestments({ householdID });

  const isLoading = investmentsQuery.isLoading;
  const investmentsWithSavings = getInvestmentsWithRecurringContributions(
    getAfterTaxSavingsInvestments(investments)
  );
  const annualContributions = getTotalRecurringContributionsForInvestments(
    investmentsWithSavings,
    { frequency: RecurringFrequency.Annually }
  );
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    recurringMonetaryAmountConverter(
      annualContributions,
      RecurringFrequency.Monthly
    )
  );

  return {
    isLoading,
    tInvestment,
    annualContributions,
    investmentsWithSavings,
    monthlyContributionsDisplay,
  };
}
