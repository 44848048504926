import { ElementsIconProps } from '../components/Icon/types';

export function TrendUpIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="trend_up_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29624 5.70375C9.29624 6.26375 9.74624 6.70375 10.2962 6.70375H15.8862L4.99624 17.5938C4.60624 17.9838 4.60624 18.6138 4.99624 19.0038C5.38624 19.3938 6.01624 19.3938 6.40624 19.0038L17.2962 8.11375V13.7038C17.2962 14.2538 17.7462 14.7038 18.2962 14.7038C18.8462 14.7038 19.2962 14.2538 19.2962 13.7038V5.70375C19.2962 5.15375 18.8462 4.70375 18.2962 4.70375H10.2962C9.74624 4.70375 9.29624 5.15375 9.29624 5.70375Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_580"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.29624 5.70375C9.29624 6.26375 9.74624 6.70375 10.2962 6.70375H15.8862L4.99624 17.5938C4.60624 17.9838 4.60624 18.6138 4.99624 19.0038C5.38624 19.3938 6.01624 19.3938 6.40624 19.0038L17.2962 8.11375V13.7038C17.2962 14.2538 17.7462 14.7038 18.2962 14.7038C18.8462 14.7038 19.2962 14.2538 19.2962 13.7038V5.70375C19.2962 5.15375 18.8462 4.70375 18.2962 4.70375H10.2962C9.74624 4.70375 9.29624 5.15375 9.29624 5.70375Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_580)"></g>
      </g>
    </svg>
  );
}
