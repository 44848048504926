import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseOtherDebtSummarySumTableArgs {
  householdID: string;
}

export function useOtherDebtSummarySumTable({ householdID }: UseOtherDebtSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalOtherDebt = ttDetails?.debtSummary.totalOtherDebt;
  const otherDebt = ttDetails?.debtSummary.otherDebt.sort((a, b) => a.name.localeCompare(b.name));

  return { otherDebt, totalOtherDebt, tUI, isLoading, tLoan };
}
