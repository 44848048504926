import { InvestmentDetailsTable, InvestmentsMixProportionChart } from '../../../ErDetails';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  ClientEquityRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
} from '../../components';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { UseEquityRateElementDetailViewIndexPageArgs, useEquityRateElementDetailViewIndexPage } from './hooks';

interface EquityRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseEquityRateElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function EquityRateElementDetailViewIndexPage({
  firmID,
  firmClientID,
  downloadReportButton,
  showScoreHealthRange,
}: EquityRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useEquityRateElementDetailViewIndexPage({
    firmClientID,
    firmID,
  });

  if (!householdID || !firmClientID || !firmID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('equity-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('equity-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Er" householdID={householdID} />
      {showScoreHealthRange && <ClientEquityRateScoreHealthRange firmID={firmID} firmClientID={firmClientID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('equity-rate-element-detail-view-learn-more-body')}
        label={tPages('equity-rate-element-detail-view-learn-more-label')}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <InvestmentDetailsTable householdID={householdID} />
      <InvestmentsMixProportionChart householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
