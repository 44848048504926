import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseOtherAssetsSummarySumTableArgs {
  householdID: string;
}

export function useOtherAssetsSummarySumTable({ householdID }: UseOtherAssetsSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalOtherAssets = ttDetails?.assetSummary.totalOtherAssets;
  const personalPropertyItems = ttDetails?.assetSummary.personalPropertyItems.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const notesReceivable = ttDetails?.assetSummary.noteReceivables.sort((a, b) => a.name.localeCompare(b.name));

  return { notesReceivable, totalOtherAssets, personalPropertyItems, tUI, isLoading };
}
