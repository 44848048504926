import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { useInvestmentRealEstateSummarySumTable } from '../hooks';
import { InvestmentRealEstateSummarySumTableProps } from '../types';
import { displayRealEstateType, getRealEstatePropertyValue } from '../../RealEstateProperty';

export function InvestmentRealEstateSummarySumTable({
  householdID,
  ...props
}: InvestmentRealEstateSummarySumTableProps) {
  const { isLoading, tUI, investmentRealEstateProperties, tRealEstate, totalInvestmentRealEstate } =
    useInvestmentRealEstateSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalInvestmentRealEstate}
      sumTitle={tUI('investment-real-estate-summary-sum-table-total-title')}
    >
      {investmentRealEstateProperties?.map((realEstateProperty) => (
        <AmountSumTableItem
          key={realEstateProperty?.id}
          title={realEstateProperty.name}
          amount={getRealEstatePropertyValue(realEstateProperty)}
          subtitle={displayRealEstateType(realEstateProperty.realEstateType, tRealEstate)}
        />
      ))}
    </AmountSumTable>
  );
}
