import { ElementsIconProps } from '../components/Icon/types';

export function BeachUmbrellaIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="beach_umbrella_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.13 14.56L14.56 13.13L20.29 18.86C20.68 19.25 20.68 19.89 20.29 20.29C19.9 20.68 19.26 20.68 18.86 20.29L13.13 14.56ZM17.42 8.83L18.69 7.56C19.58 6.67 19.46 5.13 18.38 4.48C14.49 2.1 9.35 2.59 5.98 5.95C9.91 4.65 14.29 5.7 17.42 8.83ZM5.95 5.98C2.59 9.35 2.1 14.49 4.47 18.38C5.13 19.46 6.66 19.59 7.55 18.69L8.82 17.42C5.7 14.29 4.65 9.91 5.95 5.98ZM5.97 5.96L5.96 5.97C5.58 8.98 7.13 12.85 10.26 15.99L15.99 10.26C12.86 7.13 8.98 5.58 5.97 5.96Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
