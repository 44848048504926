export const formattedPercentage = (percentageString?: string | null): number =>
  (percentageStringToNumber(percentageString) ?? 0) / 100;

export const percentageStringToNumber = (percentageString?: string | null) => {
  const sanitizedPercentageString = percentageString?.replace(/%/g, '');

  if (isNaN(Number(sanitizedPercentageString))) {
    return 0;
  }

  return Number(sanitizedPercentageString);
};

export const displayFloat = (num?: number | null, decimal = 2) => {
  const adjustedNum = num ?? 0;
  return parseFloat(adjustedNum.toFixed(decimal)).toString();
};

export interface DisplayPercentageOptions {
  decimal?: number;
  withSymbol?: boolean;
  zeroIsDash?: boolean;
}

export const displayPercentage = (
  num?: number | null,
  { decimal = 2, withSymbol = true, zeroIsDash = false }: DisplayPercentageOptions = {
    decimal: 2,
    withSymbol: true,
  }
) => {
  const suffix = withSymbol ? '%' : '';
  const number = Number(num);
  if (isNaN(number) || num === null || (number === 0 && zeroIsDash)) {
    return '––';
  }
  if (!isFinite(number)) {
    return `0${suffix}`;
  }
  return `${displayFloat((num ?? 0) * 100, decimal)}${suffix}`;
};

export const getRange = (min: number, max: number) => Array.from(Array(max + 1 - min), (_, i) => i + min);

export interface DisplayRateOptions extends Omit<DisplayPercentageOptions, 'decimal'> {}

export const displayRate = (number?: number | null, opt?: DisplayRateOptions) => {
  let boundedNumber = number;
  if (!isNaN(Number(boundedNumber))) {
    boundedNumber = Math.min(Math.max(number ?? 0, 0), 1);
  }

  return displayPercentage(boundedNumber, {
    decimal: 0,
    withSymbol: opt?.withSymbol,
    zeroIsDash: opt?.zeroIsDash,
  });
};

export const displayTerm = (number?: number | null) => {
  if (isNaN(Number(number)) || number === null) return '––';
  return number?.toFixed(1) as string;
};

export const isDashed = (str: string) => str.includes('––');

/**
 * Formats an integer into a string with one decimal place, or a float into a string with two decimal places.
 * @param num
 */
export const getFormattedNumberLabel = (num?: number | null) => {
  const decimalPlaces = Number.isInteger(num)
    ? 1
    : 2;
  return num?.toFixed(decimalPlaces);
};
