import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useInvestmentForm } from '../hooks';
import { InvestmentFormContextProvider } from '../context';

interface InvestmentFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useInvestmentFormReturn: ReturnType<typeof useInvestmentForm>;
}

export const InvestmentForm: FC<InvestmentFormProps> = ({
  children,
  className,
  useInvestmentFormReturn: { onSubmit, formMethods, investment, householdID },
}) => {
  return (
    <FormProvider {...formMethods}>
      <InvestmentFormContextProvider householdID={householdID} investment={investment}>
        <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </InvestmentFormContextProvider>
    </FormProvider>
  );
};
