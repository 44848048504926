import { ElementsIconProps } from '../components/Icon/types';

export function SendIcon(props: ElementsIconProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_128_739)">
        <path
          d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_128_739">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
