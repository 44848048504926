import clsx from 'clsx';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputContainer, InputInlineToggle } from '../../UI';
import { elementsInputClasses } from '../../UI/components/Form/Input/util';
import { RecurringFrequency } from '../../generated/graphql';
import { LoanFormValues } from '../types';

export const LoanLengthInput = () => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'lengthOfLoan'>({
    control,
    name: 'lengthOfLoan',
  });

  return (
    <InputContainer
      label={tForm('loan-length-of-loan-input-label')}
      status={errors.nickname ? 'errored' : 'normal'}
      trailing={
        <InputInlineToggle
          onChange={(index) => {
            field.onChange({
              length: field.value?.length ?? null,
              frequency:
                index === 0
                  ? RecurringFrequency.Monthly
                  : RecurringFrequency.Annually,
            });
          }}
          selected={
            field.value?.frequency === RecurringFrequency.Monthly ? 0 : 1
          }
          items={[
            tForm('general-frequency-input-frequency-month-abbr'),
            tForm('general-frequency-input-frequency-year-abbr'),
          ]}
        />
      }
    >
      <input
        min={0}
        step={1}
        type="number"
        ref={field.ref}
        id="lengthOfLoan"
        name="lengthOfLoan"
        onBlur={field.onBlur}
        value={field.value?.length ?? ''}
        onChange={(e) => {
          if (e.target.value === '') return field.onChange(null);
          field.onChange({
            length: Number(e.target.value),
            frequency: field.value?.frequency ?? RecurringFrequency.Monthly,
          });
        }}
        className={clsx(elementsInputClasses.input, 'number-no-step')}
      />
    </InputContainer>
  );
};
