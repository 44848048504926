import { ElementsIconProps } from '../components/Icon/types';

export function DoneIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="done_24px">
        <g id="Group">
          <path
            id="Color"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8 15.905L5.3 12.405C5.11514 12.2178 4.86305 12.1125 4.6 12.1125C4.33695 12.1125 4.08485 12.2178 3.9 12.405C3.51 12.795 3.51 13.415 3.9 13.805L8.09 17.995C8.48 18.385 9.11 18.385 9.5 17.995L20.1 7.40499C20.49 7.01499 20.49 6.39499 20.1 6.00499C19.9151 5.81784 19.663 5.71251 19.4 5.71251C19.1369 5.71251 18.8849 5.81784 18.7 6.00499L8.8 15.905Z"
            fill="currentColor"
          />
          <mask
            id="mask0_0_114"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="5"
            width="18"
            height="14"
          >
            <path
              id="Color_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.8 15.905L5.3 12.405C5.11514 12.2178 4.86305 12.1125 4.6 12.1125C4.33695 12.1125 4.08485 12.2178 3.9 12.405C3.51 12.795 3.51 13.415 3.9 13.805L8.09 17.995C8.48 18.385 9.11 18.385 9.5 17.995L20.1 7.40499C20.49 7.01499 20.49 6.39499 20.1 6.00499C19.9151 5.81784 19.663 5.71251 19.4 5.71251C19.1369 5.71251 18.8849 5.81784 18.7 6.00499L8.8 15.905Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_0_114)"></g>
        </g>
      </g>
    </svg>
  );
}
