import { ElementsIconProps } from '../components/Icon/types';

export function SortArrowDescIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7037 18.0038L15.2937 15.4138C15.9237 14.7838 15.4837 13.7038 14.5937 13.7038L13.4999 13.7038L13.4999 9.5C13.4999 8.67157 12.8284 8 11.9999 8C11.1715 8 10.4999 8.67157 10.4999 9.5L10.4999 13.7038L9.41373 13.7038C8.52373 13.7038 8.07373 14.7838 8.70373 15.4138L11.2937 18.0038C11.6837 18.3938 12.3137 18.3938 12.7037 18.0038Z"
        fill="currentColor"
      />
    </svg>
  );
}
