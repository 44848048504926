import { ElementsIconProps } from '../components/Icon/types';

export function TrustsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="trusts_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM15.59 5.59L20.42 10.42C20.79 10.79 21 11.3 21 11.83V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H14.17C14.7 5 15.21 5.21 15.59 5.59ZM15 12H19.5L14 6.5V11C14 11.55 14.45 12 15 12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
