import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

export interface SideOverHeadingProps
  extends Pick<ComponentPropsWithoutRef<'h1'>, 'className'> {
  heading: string;
}

export const SideOverHeading: FC<SideOverHeadingProps> = ({
  heading,
  className,
}) => {
  return (
    <h1
      className={clsx(
        'mt-[43px] text-5xl font-bold leading-normal tracking-normal text-white',
        className
      )}
    >
      {heading}
    </h1>
  );
};
