import { ScorecardElementSymbol } from '../Scorecard';

export const isDarkTheme = () => {
  if (typeof document === 'undefined') return true;

  const htmlElement = document.getElementsByTagName('html')[0];
  return htmlElement.classList.contains('dark');
};

export const lightModeColors = {
  snow: '#F6F9F9',
  accent: '#19BE81',
  notifications: '#FF2AAC',
  gray: {
    light: '#838C89',
  },
  purple: {
    light: '#DCC3F9',
  },
} as const;

export const darkModeColors = {
  accent: '#4AF196',
  danger: '#FF154F',
  warning: '#FFE26C',
  white: '#FFFFFF',
  almostBlack: '#101112',
  neutral: '#212326',
  gray: {
    light: '#ACB7B2',
    medium: '#585F66',
    medium20: '#1C1D1F',
    dark: '#17181A',
    dropdown: '#212325',
    floatingMenu: '#2B2E31',
    background: '#0C0C0E',
    mobileTile: '#585F6629',
  },
} as const;

export const elementsColors: Record<ScorecardElementSymbol, string> = {
  Ir: '#169FFF',
  Er: '#16A104',
  Sr: '#30948F',
  Br: '#C035DD',
  Dr: '#006E42',
  Tr: '#FFBD01',
  Lt: '#FF8C0C',
  Qt: '#8A31F1',
  Rt: '#274ECC',
  Bt: '#FF144F',
  Tt: '#93CE00',
} as const;

export const hiContrastElementsColors: Partial<Record<ScorecardElementSymbol, string>> = {
  Qt: '#B675FF',
};

export const elementsTitleTextColors: Record<ScorecardElementSymbol, string> = {
  Ir: darkModeColors.white,
  Er: darkModeColors.white,
  Sr: darkModeColors.white,
  Br: darkModeColors.white,
  Dr: darkModeColors.white,
  Tr: darkModeColors.gray.dark,
  Lt: darkModeColors.gray.dark,
  Qt: darkModeColors.white,
  Rt: darkModeColors.white,
  Bt: darkModeColors.gray.dark,
  Tt: darkModeColors.gray.dark,
} as const;

export const elementsTitleTextTailwindClasses: Record<ScorecardElementSymbol, string> = {
  Ir: `text-white`,
  Er: `text-white`,
  Sr: `text-white`,
  Br: `text-white`,
  Dr: `text-white`,
  Tr: `text-darkMode-gray-medium mix-blend-multiply`,
  Lt: `text-darkMode-gray-medium mix-blend-multiply`,
  Qt: `text-white`,
  Rt: `text-white`,
  Bt: `text-white`,
  Tt: `text-darkMode-gray-medium mix-blend-multiply`,
} as const;

export const darkElementsColors: Record<ScorecardElementSymbol, string> = {
  Ir: '#0172C2',
  Er: '',
  Sr: '',
  Br: '',
  Dr: '',
  Tr: '#a37a00',
  Lt: '',
  Qt: '',
  Rt: '',
  Bt: '',
  Tt: '',
} as const;

export const breakPoints = {
  web: '640px',
} as const;

export function isLight(hex: string): boolean {
  let r: number;
  let g: number;
  let b: number;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  } else {
    throw new Error('Invalid Hex Value');
  }

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128;
}
