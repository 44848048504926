import { useTranslation } from 'react-i18next';
import { useQtDetails } from '../queries';

export interface UseQtPreTaxInvestmentsSumTableArgs {
  householdID: string | undefined | null;
}

export function useQtPreTaxInvestmentsSumTable({
  householdID,
}: UseQtPreTaxInvestmentsSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { qtDetails, qtDetailsQuery } = useQtDetails({
    householdID,
    includeTotalTaxDeferred: true,
    includeTaxDeferredAccounts: true,
  });

  const isLoading = qtDetailsQuery.isLoading;

  return { isLoading, qtDetails, tUI, tInvestment };
}
