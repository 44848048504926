import { useInvestments } from '../queries';
import { useTranslation } from 'react-i18next';
import {
  getEducationSavingsInvestments,
  getInvestmentsWithRecurringContributions,
  getTotalRecurringContributionsForInvestments,
} from '../util';
import { recurringMonetaryAmountConverter } from '../../util';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';

export interface UseEducationSavingsContributionsSumTableArgs {
  householdID: string | undefined | null;
}

export function useEducationSavingsContributionsSumTable({
  householdID,
}: UseEducationSavingsContributionsSumTableArgs) {
  const { t: tInvestment } = useTranslation('investment');
  const { investments, investmentsQuery } = useInvestments({ householdID });
  const { displayRecurringMonetaryAmount } =
    useDisplayRecurringMonetaryAmount();

  const isLoading = investmentsQuery.isLoading;
  const investmentsWithSavings = getInvestmentsWithRecurringContributions(
    getEducationSavingsInvestments(investments)
  );
  const annualContributions = getTotalRecurringContributionsForInvestments(
    investmentsWithSavings,
    { frequency: RecurringFrequency.Annually }
  );
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    recurringMonetaryAmountConverter(
      annualContributions,
      RecurringFrequency.Monthly
    )
  );

  return {
    isLoading,
    tInvestment,
    monthlyContributionsDisplay,
    investmentsWithSavings,
    annualContributions,
  };
}
