import clsx from 'clsx';
import { FC } from 'react';
import { InputSize, InputStatus } from '../types';
import { elementsInputClasses } from '../util';

export interface InputContainerProps {
  children?: React.ReactNode;
  status?: InputStatus;
  label?: string;
  trailing?: JSX.Element | boolean;
  leading?: JSX.Element | boolean;
  onClick?: () => void;
  helperText?: string | boolean | null;
  isReadOnly?: boolean;
  className?: HTMLDivElement['className'];
  size?: InputSize;
}

export const InputContainer: FC<InputContainerProps> = ({
  children,
  status = 'normal',
  helperText,
  label,
  leading,
  onClick,
  trailing,
  isReadOnly,
  className,
  size = 'default',
}) => {
  return (
    <div
      className={clsx(
        className,
        elementsInputClasses.status(status),
        elementsInputClasses.size(size)
      )}
    >
      <div
        className={elementsInputClasses.container}
        onClick={() => {
          if (status === 'disabled' || isReadOnly) return;
          onClick?.();
        }}
      >
        {leading && (
          <div className={elementsInputClasses.leading}>{leading}</div>
        )}
        <label className={elementsInputClasses.label}>
          {label}
          {children}
        </label>
        {trailing && (
          <div className={elementsInputClasses.trailing}>{trailing}</div>
        )}
      </div>
      {helperText && (
        <p className={elementsInputClasses.helperText}>{helperText}</p>
      )}
    </div>
  );
};
