import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { usePersonalRealEstateSummarySumTable } from '../hooks';
import { PersonalRealEstateSummarySumTableProps } from '../types';
import { displayRealEstateType, getRealEstatePropertyValue } from '../../RealEstateProperty';

export function PersonalRealEstateSummarySumTable({ householdID, ...props }: PersonalRealEstateSummarySumTableProps) {
  const { isLoading, tUI, personalRealEstateProperties, tRealEstate, totalPersonalRealEstate } =
    usePersonalRealEstateSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalPersonalRealEstate}
      sumTitle={tUI('personal-real-estate-summary-sum-table-total-title')}
    >
      {personalRealEstateProperties?.map((realEstateProperty) => (
        <AmountSumTableItem
          key={realEstateProperty?.id}
          title={realEstateProperty.name}
          amount={getRealEstatePropertyValue(realEstateProperty)}
          subtitle={displayRealEstateType(realEstateProperty.realEstateType, tRealEstate)}
        />
      ))}
    </AmountSumTable>
  );
}
