import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { InputContainer, InputContainerProps } from './components';
import { elementsInputClasses } from './util';

export type InputElementProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'onClick' | 'name' | 'disabled' | 'onChange' | 'onBlur' | 'id' | 'className'
>;

export interface InputProps
  extends Omit<InputContainerProps, 'isReadOnly' | 'children'> {
  name?: string;
  onChange?: ComponentPropsWithoutRef<'input'>['onChange'];
  onBlur?: ComponentPropsWithoutRef<'input'>['onBlur'];
  inputProps?: InputElementProps;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { name, onBlur, status, onChange, inputProps, ...inputContainerProps },
    ref
  ) => {
    return (
      <InputContainer
        status={status}
        {...inputContainerProps}
        isReadOnly={!!inputProps?.readOnly}
      >
        <input
          id={name}
          ref={ref}
          name={name}
          {...inputProps}
          onBlur={onBlur}
          onChange={onChange}
          disabled={status === 'disabled'}
          className={elementsInputClasses.input}
        />
      </InputContainer>
    );
  }
);
