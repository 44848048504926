import clsx from 'clsx';
import { FC } from 'react';
import { useClickAnchorContext } from '../../../ClickMenu/context';
import { Icon } from '../../../Icon';
import { useDropdownContext } from '../dropdown.context';
import { IDropdownOption } from '../types';

export interface DropdownOptionProps extends IDropdownOption {
  subtitle?: string;
  disabled?: boolean;
  leading?: JSX.Element;
  className?: HTMLButtonElement['className'];
}

export const DropdownOption: FC<DropdownOptionProps> = ({
  id,
  label,
  className,
  leading,
  value,
  disabled,
  subtitle,
}) => {
  const { selected, setSelected } = useDropdownContext();
  const { setOpen } = useClickAnchorContext();

  return (
    <button
      type="button"
      onClick={() => {
        setOpen(false);
        if (value) {
          setSelected({ id, label, value });
        } else {
          setSelected(null);
        }
      }}
      className={clsx(
        'group flex px-4 py-3',
        id !== 'Dropdown_clear_selection' &&
          'focus:bg-primary hover:bg-primary focus:dark:bg-primary hover:dark:bg-primary',
        disabled && 'opacity-40',
        className
      )}
      disabled={disabled}
    >
      {leading && <div className="mr-3 text-primary group-hover:text-white">{leading}</div>}
      <div className="flex flex-col items-start flex-1">
        <span
          className={clsx(
            id === 'Dropdown_clear_selection'
              ? 'dark:text-darkMode-gray-light/70 text-darkMode-gray-light group-hover:text-primary group-focus:text-primary'
              : 'dark:text-white text-darkMode-gray-medium group-hover:text-white dark:group-hover:text-darkMode-gray-dark group-hover:font-medium',
            'text-left text-lg !leading-6 '
          )}
        >
          {label}
        </span>
        {subtitle && (
          <p className={clsx('text-sm leading-[18px] dark:group-hover:text-darkMode-gray-dark')}>{subtitle}</p>
        )}
      </div>
      {selected?.id === id && (
        <Icon
          icon="check"
          className="ml-3 text-primary group-hover:text-white dark:group-hover:text-darkMode-gray-dark group-hover:font-medium"
        />
      )}
    </button>
  );
};
