import { ElementsIconProps } from '../components/Icon/types';

export function GradeIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="grade_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9974 17.6467L17.1674 20.7667C17.5474 20.9967 18.0174 20.6567 17.9174 20.2267L16.5474 14.3467L21.1074 10.3967C21.4374 10.1067 21.2674 9.55666 20.8174 9.51666L14.8074 9.00666L12.4574 3.46666C12.2874 3.05666 11.7074 3.05666 11.5374 3.46666L9.18743 9.00666L3.17743 9.51666C2.73743 9.55666 2.55743 10.1067 2.89743 10.3967L7.45743 14.3467L6.08743 20.2267C5.98743 20.6567 6.45743 20.9967 6.83743 20.7667L11.9974 17.6467Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_160"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="20"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9974 17.6467L17.1674 20.7667C17.5474 20.9967 18.0174 20.6567 17.9174 20.2267L16.5474 14.3467L21.1074 10.3967C21.4374 10.1067 21.2674 9.55666 20.8174 9.51666L14.8074 9.00666L12.4574 3.46666C12.2874 3.05666 11.7074 3.05666 11.5374 3.46666L9.18743 9.00666L3.17743 9.51666C2.73743 9.55666 2.55743 10.1067 2.89743 10.3967L7.45743 14.3467L6.08743 20.2267C5.98743 20.6567 6.45743 20.9967 6.83743 20.7667L11.9974 17.6467Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_160)"></g>
      </g>
    </svg>
  );
}
