import { Loan } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import {
  displayInterestRateType,
  displayLoanType,
  getLoanInterestRateDisplay,
} from '../util';

export function useDisplayLoanAmountSumTableItemSubtitle() {
  const { t: tLoan } = useTranslation('loan');

  function displayLoanAmountSumTableItemSubtitle(loan: Loan) {
    let subtitle = displayLoanType(loan.loanType, tLoan);
    const interestRate = getLoanInterestRateDisplay(loan);

    if (interestRate || loan.interestRateType) subtitle = subtitle.concat(' |');

    if (interestRate) {
      subtitle = subtitle.concat(` ${interestRate}`);
    }
    if (loan.interestRateType) {
      subtitle = subtitle.concat(
        ` ${displayInterestRateType(loan.interestRateType, tLoan)}`
      );
    }

    return subtitle;
  }

  return { displayLoanAmountSumTableItemSubtitle };
}
