import {
  displayInvestmentType,
  getInvestmentValue,
  getLastInvestmentUpdateDate,
} from '../../Investment';
import {
  AmountSumTable,
  AmountSumTableItem,
  AmountSumTableProps,
} from '../../UI';
import {
  UseQtAfterTaxInvestmentsSumTableArgs,
  useQtAfterTaxInvestmentsSumTable,
} from '../hooks';

interface QtAfterTaxInvestmentsSumTableProps
  extends UseQtAfterTaxInvestmentsSumTableArgs,
    Pick<AmountSumTableProps, 'hideOnEmpty'> {
  className?: string;
}

export function QtAfterTaxInvestmentsSumTable({
  className,
  hideOnEmpty,
  ...useQtTaxFreeInvestmentsSumTableArgs
}: QtAfterTaxInvestmentsSumTableProps) {
  const { isLoading, qtDetails, tUI, tInvestment } =
    useQtAfterTaxInvestmentsSumTable(useQtTaxFreeInvestmentsSumTableArgs);

  return (
    <AmountSumTable
      className={className}
      isLoading={isLoading}
      sum={qtDetails?.totalTaxFree}
      hideOnEmpty={hideOnEmpty ?? true}
      title={tUI('qt-after-tax-investments-table-title')}
      sumTitle={tUI('qt-after-tax-investments-table-sum-title')}
    >
      {qtDetails?.taxFreeAccounts.map((investment) => (
        <AmountSumTableItem
          key={investment.id}
          title={investment.name}
          amount={getInvestmentValue(investment)}
          lastUpdated={getLastInvestmentUpdateDate(investment)}
          subtitle={displayInvestmentType(
            investment.investmentType,
            tInvestment
          )}
        />
      ))}
    </AmountSumTable>
  );
}
