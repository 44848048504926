import { useInvestmentRealEstateLoansSummarySumTable } from '../hooks';
import { InvestmentRealEstateLoansSummarySumTableProps } from '../types';
import { displayLoanType, getLoanBalance } from '../../Loan';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function InvestmentRealEstateLoansSummarySumTable({
  householdID,
  ...props
}: InvestmentRealEstateLoansSummarySumTableProps) {
  const { isLoading, tUI, investmentRealEstateLoans, tLoan, totalInvestmentRealEstateLoans } =
    useInvestmentRealEstateLoansSummarySumTable({
      householdID,
    });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalInvestmentRealEstateLoans}
      sumTitle={tUI('investment-real-estate-loans-summary-sum-table-total-title')}
    >
      {investmentRealEstateLoans?.map((loan) => (
        <AmountSumTableItem
          key={loan?.id}
          title={loan.name}
          amount={getLoanBalance(loan)}
          subtitle={displayLoanType(loan.loanType, tLoan)}
        />
      ))}
    </AmountSumTable>
  );
}
