import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps, MonetaryInput } from '../../UI';
import { BusinessFormValues } from '../types';
import { FC } from 'react';

type BusinessEstimatedValueInputProps = Pick<InputProps, 'className'>;

export const BusinessEstimatedValueInput: FC<BusinessEstimatedValueInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<BusinessFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<BusinessFormValues, 'estimatedValue'>({
    control,
    name: 'estimatedValue',
    rules: { required: true },
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={errors.estimatedValue ? 'errored' : 'normal'}
      label={tForm('business-estimated-value-input-label')}
      inputProps={{ placeholder: tForm('general-placeholder-required') }}
    />
  );
};
