import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { taxReturnSummaryFormDefaultValues } from '../util';
import { TaxReturnSummaryFormValues, TaxReturnSummaryFormSubmitData } from '../types';
import { useFormMemoDefaultValues, useSetFormDefaultValues, useSubmitOnChange } from '../../UI/components/Form/hooks';
import { useTaxReturnSummary } from '../queries';

export interface UseTaxReturnSummaryFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  taxReturnSummaryID?: string;
  defaultValueOverrides?: Partial<TaxReturnSummaryFormValues>;
  handleSubmit?: (data: TaxReturnSummaryFormSubmitData) => void;
}

export const useTaxReturnSummaryForm = ({
  householdID,
  saveOnChange,
  handleSubmit,
  taxReturnSummaryID,
  defaultValueOverrides,
}: UseTaxReturnSummaryFormArgs) => {
  const formMethods = useForm<TaxReturnSummaryFormValues>();
  const { taxReturnSummary, taxReturnSummaryQuery } = useTaxReturnSummary({ householdID, taxReturnSummaryID });
  const defaultValues = useFormMemoDefaultValues(
    taxReturnSummaryFormDefaultValues(taxReturnSummary, defaultValueOverrides)
  );

  const isLoadingFormData = taxReturnSummaryQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const onSubmit: SubmitHandler<TaxReturnSummaryFormValues> = useCallback(
    (data) => {
      handleSubmit?.({
        householdID,
        formValues: data,
        taxReturnSummaryID: taxReturnSummary?.id,
        changeToken: taxReturnSummary?.changeToken,
      });
    },
    [taxReturnSummary?.id, taxReturnSummary?.changeToken, householdID, handleSubmit]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!taxReturnSummary,
  });

  return { formMethods, onSubmit, taxReturnSummary, householdID, formReady, isLoadingFormData };
};
