import { ElementsIconProps } from '../components/Icon/types';

export function AccountBoxIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="account_box_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5ZM15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6C13.66 6 15 7.34 15 9ZM6 17C6 15 10 13.9 12 13.9C14 13.9 18 15 18 17V18H6V17Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_28"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5ZM15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6C13.66 6 15 7.34 15 9ZM6 17C6 15 10 13.9 12 13.9C14 13.9 18 15 18 17V18H6V17Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_28)"></g>
      </g>
    </svg>
  );
}
