import { ElementsIconProps } from '../components/Icon/types';

export function LabelOutlineIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="label_outline_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2413 5.84C16.8813 5.33 16.2813 5 15.6113 5L4.61125 5.01C3.51125 5.01 2.61125 5.9 2.61125 7V17C2.61125 18.1 3.51125 18.99 4.61125 18.99L15.6113 19C16.2813 19 16.8813 18.67 17.2413 18.16L21.2013 12.58C21.4513 12.23 21.4513 11.77 21.2013 11.42L17.2413 5.84ZM15.6113 17H5.61125C5.06125 17 4.61125 16.55 4.61125 16V8C4.61125 7.45 5.06125 7 5.61125 7H15.6113L19.1613 12L15.6113 17Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
