import { TFunction } from 'i18next';
import {
  DataPoint,
  MonetaryAmount,
  RecurringFrequency,
} from '../generated/graphql';

export const recurringMonetaryAmountDataPointValue = (
  dataPoint: DataPoint | undefined | null
) => {
  if (dataPoint?.data.__typename === 'RecurringMonetaryAmountDataPointValue') {
    return dataPoint.data.value;
  }
};

export function monetaryAmountDataPointValue(
  dataPoint: DataPoint | undefined | null
) {
  if (dataPoint?.data?.__typename === 'MonetaryAmountDataPointValue') {
    return dataPoint.data.value;
  }
}

export function investmentMixDataPointValue(
  dataPoint: DataPoint | undefined | null
) {
  if (dataPoint?.data?.__typename === 'InvestmentMixDataPointValue') {
    return dataPoint.data.value;
  }
}

export function floatDataPointValue(dataPoint: DataPoint | undefined | null) {
  if (dataPoint?.data?.__typename === 'FloatDataPointValue') {
    return dataPoint.data.value;
  }
}

export function isMonetaryAmount(amount: unknown): amount is MonetaryAmount {
  return (<MonetaryAmount>amount)?.__typename === 'MonetaryAmount';
}

export function recurringFrequencyToPaymentFrequencySuffix(
  frequency: RecurringFrequency | undefined | null,
  tCommon: TFunction<'common'>
) {
  switch (frequency) {
    case RecurringFrequency.Annually:
      return tCommon('yearly-frequency-suffix');
    case RecurringFrequency.Monthly:
      return tCommon('monthly-frequency-suffix');
    case RecurringFrequency.Biweekly:
      return tCommon('biweekly-frequency-suffix');
    case RecurringFrequency.Quarterly:
      return tCommon('quarterly-frequency-suffix');
    case RecurringFrequency.Unknown:
    default:
      return '';
  }
}
