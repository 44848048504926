import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseRealEstateTermElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useRealEstateTermElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseRealEstateTermElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
