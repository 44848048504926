import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { dateWithZerosUTC } from '../../../../util';
import { ClickAnchor, ClickAnchorProps } from '../../ClickMenu';
import {
  InputContainer,
  InputContainerProps,
  elementsInputClasses,
} from '../Input';
import { DatePickerFloatingMenu } from './components';
import { DatePickerContextProvider } from './datePicker.context';
import { UseDatePickerArgs, useDatePicker } from './hooks';
import { ClientOnly } from 'remix-utils/client-only';

export interface DatePickerProps
  extends UseDatePickerArgs,
    Omit<InputContainerProps, 'isReadOnly' | 'children' | 'trailing'>,
    Pick<ClickAnchorProps, 'placement'> {
  name?: string;
  placeholder?: string;
  withIcon?: boolean;
  onBlur?: ComponentPropsWithoutRef<'input'>['onBlur'];
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      name,
      onChange,
      placeholder,
      status,
      onBlur,
      value,
      withIcon = true,
      placement,
      ...inputContainerProps
    },
    ref
  ) => {
    const datePickerData = useDatePicker({ value, onChange });

    return (
      <ClickAnchor
        offset={[0, 5]}
        inPortal={false}
        placement={placement || 'bottom-end'}
        disabled={status === 'disabled'}
        floatingElement={
          <DatePickerContextProvider {...datePickerData}>
            <ClientOnly>{() => <DatePickerFloatingMenu />}</ClientOnly>
          </DatePickerContextProvider>
        }
      >
        {({ setOpen }) => (
          <InputContainer
            status={status}
            {...inputContainerProps}
            trailing={
              withIcon && (
                <span className="material-icons-round text-primary ElementsInput-trailing">
                  calendar_today
                </span>
              )
            }
          >
            <div className="relative">
              {datePickerData.chosenDate && (
                <input
                  readOnly
                  ref={ref}
                  id={name}
                  name={name}
                  type="hidden"
                  disabled={status === 'disabled'}
                  value={dateWithZerosUTC(datePickerData.chosenDate)}
                />
              )}
              <input
                type="text"
                onBlur={onBlur}
                placeholder={placeholder}
                value={datePickerData.userInput}
                disabled={status === 'disabled'}
                className={elementsInputClasses.input}
                onChange={(e) => datePickerData.setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setOpen(false);
                  }
                }}
              />
            </div>
          </InputContainer>
        )}
      </ClickAnchor>
    );
  }
);
