import { useTranslation } from 'react-i18next';
import { useLtDetails } from '../queries';

export interface UseAfterTaxInvestmentsSumTableArgs {
  householdID: string | undefined | null;
}

export function useAfterTaxInvestmentsSumTable({
  householdID,
}: UseAfterTaxInvestmentsSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { ltDetails, ltDetailsQuery } = useLtDetails({
    householdID,
    includeAfterTaxInvestments: true,
    includeTotalAfterTaxInvestments: true,
  });

  const isLoading = ltDetailsQuery.isLoading;

  return { isLoading, ltDetails, tUI, tInvestment };
}
