import { ElementsIconProps } from '../components/Icon/types';

export function EquipmentIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="equipment_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 4C9.5 3.44772 9.94772 3 10.5 3H13.5C14.0523 3 14.5 3.44772 14.5 4V5H16C16.5523 5 17 5.44772 17 6V12H7V6C7 5.44772 7.44772 5 8 5H9.5V4ZM4 15C4 14.4477 4.44772 14 5 14H19C19.5523 14 20 14.4477 20 15V16C20 16.5523 19.5523 17 19 17H14V20H16.5C16.7761 20 17 20.2239 17 20.5C17 20.7761 16.7761 21 16.5 21H7.5C7.22386 21 7 20.7761 7 20.5C7 20.2239 7.22386 20 7.5 20H10V17H5C4.44772 17 4 16.5523 4 16V15ZM3 9C2.44772 9 2 9.44772 2 10V11C2 11.5523 2.44772 12 3 12H4C4.55228 12 5 11.5523 5 11V10C5 9.44772 4.55228 9 4 9H3ZM19 10C19 9.44772 19.4477 9 20 9H21C21.5523 9 22 9.44772 22 10V11C22 11.5523 21.5523 12 21 12H20C19.4477 12 19 11.5523 19 11V10Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
