import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicy } from '../../../../generated/graphql';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { displayInsurancePolicySubType, useFormatExpirationInformation } from '../../../../Insurance';
import { displayMonetaryAmount } from '../../../../util';
import { MonetaryListItem } from './MonetaryListItem';

interface InsuranceListItemProps {
  index: number;
  insurance: InsurancePolicy;
}

export const InsuranceListItem: FC<InsuranceListItemProps> = ({ index, insurance }) => {
  const { firmClient, routes, supportEdit } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');
  const expirationInfo = useFormatExpirationInformation(insurance);
  const canEdit = !!firmClient?.id && !!insurance?.id && !!routes?.editInsurance && supportEdit;

  const monetaryItem = (
    <MonetaryListItem
      index={index}
      title={insurance.name}
      amountSubtitle={expirationInfo}
      withChevron={canEdit}
      amount={displayMonetaryAmount(insurance.coverageAmount)}
      className={clsx(
        'min-h-[66px] py-3 pl-4 pr-2',
        '[&_.MonetaryListItem-main-content_.dollar-sign]:!hidden',
        '[&_.MonetaryListItem-title-content]:flex-col-reverse',
        '[&_.MonetaryListItem-amount-content]:flex-col-reverse',
        '[&_.MonetaryListItem-amount]:!text-xl [&_.MonetaryListItem-amount]:!font-medium',
        '[&_.MonetaryListItem-title]:!text-lg [&_.MonetaryListItem-title]:!leading-5',
        '[&_.MonetaryListItem-subtitle]:!text-sm [&_.MonetaryListItem-subtitle]:!text-darkMode-gray-light',
        '[&_.MonetaryListItem-amount-subtitle]:!text-sm [&_.MonetaryListItem-amount-subtitle]:!text-darkMode-gray-light'
      )}
      subtitle={displayInsurancePolicySubType(insurance.policySubtype, tInsurance)}
    />
  );

  return canEdit && routes?.editInsurance ? (
    <Link
      prefetch="intent"
      to={routes.editInsurance({
        clientID: firmClient.id,
        insuranceID: insurance.id,
      })}
    >
      {monetaryItem}
    </Link>
  ) : (
    monetaryItem
  );
};
