import { Owner, Ownership, OwnershipInterest, OwnerType } from '../generated/graphql';
import { displayPercentage } from '../util';

export function ownershipTenantsInput({
  ownerID,
  ownerType,
  percentage,
}: {
  ownerID: string;
  ownerType: OwnerType;
  percentage: number;
}) {
  return {
    tenantsInCommon: {
      interests: [
        {
          owner: { id: ownerID, type: ownerType },
          percentage: percentage,
        },
      ],
    },
  };
}

export function communityPropertyOwnership(ownership: Ownership) {
  return ownership.__typename === 'CommunityProperty' ? ownership : undefined;
}

export function soleOwnership(ownership: Ownership) {
  return ownership.__typename === 'SoleOwnership' ? ownership : undefined;
}

export function tenantsInCommonOwnership(ownership: Ownership) {
  return ownership.__typename === 'TenantsInCommon' ? ownership : undefined;
}

export function jointWithRightsOfSurvivorshipOwnership(ownership: Ownership) {
  return ownership.__typename === 'JointWithRightsOfSurvivorship' ? ownership : undefined;
}

export function isOwnedByType<E extends { ownership: Ownership }>(ownedItem: E, type: OwnerType) {
  const communityProperty = communityPropertyOwnership(ownedItem.ownership);
  const sole = soleOwnership(ownedItem.ownership);
  const tenantsInCommon = tenantsInCommonOwnership(ownedItem.ownership);
  const jointWithRightsOfSurvivorship = jointWithRightsOfSurvivorshipOwnership(ownedItem.ownership);

  if (communityProperty) {
    return communityProperty.owners.find((owner) => owner.ownerType === type);
  }

  if (sole) {
    return sole.owner.ownerType === type;
  }

  if (tenantsInCommon) {
    return tenantsInCommon.interests?.find((interest) => interest.owner.ownerType === type);
  }

  if (jointWithRightsOfSurvivorship) {
    return jointWithRightsOfSurvivorship.owners.find((owner) => owner.ownerType === type);
  }
}

export function isOwnedByBusiness<E extends { ownership: Ownership }>(ownedItem: E) {
  return isOwnedByType(ownedItem, OwnerType.Business);
}

export function isOwnedByPerson<E extends { ownership: Ownership }>(ownedItem: E) {
  return isOwnedByType(ownedItem, OwnerType.Person);
}

export function allOwners(ownership: Ownership): Owner[] {
  const communityProperty = communityPropertyOwnership(ownership);
  const sole = soleOwnership(ownership);
  const tenantsInCommon = tenantsInCommonOwnership(ownership);
  const jointWithRightsOfSurvivorship = jointWithRightsOfSurvivorshipOwnership(ownership);

  let owners: Owner[] = [];
  if (communityProperty || jointWithRightsOfSurvivorship) {
    owners = (communityProperty ?? jointWithRightsOfSurvivorship)?.owners || [];
  } else if (sole) {
    owners = [sole.owner];
  } else if (tenantsInCommon) {
    owners = tenantsInCommon.interests?.map((i) => i.owner) || [];
  }
  return owners;
}

export const getOwnershipInterestsFromOwnership = (ownership: Ownership) => {
  if (ownership.__typename === 'TenantsInCommon' && ownership?.interests && ownership.interests.length > 0) {
    return ownership.interests.reduce((maxInterest, currentInterest) =>
      currentInterest.percentage > maxInterest.percentage ? currentInterest : maxInterest
    );
  } else {
    return null;
  }
};

type BusinessPersonOwner = Owner & {
  familyName: string;
  givenName: string;
};

type BusinessBusinessOwner = Owner & {
  name: string;
};

export const getBusinessOwnerName = (ownershipInterest: OwnershipInterest) => {
  if (ownershipInterest.owner.ownerType === OwnerType.Business) {
    const owner = ownershipInterest.owner as BusinessBusinessOwner;
    return `${owner.name} (${displayPercentage(ownershipInterest.percentage, {
      withSymbol: true,
    })})`;
  } else if (ownershipInterest.owner.ownerType === OwnerType.Person) {
    const owner = ownershipInterest.owner as BusinessPersonOwner;
    return `${owner.givenName} ${owner.familyName} (${displayPercentage(ownershipInterest.percentage, {
      withSymbol: true,
    })})`;
  } else {
    return null;
  }
};
