import { useCreateDataPoint } from './mutations';
import { useMutation } from '@tanstack/react-query';
import { RecurringFrequency } from '../generated/graphql';
import { CreateRecurringMonetaryAmountValueDataPointServiceInput } from './types';

export function useCreateRecurringMonetaryAmountValueDataPointService() {
  const { createDataPoint } = useCreateDataPoint();

  const createRecurringMonetaryAmountValueDataPointService = useMutation({
    mutationFn: async (
      input: CreateRecurringMonetaryAmountValueDataPointServiceInput
    ) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          groupID: input.amountDataPointGroupID,
          dateTime: new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.amountInCents ?? 0,
                },
                frequency:
                  input.amountRecurringFrequency ?? RecurringFrequency.Annually,
              },
            },
          },
        },
        tenantID: input.tenantID,
      });
    },
  });

  return { createRecurringMonetaryAmountValueDataPointService };
}
