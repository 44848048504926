import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UsePersonalRealEstateLoansSummarySumTableArgs {
  householdID: string;
}

export function usePersonalRealEstateLoansSummarySumTable({
  householdID,
}: UsePersonalRealEstateLoansSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalPersonalRealEstateLoans = ttDetails?.debtSummary.totalPersonalRealEstateLoans;
  const personalRealEstateLoans = ttDetails?.debtSummary.personalRealEstateLoans.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return { personalRealEstateLoans, totalPersonalRealEstateLoans, tUI, isLoading, tLoan };
}
