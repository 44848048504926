import { ElementsIconProps } from '../components/Icon/types';

export function MoreCircleOutlinedIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22C17.4706 22 22 17.4608 22 12C22 6.52941 17.4608 2 11.9902 2C6.52941 2 2 6.52941 2 12C2 17.4608 6.53922 22 12 22ZM12 20.3333C7.37255 20.3333 3.67647 16.6275 3.67647 12C3.67647 7.37255 7.36275 3.66667 11.9902 3.66667C16.6176 3.66667 20.3235 7.37255 20.3333 12C20.3431 16.6275 16.6275 20.3333 12 20.3333ZM7.42157 13.4216C8.20588 13.4216 8.84314 12.7843 8.84314 11.9902C8.84314 11.2059 8.19608 10.5686 7.42157 10.5686C6.62745 10.5686 5.9902 11.2059 5.9902 11.9902C5.9902 12.7843 6.62745 13.4216 7.42157 13.4216ZM11.9902 13.4216C12.7745 13.4216 13.4216 12.7843 13.4216 11.9902C13.4216 11.2059 12.7745 10.5686 11.9902 10.5686C11.2059 10.5686 10.5588 11.2059 10.5588 11.9902C10.5588 12.7843 11.2059 13.4216 11.9902 13.4216ZM16.5686 13.4216C17.3529 13.4216 17.9902 12.7843 17.9902 11.9902C17.9902 11.2059 17.3529 10.5686 16.5686 10.5686C15.7745 10.5686 15.1373 11.2059 15.1373 11.9902C15.1373 12.7843 15.7745 13.4216 16.5686 13.4216Z"
        fill="currentColor"
      />
    </svg>
  );
}
