import { formatDistanceStrict } from 'date-fns';
import {
  UseDataPointsInGroupArgs,
  useDataPointsInGroup,
} from '../../../../DataPointGroup';
import { useFirmClient } from '../../../../FirmClient';
import { useTranslation } from 'react-i18next';

export interface UseSpendingUpdatesTableArgs
  extends Pick<UseDataPointsInGroupArgs, 'fromISODate' | 'toISODate'> {
  firmID: string | undefined | null;
  firmClientID: string | undefined | null;
  formatDistanceStrictOptions?: Parameters<typeof formatDistanceStrict>[2];
}

export function useSpendingUpdatesTable({
  firmID,
  toISODate,
  fromISODate,
  firmClientID,
  formatDistanceStrictOptions,
}: UseSpendingUpdatesTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { firmClient } = useFirmClient({ firmID, firmClientID });
  const { dataPointsInGroup, dataPointsInGroupQuery } = useDataPointsInGroup({
    toISODate,
    fromISODate,
    groupID: firmClient?.household?.spending.id,
    tenantID: firmClient?.household?.spending.tenantID,
  });

  const getFormattedDateDifference = () => {
    if (!fromISODate) return '';
    const visualTo = toISODate || new Date().toISOString();

    return formatDistanceStrict(
      new Date(fromISODate),
      new Date(visualTo),
      formatDistanceStrictOptions
    );
  };

  const getTitle = () => {
    const difference = getFormattedDateDifference();
    return difference
      ? tUI('spending-updates-table-title-dated', {
          formattedDateDifference: difference,
        })
      : tUI('spending-updates-table-title');
  };

  const getEmptyMessage = () => {
    const difference = getFormattedDateDifference();
    return difference
      ? tUI('spending-updates-table-empty-message-dated', {
          formattedDateDifference: difference,
        })
      : tUI('spending-updates-table-empty-message');
  };

  const isLoading = dataPointsInGroupQuery.isLoading;

  return {
    dataPointsInGroup,
    dataPointsInGroupQuery,
    isLoading,
    getTitle,
    getEmptyMessage,
  };
}
