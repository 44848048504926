import { ElementsIconProps } from '../components/Icon/types';

export function ArrowDropDownCircleIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrow_drop_down_circle_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.65 14.65L8.86 11.86C8.54 11.54 8.76 11 9.21 11H14.8C15.25 11 15.47 11.54 15.15 11.85L12.36 14.64C12.16 14.84 11.84 14.84 11.65 14.65Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_892"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.65 14.65L8.86 11.86C8.54 11.54 8.76 11 9.21 11H14.8C15.25 11 15.47 11.54 15.15 11.85L12.36 14.64C12.16 14.84 11.84 14.84 11.65 14.65Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_892)"></g>
      </g>
    </svg>
  );
}
