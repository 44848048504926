import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { applyTimeZoneOffset } from '../../../../../util';
import { useClickAnchorContext } from '../../../ClickMenu/context';
import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';

export const useDatePickerFloatingMenu = () => {
  const {
    now,
    chosenDate,
    lookedYear,
    lookedMonth,
    currentView,
    setUserInput,
    setChosenDate,
    setCurrentView,
    setLookedMonth,
    setLookedYear,
  } = useDatePickerContext();
  const { setOpen } = useClickAnchorContext();
  const [yearOffset, setYearOffset] = useState(0);
  const [noOfDays, setNoOfDays] = useState<number[]>([]);
  const [blankDays, setBlankDays] = useState<number[]>([]);

  const setDate = (day: number) => {
    const date = applyTimeZoneOffset(new Date(lookedYear, lookedMonth, day));
    setUserInput(format(date, 'MMM dd, yyyy'));
    setChosenDate(date);
    setOpen(false);
  };

  const getNoOfDays = () => {
    const daysInMonth = new Date(lookedYear, lookedMonth + 1, 0).getDate();

    // find where to start calendar day of week
    const dayOfWeek = new Date(lookedYear, lookedMonth).getDay();
    const blankDaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankDaysArray.push(i);
    }
    setBlankDays(blankDaysArray);

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    setNoOfDays(daysArray);
  };

  const traverseTime = (multiplier: -1 | 1) => {
    if (currentView === DatePickerView.DatePicker) {
      let month = lookedMonth + 1 * multiplier;
      if (month === -1) {
        month = 11;
        setLookedYear(lookedYear - 1);
      } else if (month === 12) {
        month = 0;
        setLookedYear(lookedYear + 1);
      }
      setLookedMonth(month);
    } else if (currentView === DatePickerView.YearPicker) {
      setYearOffset(yearOffset + 20 * multiplier);
    } else if (currentView === DatePickerView.MonthPicker) {
      setLookedYear(lookedYear + 1 * multiplier);
    }
  };

  useEffect(() => {
    const date = chosenDate ?? now;
    setLookedMonth(date.getMonth());
    setLookedYear(date.getFullYear());
  }, [chosenDate]);

  useEffect(() => setCurrentView(DatePickerView.DatePicker), []);

  useEffect(() => {
    getNoOfDays();
  }, [lookedMonth, lookedYear]);

  return {
    setDate,
    noOfDays,
    blankDays,
    traverseTime,
    yearOffset,
  };
};
