import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dataPointGroupKeys } from '../DataPointGroup/queryKeys';
import {
  CreateDataPointInput,
  DeleteDataPointInput,
} from '../generated/graphql';
import {
  clientCreateDataPoint,
  clientDeleteDataPoint,
  useGraphqlClient,
} from '../graphql';

export const useCreateDataPoint = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  const createDataPoint = useMutation({
    retry: 3,
    mutationKey: ['createDataPoint'],
    mutationFn: (input: CreateDataPointInput) => {
      return clientCreateDataPoint(gqlClient, input);
    },
    onSuccess({ dataPoint }) {
      queryClient.invalidateQueries(
        dataPointGroupKeys.dataPointsInGroup(dataPoint.groupID)
      );
    },
  });

  return { createDataPoint };
};

export const useDeleteDataPoint = (dataPointGroupID: string | undefined) => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  const deleteDataPoint = useMutation({
    mutationFn: (input: DeleteDataPointInput) => {
      return clientDeleteDataPoint(gqlClient, input);
    },
    onSuccess() {
      queryClient.invalidateQueries(
        dataPointGroupKeys.dataPointsInGroup(dataPointGroupID)
      );
    },
  });

  return { deleteDataPoint };
};
