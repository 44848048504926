import { ElementsIconProps } from '../components/Icon/types';

export function ScorecardIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="scorecard_24px">
        <g id="Group 3">
          <rect
            id="Rectangle"
            x="9"
            y="1"
            width="6"
            height="6"
            fill="#C036DD"
          />
          <rect
            id="Rectangle_2"
            x="9"
            y="9"
            width="6"
            height="6"
            fill="#FF144F"
          />
          <rect
            id="Rectangle_3"
            x="9"
            y="17"
            width="6"
            height="6"
            fill="#284ECD"
          />
          <rect
            id="Rectangle_4"
            x="17"
            y="9"
            width="6"
            height="6"
            fill="#FF8C0E"
          />
          <path
            id="Rectangle_5"
            d="M17 17H23V20C23 21.6569 21.6569 23 20 23H17V17Z"
            fill="#93CE00"
          />
          <path
            id="Rectangle_6"
            d="M1 4C1 2.34315 2.34315 1 4 1H7V7H1V4Z"
            fill="#31948F"
          />
          <rect
            id="Rectangle_7"
            x="1"
            y="9"
            width="6"
            height="6"
            fill="#FFBE00"
          />
          <path
            id="Rectangle_8"
            d="M1 17H7V23H4C2.34315 23 1 21.6569 1 20V17Z"
            fill="#8B31F1"
          />
          <path
            id="Rectangle_9"
            d="M17 1H20C21.6569 1 23 2.34315 23 4V7H17V1Z"
            fill="#016E42"
          />
        </g>
      </g>
    </svg>
  );
}
