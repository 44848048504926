import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formRecurringMonetaryAmountValueInCents } from '../../UI';
import { useSubmitOnChange } from '../../UI/components/Form/hooks';
import { IncomeSource } from '../../generated/graphql';
import {
  useCreateIncomeSourceService,
  useUpdateIncomeSourceService,
} from '../service';
import {
  CreateIncomeSourceServiceOptions,
  IncomeSourceFormValues,
  UpdateIncomeSourceServiceOptions,
} from '../types';
import { incomeSourceFormDefaultValues } from '../util';

export interface UseIncomeSourceFormArgs {
  saveOnChange?: boolean;
  incomeSource?: IncomeSource;
  defaultValues?: IncomeSourceFormValues;
  householdID?: string;
  createIncomeSourceServiceOptions?: CreateIncomeSourceServiceOptions;
  updateIncomeSourceServiceOptions?: UpdateIncomeSourceServiceOptions;
}

export const useIncomeSourceForm = ({
  saveOnChange,
  incomeSource,
  defaultValues,
  householdID,
  createIncomeSourceServiceOptions,
  updateIncomeSourceServiceOptions,
}: UseIncomeSourceFormArgs) => {
  const { updateIncomeSourceService } = useUpdateIncomeSourceService(
    incomeSource?.householdID
  );
  const { createIncomeSourceService } = useCreateIncomeSourceService();
  const methods = useForm<IncomeSourceFormValues>({
    defaultValues: incomeSourceFormDefaultValues(incomeSource, defaultValues),
  });

  const onSubmit: SubmitHandler<IncomeSourceFormValues> = useCallback(
    (data) => {
      if (incomeSource) {
        updateIncomeSourceService.mutate(
          {
            amountInCents: formRecurringMonetaryAmountValueInCents(data.income),
            amountRecurringFrequency: data.income?.frequency,
            updateIncomeSourceInput: {
              householdID: incomeSource.householdID,
              id: incomeSource.id,
              changeToken: incomeSource.changeToken,
              changes: {
                notes: data.notes,
                name: data.nickname,
                personID: data.ownerPersonID,
                sourceType: data.incomeSourceType,
              },
            },
          },
          updateIncomeSourceServiceOptions
        );
      } else if (data.ownerPersonID && householdID) {
        createIncomeSourceService.mutate(
          {
            householdID,
            amountRecurringFrequency: data.income?.frequency,
            amountInCents: formRecurringMonetaryAmountValueInCents(data.income),
            incomeSource: {
              notes: data.notes,
              name: data.nickname,
              personID: data.ownerPersonID,
              sourceType: data.incomeSourceType,
            },
          },
          createIncomeSourceServiceOptions
        );
      }
    },
    [
      incomeSource,
      householdID,
      updateIncomeSourceServiceOptions,
      createIncomeSourceServiceOptions,
    ]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: methods.watch,
    handleSubmit: methods.handleSubmit,
    enabled: saveOnChange && !!incomeSource,
  });

  return { formMethods: methods, onSubmit };
};
