import { ElementsIconProps } from '../components/Icon/types';

export function ToothIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="tooth_24px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2353 4.07107C14.4875 4.02379 14.7435 4 15 4C17.1561 4 18.9039 5.74783 18.9039 7.90388C18.9039 8.22307 18.8648 8.54105 18.7873 8.85071L16.3638 18.5448C16.15 19.4 15.3816 20 14.5 20C13.6303 20 12.8839 19.3805 12.7236 18.5257C12.5633 17.6708 12.7236 13.6667 12 13.6667C11.2765 13.6667 11.4367 17.6708 11.2765 18.5257C11.1162 19.3805 10.3698 20 9.50002 20C8.61846 20 7.85003 19.4 7.63622 18.5448L5.2127 8.85071C4.68978 6.75903 5.96151 4.63948 8.05319 4.11656C8.36285 4.03915 8.68083 4 9.00002 4C10.1796 4 11.3626 5.28244 11.9998 5.28244C12.6371 5.28244 13.3475 4.23754 14.2353 4.07107Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
