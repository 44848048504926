import {
  FloatRangeInput,
  InputMaybe,
  TargetScoreRange
} from '../../generated/graphql';


export function getOptimisticTargetScoreRangeFromMutationInput(
  input?: InputMaybe<FloatRangeInput> | undefined
): TargetScoreRange {
  return { __typename: 'TargetScoreRange', customized: false, range: { min: input?.min, max: input?.max } };
}
