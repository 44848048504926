import common from './en/common.json';
import file from './en/file.json';
import fileAttachment from './en/fileAttachment.json';
import financialGoal from './en/financialGoal.json';
import financialNextStep from './en/financialNextStep.json';
import firm from './en/firm.json';
import firmClient from './en/firmClient.json';
import firmMember from './en/firmMember.json';
import form from './en/form.json';
import incomeSource from './en/incomeSource.json';
import insurance from './en/insurance.json';
import loan from './en/loan.json';
import person from './en/person.json';
import scorecard from './en/scorecard.json';
import cashAccount from './en/cashAccount.json';
import investment from './en/investment.json';
import realEstate from './en/realEstate.json';
import pages from './en/pages.json';
import taxes from './en/taxes.json';
import firmClientGroups from './en/firmClientGroups.json';
import UI from './en/ui.json';
import targetScore from './en/targetScore.json';
import business from './en/business.json';

export const elementsPackageI18nConfigOptions = {
  supportedLngs: ['en'],
  defaultNS: 'common',
  fallbackLng: 'en',
  react: { useSuspense: false },
  resources: {
    en: {
      common,
      file,
      fileAttachment,
      financialGoal,
      financialNextStep,
      firm,
      firmClient,
      firmMember,
      form,
      incomeSource,
      insurance,
      loan,
      person,
      scorecard,
      cashAccount,
      investment,
      realEstate,
      pages,
      taxes,
      firmClientGroups,
      UI,
      targetScore,
      business,
    },
  },
};

export const elementsPackageI18nNamespaces = Object.keys(elementsPackageI18nConfigOptions.resources.en);
