import { ElementsIconProps } from '../components/Icon/types';

export function LightbulbIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_209_745)">
        <path
          d="M9 21C9 21.5 9.4 22 10 22H14C14.6 22 15 21.5 15 21V20H9V21ZM12 2C8.1 2 5 5.1 5 9C5 11.4 6.2 13.5 8 14.7V17C8 17.5 8.4 18 9 18H15C15.6 18 16 17.5 16 17V14.7C17.8 13.4 19 11.3 19 9C19 5.1 15.9 2 12 2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_745">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
