import {
  Divider,
  ElementDetailViewHeader,
  ElementEducationVideoTile,
  ElementDetailViewIndexPageContainer,
  ClientTotalTermScoreHealthRange,
} from '../../components';
import { useTotalTermElementDetailViewIndexPage } from './hooks';
import RetirementPicture from '../../../assets/RetirementPicture';
import { TotalTermElementDetailViewIndexPageProps } from './types';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import { AssetSummarySumTable, DebtSummarySumTable, TotalTermDetailsProportionChart } from '../../../TtDetails';

export function TotalTermElementDetailViewIndexPage({
  firmID,
  firmClientID,
  downloadReportButton,
  showScoreHealthRange,
  debtSummaryTableClickHandlers,
  assetSummaryTableClickHandlers,
}: TotalTermElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useTotalTermElementDetailViewIndexPage({
    firmID,
    firmClientID,
  });

  if (!householdID || !firmID || !firmClientID) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        tooltipDescription={tPages('total-term-element-detail-view-header-tooltip-description')}
        title={tScorecard('total-term')}
        tooltipContainerClassName="!w-[296px]"
      />
      <ScorecardElementCalculationGroup element="Tt" className="mb-12" householdID={householdID} />
      {showScoreHealthRange && <ClientTotalTermScoreHealthRange firmID={firmID} firmClientID={firmClientID} />}
      <ElementEducationVideoTile
        label={tPages('total-term-element-detail-view-learn-more-label')}
        body={tPages('total-term-element-detail-view-learn-more-body')}
        thumbNail={<RetirementPicture className="h-[119px]" />}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <AssetSummarySumTable clickHandlers={assetSummaryTableClickHandlers} householdID={householdID} />
      <DebtSummarySumTable clickHandlers={debtSummaryTableClickHandlers} className="mt-8" householdID={householdID} />
      <TotalTermDetailsProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
