import { CSSProperties, FC } from 'react';
import { IconName } from './types';
import { iconNameMap } from './util';

interface IconProps {
  icon: IconName;
  className?: string;
  style?: CSSProperties;
}

export const Icon: FC<IconProps> = ({ className, icon, style }) => {
  const iconInstance = iconNameMap[icon];
  return typeof iconInstance === 'string' ? (
    <img style={style} className={className} src={iconInstance} />
  ) : (
    iconInstance({ className, style })
  );
};
