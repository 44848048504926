import { ElementsIconProps } from '../components/Icon/types';

export function ChevronRightIcon(props: ElementsIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M9 6.71a.996.996 0 0 0 0 1.41L12.88 12 9 15.88a.996.996 0 1 0 1.41 1.41L15 12.7a.996.996 0 0 0 0-1.41L10.41 6.7c-.38-.38-1.02-.38-1.41.01z"
          id="a"
        />
      </defs>
      <use
        fill="currentColor"
        xlinkHref="#a"
        // transform="translate(.5)"
        fillRule="evenodd"
      />
    </svg>
  );
}
