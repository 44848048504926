import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseAfterTaxInvestmentsSummarySumTableArgs {
  householdID: string;
}

export function useAfterTaxInvestmentsSummarySumTable({ householdID }: UseAfterTaxInvestmentsSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalAfterTaxInvestments = ttDetails?.assetSummary.totalAfterTaxInvestments;
  const afterTaxInvestments = ttDetails?.assetSummary.afterTaxInvestments.sort((a, b) => a.name.localeCompare(b.name));

  return { afterTaxInvestments, totalAfterTaxInvestments, tUI, isLoading, tInvestment };
}
