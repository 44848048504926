import { ElementsIconProps } from '../components/Icon/types';

export function VolumeMuteIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="volume_mute_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 10.005V14.005C7.5 14.555 7.95 15.005 8.5 15.005H11.5L14.79 18.295C15.42 18.925 16.5 18.475 16.5 17.585V6.41501C16.5 5.52501 15.42 5.07501 14.79 5.70501L11.5 9.00501H8.5C7.95 9.00501 7.5 9.45501 7.5 10.005Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_524"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="7"
          y="5"
          width="10"
          height="14"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 10.005V14.005C7.5 14.555 7.95 15.005 8.5 15.005H11.5L14.79 18.295C15.42 18.925 16.5 18.475 16.5 17.585V6.41501C16.5 5.52501 15.42 5.07501 14.79 5.70501L11.5 9.00501H8.5C7.95 9.00501 7.5 9.45501 7.5 10.005Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_524)"></g>
      </g>
    </svg>
  );
}
