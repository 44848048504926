import { DefaultValues } from 'react-hook-form';
import {
  formOwnershipAllocationToFloat,
  formRecurringMonetaryAmountValueInCents,
  monetaryAmountDataPointFormDefaultValue,
  recurringMonetaryAmountDataPointFormDefaultValue,
} from '../UI';
import {
  CashAccount,
  CashAccountType,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import {
  CashAccountFormSubmitData,
  CashAccountFormValues,
  CreateCashAccountServiceInput,
  UpdateCashAccountServiceInput,
} from './types';
import { monetaryAmountDataPointValue, recurringMonetaryAmountDataPointValue } from '../DataPoint';
import {
  DisplayAgeOptions,
  addMonetaryAmounts,
  addRecurringMonetaryAmounts,
  displayAge,
  recurringMonetaryAmountConverter,
} from '../util';
import { differenceInMonths } from 'date-fns';
import { latestDataPointGroupUpdateDate } from '../DataPointGroup';
import { CASH_ACCOUNT_VALUE_DECAY_LIMIT_IN_MONTHS } from './constants';
import { TFunction } from 'i18next';
import { isOwnedByBusiness, isOwnedByPerson, ownershipTenantsInput } from '../Owner';

export function cashAccountFormDefaultValues(
  cashAccount: CashAccount | null | undefined,
  defaultValues?: Partial<CashAccountFormValues>
): DefaultValues<CashAccountFormValues> {
  if (!cashAccount) return { ...defaultValues };

  return {
    nickname: cashAccount.name,
    recurringContribution: recurringMonetaryAmountDataPointFormDefaultValue(
      cashAccount.recurringContribution.latestDataPoint
    ),
    estimatedValue: monetaryAmountDataPointFormDefaultValue(cashAccount.value.latestDataPoint),
    ...defaultValues,
  };
}

export function groupCashAccountsByType(cashAccounts: CashAccount[]) {
  const cashAccountsMap: Map<CashAccountType, CashAccount[]> = new Map();

  cashAccounts.forEach((cashAccount) => {
    if (!cashAccountsMap.has(cashAccount.cashAccountType)) {
      cashAccountsMap.set(cashAccount.cashAccountType, []);
    }
    const cashAccountsOfType = cashAccountsMap.get(cashAccount.cashAccountType);
    if (cashAccountsOfType) {
      cashAccountsOfType.push(cashAccount);
    }
  });

  return cashAccountsMap;
}

export function cashAccountsOfType(cashAccounts: CashAccount[], cashAccountType: CashAccountType) {
  return cashAccounts.filter((cashAccount) => cashAccount.cashAccountType === cashAccountType);
}

export function getCashAccountRecurringContributions(
  cashAccount: CashAccount,
  options: { frequency?: RecurringFrequency } = {}
) {
  const value = recurringMonetaryAmountDataPointValue(cashAccount.recurringContribution.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getCashAccountsWithRecurringContributions(cashAccounts: CashAccount[]) {
  return cashAccounts.filter((cashAccount) => !!getCashAccountRecurringContributions(cashAccount)?.amount.value);
}

export function getTotalRecurringContributionsForCashAccounts(
  cashAccounts: CashAccount[],
  options: { frequency?: RecurringFrequency } = {}
): RecurringMonetaryAmount | null {
  const contributions: RecurringMonetaryAmount[] = [];

  for (const cashAccount of cashAccounts) {
    const cashAccountPayments = getCashAccountRecurringContributions(cashAccount);
    if (cashAccountPayments) contributions.push(cashAccountPayments);
  }

  return addRecurringMonetaryAmounts(contributions, options.frequency);
}

export function getCashAccountValue(cashAccount: CashAccount): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(cashAccount.value.latestDataPoint);
}

export function totalValueForCashAccounts(cashAccounts: CashAccount[]) {
  return addMonetaryAmounts(cashAccounts.map(getCashAccountValue));
}

export function isCashAccountValueDecayed(cashAccount: CashAccount) {
  return (
    differenceInMonths(new Date(), latestDataPointGroupUpdateDate(cashAccount.value) || new Date()) >=
    CASH_ACCOUNT_VALUE_DECAY_LIMIT_IN_MONTHS
  );
}

export function totalDecayedValueForCashAccounts(cashAccounts: CashAccount[]) {
  return addMonetaryAmounts(
    cashAccounts.filter(isCashAccountValueDecayed).map(getCashAccountValue).filter(Boolean) as MonetaryAmount[]
  );
}

export function displayCashAccountValueAge(cashAccount: CashAccount, options?: DisplayAgeOptions) {
  return displayAge(latestDataPointGroupUpdateDate(cashAccount.value)?.toISOString(), options);
}

export function displayCashAccountType(type: CashAccountType, tCashAccount: TFunction<'cashAccount'>) {
  switch (type) {
    case CashAccountType.Cash:
      return tCashAccount('cash');
    case CashAccountType.CashManagementAccount:
      return tCashAccount('cash-management-account');
    case CashAccountType.CertificateOfDeposit:
      return tCashAccount('certificate-of-deposit');
    case CashAccountType.CheckingAccount:
      return tCashAccount('checking-account');
    case CashAccountType.MoneyMarketAccount:
      return tCashAccount('money-market-account');
    case CashAccountType.SavingsAccount:
      return tCashAccount('savings-account');
    case CashAccountType.TreasuryBill:
      return tCashAccount('treasury-bill');
  }
}

export const cashAccountsHaveOfType = (cashAccounts: CashAccount[], type: CashAccountType) =>
  cashAccounts.some((cashAccount) => cashAccount.cashAccountType === type);

export const getBusinessCashAccounts = (cashAccounts: CashAccount[]) => cashAccounts.filter(isOwnedByBusiness);

export const getPersonalCashAccounts = (cashAccounts: CashAccount[]) => cashAccounts.filter(isOwnedByPerson);

export const getLastCashAccountUpdateDate = (cashAccount: CashAccount): string =>
  cashAccount.value?.latestDataPoint?.dateTime ?? cashAccount.value?.updatedAt;

export function createCashAccountServiceInputFromForm({
  formValues,
  householdID,
}: CashAccountFormSubmitData): CreateCashAccountServiceInput {
  return {
    valueInCents: formValues.estimatedValue * 100,
    recurringContributionFrequency: formValues.recurringContribution.frequency,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    createCashAccountInput: {
      householdID,
      cashAccount: {
        notes: formValues.notes,
        name: formValues.nickname,
        cashAccountType: formValues.cashAccountType,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
      },
    },
  };
}

export function updateCashAccountServiceInputFromForm({
  formValues,
  householdID,
  changeToken,
  cashAccountID,
}: CashAccountFormSubmitData): UpdateCashAccountServiceInput {
  if (!cashAccountID || !changeToken) throw new Error('Missing cashAccountID or changeToken');

  return {
    updateCashAccountInput: {
      householdID,
      changeToken,
      id: cashAccountID,
      changes: { name: formValues.nickname },
    },
    estimatedValueInCents: formValues.estimatedValue * 100,
    recurringContributionFrequency: formValues.recurringContribution.frequency,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
  };
}
