import clsx from 'clsx';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { ClickAnchor, ClickAnchorProps } from '../../ClickMenu';
import { Icon } from '../../Icon';
import {
  InputContainer,
  InputContainerProps,
  elementsInputClasses,
} from '../Input';
import { DropdownMenu, DropdownOption } from './components';
import { DropdownContextProvider } from './dropdown.context';
import { IDropdownOption } from './types';

export interface DropdownProps
  extends Omit<InputContainerProps, 'isReadOnly' | 'children' | 'trailing'>,
    Pick<
      ClickAnchorProps,
      | 'placement'
      | 'popperJSHideModifierOptions'
      | 'popperJSPreventOverflowOptions'
      | 'popperJSFlipModifierOptions'
    > {
  name?: string;
  value?: string | null;
  placeholder?: string;
  children?: React.ReactNode;
  withClearSelection?: boolean;
  retriggerSelectionFromValue?: boolean;
  onChange?: (
    value: IDropdownOption['value'],
    option: IDropdownOption | null
  ) => void;
  dropdownMenuClassName?: HTMLDivElement['className'];
  onBlur?: ComponentPropsWithoutRef<'input'>['onBlur'];
  loading?: boolean;
}

export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
  (
    {
      name,
      onBlur,
      value,
      status,
      onChange,
      children,
      placement,
      placeholder,
      withClearSelection,
      dropdownMenuClassName,
      retriggerSelectionFromValue,
      popperJSHideModifierOptions,
      popperJSPreventOverflowOptions,
      popperJSFlipModifierOptions,
      loading,
      ...inputContainerProps
    },
    ref
  ) => {
    const [selected, setSelected] = useState<IDropdownOption | null>(null);

    useEffect(() => {
      if (!Array.isArray(children)) return;

      const option = children?.find(
        (child: any) => (child as JSX.Element).props.value === value
      ) as JSX.Element;

      // support for deselecting
      setSelected(option?.props || null);
    }, [value, retriggerSelectionFromValue]);

    useEffect(() => {
      onChange?.(selected ? selected.value : null, selected);
    }, [selected]);

    return (
      <DropdownContextProvider selected={selected} setSelected={setSelected}>
        <ClickAnchor
          disabled={status === 'disabled'}
          offset={[0, 5]}
          inPortal={false}
          placement={placement}
          popperJSFlipModifierOptions={popperJSFlipModifierOptions}
          popperJSHideModifierOptions={popperJSHideModifierOptions}
          popperJSPreventOverflowOptions={popperJSPreventOverflowOptions}
          floatingElement={
            children ? (
              <DropdownMenu className={dropdownMenuClassName}>
                {withClearSelection && (
                  <DropdownOption
                    value={null}
                    label="Clear Selection"
                    id="Dropdown_clear_selection"
                  />
                )}
                {children}
              </DropdownMenu>
            ) : (
              <></>
            )
          }
        >
          <InputContainer
            isReadOnly
            status={status}
            trailing={
              <Icon
                icon={loading ? 'spinner' : 'arrowDropdown'}
                className="!h-[24px] !w-[24px] text-primary"
              />
            }
            {...inputContainerProps}
          >
            {selected && value && (
              <input
                readOnly
                id={name}
                ref={ref}
                name={name}
                className="hidden"
                value={value ?? undefined}
                disabled={status === 'disabled'}
              />
            )}
            <input
              readOnly
              onBlur={onBlur}
              placeholder={placeholder}
              value={selected?.label || ''}
              disabled={status === 'disabled'}
              className={clsx(elementsInputClasses.input, 'cursor-pointer')}
            />
          </InputContainer>
        </ClickAnchor>
      </DropdownContextProvider>
    );
  }
);
