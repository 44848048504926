import {
  displayCashAccountType,
  getCashAccountValue,
  getLastCashAccountUpdateDate,
} from '../../CashAccount';
import {
  AmountSumTable,
  AmountSumTableItem,
  AmountSumTableProps,
} from '../../UI';
import { UsePersonalCashSumTableArgs, usePersonalCashSumTable } from '../hooks';

interface PersonalCashSumTableProps
  extends UsePersonalCashSumTableArgs,
    Pick<AmountSumTableProps, 'hideOnEmpty'> {
  className?: string;
}

export function PersonalCashSumTable({
  className,
  hideOnEmpty,
  ...usePersonalCashSumTableArgs
}: PersonalCashSumTableProps) {
  const { isLoading, ltDetails, tUI, tCashAccount } = usePersonalCashSumTable(
    usePersonalCashSumTableArgs
  );

  return (
    <AmountSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={ltDetails?.totalPersonalCash}
      title={tUI('personal-cash-table-title')}
      sumTitle={tUI('personal-cash-table-sum-title')}
    >
      {ltDetails?.personalCashItems.map((cashAccount) => (
        <AmountSumTableItem
          key={cashAccount.id}
          title={cashAccount.name}
          amount={getCashAccountValue(cashAccount)}
          lastUpdated={getLastCashAccountUpdateDate(cashAccount)}
          subtitle={displayCashAccountType(
            cashAccount.cashAccountType,
            tCashAccount
          )}
        />
      ))}
    </AmountSumTable>
  );
}
