import { gql } from 'urql';
import { InvestmentFragment, MonetaryAmountFragment } from './fragments';
import { Query } from '../generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { QueryQtDetailsArgsWithDirectives } from './types';

const qtDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${InvestmentFragment}
  query QtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeTotalRetirementAssets: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeTaxFreeAccounts: Boolean!
    $includeTotalTaxFree: Boolean!
    $includeTaxDeferredAccounts: Boolean!
    $includeTotalTaxDeferred: Boolean!
    $includeOtherAccounts: Boolean!
    $includeTotalOther: Boolean!
  ) {
    qtDetails(householdID: $householdID) {
      qt @include(if: $includeScore)
      totalRetirementAssets @include(if: $includeTotalRetirementAssets) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      taxFreeAccounts @include(if: $includeTaxFreeAccounts) {
        ...InvestmentFragment
      }
      totalTaxFree @include(if: $includeTotalTaxFree) {
        ...MonetaryAmountFragment
      }
      taxDeferredAccounts @include(if: $includeTaxDeferredAccounts) {
        ...InvestmentFragment
      }
      totalTaxDeferred @include(if: $includeTotalTaxDeferred) {
        ...MonetaryAmountFragment
      }
      otherAccounts @include(if: $includeOtherAccounts) {
        ...InvestmentFragment
      }
      totalOther @include(if: $includeTotalOther) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getQtDetails = async (
  client: GraphQLClient,
  args: QueryQtDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'qtDetails'>,
      QueryQtDetailsArgsWithDirectives
    >(qtDetailsQuery, args)
  ).qtDetails;
};
