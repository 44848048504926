import {
  AnimatedFabDivider,
  ClientInsuranceRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
  FilterableInsuranceRateScoreHealthRange,
  ScoreHealthDot,
} from '../../components';
import { useTranslation } from 'react-i18next';
import { InsurancePolicyType } from '../../../generated/graphql';
import {
  CalculationDetailsContainer,
  CalculationsFactorsBox,
  LiabilityInsuranceCalculationBreakdown,
  LifeInsuranceCalculationBreakdown,
} from './components';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import { useInsuranceElementDetailViewContext } from './hooks';
import { DisabilityInsuranceCalculationBreakdown } from './components/DisabilityInsuranceCalculationBreakdown';
import { AddIcon } from '../../icons/add';
import { InsurancesList } from './components/InsurancesList';

interface InsuranceRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'> {}

export function InsuranceRateElementDetailViewIndexPage({ ...props }: InsuranceRateElementDetailViewIndexPageProps) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { t: tPages } = useTranslation('pages');
  const { t: tInsurance } = useTranslation('insurance');
  const {
    insurancePolicies,
    householdID,
    scorecard,
    firmClient,
    allowTargetScoreRanges,
    showElementDetailViewVideoThumbnails,
    supportCreate,
  } = useInsuranceElementDetailViewContext();
  const life = insurancePolicies.filter((ins) => ins.policyType === InsurancePolicyType.Life);
  const liability = insurancePolicies.filter((ins) => ins.policyType === InsurancePolicyType.Liability);
  const disability = insurancePolicies.filter((ins) => ins.policyType === InsurancePolicyType.Disability);

  return (
    <ElementDetailViewIndexPageContainer {...props}>
      <ElementDetailViewHeader
        title={tScorecard('insurance-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('insurance-rate-element-detail-view-header-tooltip-description')}
      />
      <div className={'mb-14'}>
        <CalculationsFactorsBox householdID={householdID} />
      </div>
      {allowTargetScoreRanges ? (
        <>
          {firmClient?.id && firmClient.firmID && (
            <ClientInsuranceRateScoreHealthRange
              firmClientID={firmClient.id}
              firmID={firmClient.firmID}
              className="mt-14"
            />
          )}
        </>
      ) : (
        <FilterableInsuranceRateScoreHealthRange
          className="mt-14"
          graphDots={!!scorecard?.newIr && <ScoreHealthDot rangeValue={scorecard?.newIr} />}
        />
      )}

      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />

      {showElementDetailViewVideoThumbnails && (
        <ElementEducationVideoTile
          label={tInsurance('learn-more-label')}
          body={tInsurance('learn-more-body')}
          thumbNail={<PenHandPaperPicture />}
        />
      )}
      {scorecard?.lifeCoverages && (
        <CalculationDetailsContainer
          title={tInsurance('insurance-rate-element-detail-view-calculation-breakdown-life-insurance')}
        >
          {scorecard?.lifeCoverages.map((lifeCoverage) => (
            <LifeInsuranceCalculationBreakdown
              lifeCoverage={lifeCoverage}
              key={`life-coverage-${lifeCoverage.person?.id}`}
            />
          ))}
        </CalculationDetailsContainer>
      )}
      {scorecard?.disabilityCoverages && (
        <CalculationDetailsContainer
          title={tInsurance('insurance-rate-element-detail-view-calculation-breakdown-disability-insurance')}
        >
          {scorecard?.disabilityCoverages.map((disabilityCoverage) => (
            <DisabilityInsuranceCalculationBreakdown
              disabilityCoverage={disabilityCoverage}
              key={`disability-coverage-${disabilityCoverage.person?.id}`}
            />
          ))}
        </CalculationDetailsContainer>
      )}
      {scorecard?.liabilityCoverages && (
        <CalculationDetailsContainer
          title={tInsurance('insurance-rate-element-detail-view-calculation-breakdown-liability-insurance')}
        >
          <LiabilityInsuranceCalculationBreakdown liabilityCoverage={scorecard?.liabilityCoverages} />
        </CalculationDetailsContainer>
      )}
      {supportCreate ? (
        <AnimatedFabDivider
          to="add"
          hoverWidth={260}
          className="mt-14"
          icon={<AddIcon className="text-darkMode-gray-dark" />}
          label={tInsurance('InsuranceIndexPage-add-insurance-button-label')}
        />
      ) : (
        <Divider className="mt-14 -ml-12 w-[calc(100%_+_96px)]" />
      )}

      <div className="mt-[69px]">
        {!!life.length && (
          <InsurancesList
            title={tInsurance('insurance-rate-element-detail-view-calculation-breakdown-life-insurance')}
            insurances={life}
          />
        )}
        {!!disability.length && (
          <InsurancesList
            title={tInsurance('insurance-rate-element-detail-view-calculation-breakdown-disability-insurance')}
            insurances={disability}
          />
        )}
        {!!liability.length && <InsurancesList title={tInsurance('liability')} insurances={liability} />}
      </div>
    </ElementDetailViewIndexPageContainer>
  );
}
