import { firmClientKeys } from '../FirmClient';

export const loanKeys = {
  base: ['loan'],
  loans: (householdID: string | undefined | null) => [
    'loans',
    ...loanKeys.base,
    ...firmClientKeys.household(householdID),
  ],
};
