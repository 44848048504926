import { useTranslation } from 'react-i18next';
import { useLtDetails } from '../queries';

export interface UseBusinessCashSumTableArgs {
  householdID: string | undefined | null;
}

export function useBusinessCashSumTable({
  householdID,
}: UseBusinessCashSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { ltDetails, ltDetailsQuery } = useLtDetails({
    householdID,
    includeBusinessCashItems: true,
    includeTotalBusinessCash: true,
  });

  const isLoading = ltDetailsQuery.isLoading;

  return { isLoading, ltDetails, tUI, tCashAccount };
}
