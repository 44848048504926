import { useBusiness } from '..';
import { useCallback } from 'react';
import { businessFormDefaultValues } from '../util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BusinessFormSubmitData, BusinessFormValues } from '../types';
import { useFormMemoDefaultValues, useSetFormDefaultValues, useSubmitOnChange } from '../../UI';

export interface UseBusinessFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  businessID?: string | null;
  defaultValuesOverrides?: Partial<BusinessFormValues>;
  handleSubmit?: (data: BusinessFormSubmitData) => void;
}

export const useBusinessForm = ({
  businessID,
  householdID,
  saveOnChange,
  defaultValuesOverrides,
  handleSubmit,
}: UseBusinessFormArgs) => {
  const formMethods = useForm<BusinessFormValues>();
  const { businessQuery, business } = useBusiness({ businessID, householdID });
  const defaultValues = useFormMemoDefaultValues(businessFormDefaultValues(business, defaultValuesOverrides));

  const isLoadingFormData = businessQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const onSubmit: SubmitHandler<BusinessFormValues> = useCallback(
    (formValues) => {
      handleSubmit?.({ formValues, householdID, changeToken: business?.changeToken, businessID });
    },
    [handleSubmit, householdID, business?.changeToken, businessID]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!business,
  });

  return { formMethods, onSubmit, formReady, isLoadingFormData, business, householdID };
};
