import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseBusinessSummarySumTableArgs {
  householdID: string;
}

export function useBusinessSummarySumTable({ householdID }: UseBusinessSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalBusinessValue = ttDetails?.assetSummary.totalBusinessValue;
  const businesses = ttDetails?.assetSummary.businesses.sort((a, b) => a.name.localeCompare(b.name));

  return { businesses, totalBusinessValue, tUI, isLoading };
}
