import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, FC, SetStateAction } from 'react';
import {
  presentationButtonOverlayVariants,
  presentationButtonVariants,
  presentationScoreCalculationTooltipVariants,
} from '../animation';
import { ScorecardElementSymbol } from '../../../../Scorecard';
import {
  ScoreCalculationTooltipBody,
  scoreCalculationTooltipCalculationScorecardValuesFromScorecard,
  StandaloneScorecardElement,
  StandaloneScorecardElementProps,
} from '../../../components';
import { Scorecard } from '../../../../generated/graphql';
import { usePresentationViewOptionsContext } from '../context';
import { Link } from '@remix-run/react';

interface PresentationScorecardButtonProps extends StandaloneScorecardElementProps {
  hoveredElement: ScorecardElementSymbol | undefined;
  setHoveredElement: Dispatch<SetStateAction<ScorecardElementSymbol | undefined>>;
  to?: string;
  scorecard?: Scorecard;
  onClick?: () => void;
}

export const PresentationScorecardButton: FC<PresentationScorecardButtonProps> = ({
  to,
  value,
  element,
  className,
  scorecard,
  hoveredElement,
  setHoveredElement,
  onClick = () => {},
}) => {
  const { viewOptions } = usePresentationViewOptionsContext();

  const invisible = !viewOptions?.focusedElements?.includes(element);
  const hovered = element === hoveredElement;
  const animation = hovered ? 'hovered' : hoveredElement === undefined ? 'idle' : 'receded';

  return (
    <>
      <motion.div
        initial="idle"
        animate={animation}
        variants={presentationButtonVariants}
        onHoverStart={() => setHoveredElement(element)}
        onHoverEnd={() => setHoveredElement(undefined)}
        className={clsx(className, 'relative', to ? 'cursor-pointer' : 'cursor-default')}
        style={{ boxShadow: '0 6px 12px 4px rgba(0, 0, 0, 0.2)' }}
        onClick={onClick}
      >
        {((content: JSX.Element) =>
          to ? (
            <Link className="contents" to={to}>
              {content}
            </Link>
          ) : (
            content
          ))(
          <>
            <motion.div
              initial="idle"
              animate={animation}
              variants={presentationButtonOverlayVariants}
              className="absolute inset-0 left-[-1px] bottom-[1px] z-10 h-[101%] w-[101%] rounded-[20px] bg-darkMode-almostBlack/50"
            />
            <AnimatePresence>
              {hovered && (
                <motion.div
                  exit="idle"
                  initial="idle"
                  animate={animation}
                  variants={presentationScoreCalculationTooltipVariants}
                  className="absolute top-[calc(100%_+_8px)] left-0 origin-top-left"
                >
                  <ScoreCalculationTooltipBody
                    score={value}
                    element={element}
                    className="dark:!bg-darkMode-gray-dark"
                    calculationScorecardValues={scoreCalculationTooltipCalculationScorecardValuesFromScorecard(
                      scorecard
                    )}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <StandaloneScorecardElement
              value={value}
              element={element}
              className={clsx(
                invisible && 'grayscale opacity-40 !bg-darkMode-gray-medium',
                '!h-[216px] !w-[216px] !rounded-[20px] !px-[21.35px] !shadow-none',
                '[&_.StandaloneScorecardElement-element]:!text-[50.233px] [&_.StandaloneScorecardElement-element]:!leading-[53.046px]',
                '[&_.StandaloneScorecardElement-element-name]:!text-[22.539px] [&_.StandaloneScorecardElement-element-name]:!leading-[22.102px] [&_.StandaloneScorecardElement-element-name]:!tracking-[-0.232px]',
                '[&_.StandaloneScorecardElement-score-container]:!h-[124px]',
                '[&_.StandaloneScorecardElement-score]:!text-[70.326px] [&_.StandaloneScorecardElement-score]:!tracking-[-1.256px]',
                '[&_.StandaloneScorecardElement-score-percentage]:!text-[70.326px] [&_.StandaloneScorecardElement-score-percentage]:!tracking-[-1.256px]'
              )}
            />
          </>
        )}
      </motion.div>
    </>
  );
};
