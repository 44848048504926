import { RealEstate } from '../../generated/graphql';
import { FC, createContext, useContext } from 'react';

interface RealEstateFormContextProps {
  householdID: string;
  children?: React.ReactNode;
  realEstate: RealEstate | undefined | null;
}

interface RealEstateFormContext extends Omit<RealEstateFormContextProps, 'children'> {}

const RealEstateFormContext = createContext<RealEstateFormContext | null>(null);

export const useRealEstateFormContext = () => {
  const context = useContext(RealEstateFormContext);

  if (!context) {
    throw new Error('Make sure to use the RealEstateFormContext inside of the provider');
  }
  return context;
};

export const RealEstateFormContextProvider: FC<RealEstateFormContextProps> = ({ children, ...props }) => {
  return <RealEstateFormContext.Provider value={props}>{children}</RealEstateFormContext.Provider>;
};
