import { ElementsIconProps } from '../components/Icon/types';

export function AlarmClockIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="alarm_clock_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.87 15.25L12.5 13.25V8.72C12.5 8.32 12.18 8 11.78 8H11.72C11.32 8 11 8.32 11 8.72V13.44C11 13.79 11.18 14.12 11.49 14.3L15.14 16.49C15.48 16.69 15.92 16.59 16.12 16.25C16.33 15.9 16.22 15.45 15.87 15.25ZM21.18 5.01L18.1 2.45C17.68 2.1 17.05 2.15 16.69 2.58C16.34 3 16.4 3.63 16.82 3.99L19.89 6.55C20.31 6.9 20.94 6.85 21.3 6.42C21.66 6 21.6 5.37 21.18 5.01ZM4.10001 6.55L7.17001 3.99C7.60001 3.63 7.66001 3 7.30001 2.58C6.95001 2.15 6.32001 2.1 5.90001 2.45L2.82001 5.01C2.40001 5.37 2.34001 6 2.70001 6.42C3.05001 6.85 3.68001 6.9 4.10001 6.55ZM12 4C7.03001 4 3.00001 8.03 3.00001 13C3.00001 17.97 7.03001 22 12 22C16.97 22 21 17.97 21 13C21 8.03 16.97 4 12 4ZM12 20C8.14001 20 5.00001 16.86 5.00001 13C5.00001 9.14 8.14001 6 12 6C15.86 6 19 9.14 19 13C19 16.86 15.86 20 12 20Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
