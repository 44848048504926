{
  "income-source-nickname-input-label": "Nickname",
  "income-source-amount-input-label": "Income Amount",
  "income-source-income-type-dropdown-label": "Income Type",
  "income-source-notes-textarea-label": "Notes",

  "investment-nickname-input-label": "Nickname",
  "investment-estimated-value-input-label": "Estimated Value",
  "investment-recurring-contribution-amount-input-label": "Amount",
  "investment-mix-dropdown-label": "Investment Mix",

  "cash-account-nickname-input-label": "Nickname",
  "cash-account-estimated-value-input-label": "Estimated Value",
  "cash-account-recurring-contribution-amount-input-label": "Amount",

  "business-name-input-label": "Business Name",
  "business-estimated-value-input-label": "Estimated Value",
  "business-entity-tax-structure-dropdown-label": "Business Tax Structure",
  "business-add-related-documents": "Add Related Documents",
  "business-ownership-allocation-input": "Ownership Percentage",

  "real-estate-property-nickname-input-label": "Nickname",
  "real-estate-property-estimated-value-input-label": "Estimated Value",
  "real-estate-property-state-dropdown-label": "State",
  "real-estate-property-street-address-one-input-label": "Street Address",
  "real-estate-property-street-address-two-input-label": "Apartment, Suite, Unit, etc.",
  "real-estate-property-city-input-label": "City",
  "real-estate-property-zip-code-input-label": "Zip Code",
  "real-estate-property-address-section-header": "Address",

  "loan-type-dropdown-label": "Loan Type",
  "loan-estimated-balance-input-label": "Outstanding Balance",
  "loan-payments-amount-input-label": "Payment Amount",
  "loan-payments-frequency-dropdown-label": "Payment Frequency",
  "loan-nickname-input-label": "Account Nickname",
  "loan-interest-rate-input-label": "Interest Rate",
  "loan-length-of-loan-input-label": "Length of Loan",

  "client-spending-form-spending-input-label": "Estimated Spending",

  "person-given-name-input-label": "First Name",
  "person-family-name-input-label": "Last Name",
  "person-email-input-label": "Email",

  "user-given-name-input-label": "First Name",
  "user-family-name-input-label": "Last Name",
  "user-email-input-label": "Email",

  "general-owner-dropdown-label": "Owner",
  "general-frequency-dropdown-label": "Frequency",
  "general-required-placeholder": "Required",
  "general-dob-date-picker-label": "Date of Birth",
  "general-frequency-input-frequency-month-abbr": "mo",
  "general-frequency-input-frequency-year-abbr": "yr",
  "general-password-input-label": "Password",
  "general-given-name-input-label": "First Name",
  "general-family-name-input-label": "Last Name",
  "general-email-input-label": "Email",
  "general-phone-input-label": "Phone Number",
  "general-add-related-documents": "Add Related Documents",
  "general-related-documents": "Related Documents",
  "general-owned-by-dropdown-label": "Owned By",
  "general-ownership": "Ownership",
  "general-ownership-allocation-input": "Ownership Percentage",
  "general-recurring-savings-contributions": "Recurring Savings Contributions",

  "general-placeholder-required": "Required",

  "taxReturnSummary-filling-status-dropdown-label": "Filling Status",
  "taxReturnSummary-personal-income-input-label": "Estimated Personal Income",
  "taxReturnSummary-federal-taxes-paid-input-label": "Federal Taxes Paid",
  "taxReturnSummary-state-taxes-paid-input-label": "State Taxes Paid",
  "taxReturnSummary-year-dropdown-label": "Tax Year",
  "tax-returns-summary-form-add-related-documents": "Add Tax Documents",
  "tax-return-summary-form-related-documents-title": "Tax Documents"
}
