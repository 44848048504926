import { ElementsIconProps } from '../components/Icon/types';

export function SampleClientIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.33446 2 2 3.35097 2 5H4C4 4.44903 4.44554 4 5 4V2ZM7 2V4H9V2H7ZM11 2V4H13V2H11ZM15 2V4H17V2H15ZM19 2V4C19.5477 4 20 4.45228 20 5H22C22 3.34772 20.6523 2 19 2ZM22 7H20V9H22V7ZM22 11H20V13H22V11ZM22 15H20V17H22V15ZM15 20H17V22H15V20ZM11 20H13V22H11V20ZM7 20H9V22H7V20ZM4 15V17H2V15H4ZM4 11V13H2V11H4ZM4 7V9H2V7H4ZM2 19H4C4 19.551 4.44554 20 5 20V22C3.33446 22 2 20.649 2 19ZM19 22V20C19.5477 20 20 19.5477 20 19H22C22 20.6523 20.6523 22 19 22Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C13.66 12 15 10.66 15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z"
        fill="currentColor"
      />
    </svg>
  );
}
