import { ElementsIconProps } from '../components/Icon/types';

export function RssFeedIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.45 21C4.76667 21 4.1875 20.7625 3.7125 20.2875C3.2375 19.8125 3 19.2333 3 18.55C3 17.8667 3.2375 17.2875 3.7125 16.8125C4.1875 16.3375 4.76667 16.1 5.45 16.1C6.13333 16.1 6.7125 16.3375 7.1875 16.8125C7.6625 17.2875 7.9 17.8667 7.9 18.55C7.9 19.2333 7.6625 19.8125 7.1875 20.2875C6.7125 20.7625 6.13333 21 5.45 21ZM18.875 21C18.575 21 18.3125 20.8917 18.0875 20.675C17.8625 20.4583 17.7333 20.1917 17.7 19.875C17.5667 18.0583 17.125 16.3583 16.375 14.775C15.625 13.1917 14.6417 11.7917 13.425 10.575C12.2083 9.35834 10.8083 8.37501 9.225 7.62501C7.64167 6.87501 5.94167 6.43334 4.125 6.30001C3.80833 6.26667 3.54167 6.14167 3.325 5.92501C3.10833 5.70834 3 5.44167 3 5.12501C3 4.80834 3.1125 4.53751 3.3375 4.31251C3.5625 4.08751 3.825 3.99167 4.125 4.02501C6.25833 4.15834 8.25417 4.66251 10.1125 5.53751C11.9708 6.41251 13.6083 7.55834 15.025 8.97501C16.4417 10.3917 17.5875 12.0292 18.4625 13.8875C19.3375 15.7458 19.8417 17.7417 19.975 19.875C20.0083 20.175 19.9167 20.4375 19.7 20.6625C19.4833 20.8875 19.2083 21 18.875 21ZM12.925 21C12.625 21 12.3625 20.8958 12.1375 20.6875C11.9125 20.4792 11.7833 20.2167 11.75 19.9C11.5333 17.7333 10.7417 15.9417 9.375 14.525C8.00833 13.1083 6.25 12.2833 4.1 12.05C3.78333 12.0167 3.52083 11.8917 3.3125 11.675C3.10417 11.4583 3 11.1917 3 10.875C3 10.5583 3.1125 10.2917 3.3375 10.075C3.5625 9.85834 3.825 9.76667 4.125 9.80001C6.89167 10.0333 9.17083 11.075 10.9625 12.925C12.7542 14.775 13.7667 17.0917 14 19.875C14.0333 20.175 13.9458 20.4375 13.7375 20.6625C13.5292 20.8875 13.2583 21 12.925 21Z"
        fill="currentColor"
      />
    </svg>
  );
}
