import { UseQtDetailsArgs } from './types';

export const qtDetailsKeys = {
  base: ['qtDetails'],
  qtDetails: (args: UseQtDetailsArgs) => {
    const key = [...qtDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeTotalRetirementAssets)
      key.push('includeTotalRetirementAssets');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includeTaxFreeAccounts) key.push('includeTaxFreeAccounts');
    if (args.includeTotalTaxFree) key.push('includeTotalTaxFree');
    if (args.includeTaxDeferredAccounts) key.push('includeTaxDeferredAccounts');
    if (args.includeTotalTaxDeferred) key.push('includeTotalTaxDeferred');
    if (args.includeOtherAccounts) key.push('includeOtherAccounts');
    if (args.includeTotalOther) key.push('includeTotalOther');
    return key;
  },
};
