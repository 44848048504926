import { ElementsIconProps } from '../components/Icon/types';

export function AlternateEmailIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="alternate_email_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7178 2.02784C6.62784 1.59784 1.59784 6.62784 2.02784 12.7178C2.38784 18.0078 7.00784 21.9978 12.3078 21.9978H15.9978C16.5478 21.9978 16.9978 21.5478 16.9978 20.9978C16.9978 20.4478 16.5478 19.9978 15.9978 19.9978H12.3278C8.59784 19.9978 5.17784 17.5778 4.24784 13.9678C2.75784 8.16784 8.15784 2.75784 13.9578 4.25784C17.5778 5.17784 19.9978 8.59784 19.9978 12.3278V13.4278C19.9978 14.2178 19.2878 14.9978 18.4978 14.9978C17.7078 14.9978 16.9978 14.2178 16.9978 13.4278V12.1778C16.9978 9.66784 15.2178 7.40784 12.7378 7.05784C9.33784 6.56784 6.46784 9.50784 7.07784 12.9278C7.41784 14.8378 8.90784 16.4178 10.7978 16.8678C12.6378 17.2978 14.3878 16.7078 15.5378 15.5378C16.4278 16.7578 18.2078 17.3978 19.8378 16.7478C21.1778 16.2178 21.9978 14.8478 21.9978 13.4078V12.3178C21.9978 7.00784 18.0078 2.38784 12.7178 2.02784ZM11.9978 14.9978C10.3378 14.9978 8.99784 13.6578 8.99784 11.9978C8.99784 10.3378 10.3378 8.99784 11.9978 8.99784C13.6578 8.99784 14.9978 10.3378 14.9978 11.9978C14.9978 13.6578 13.6578 14.9978 11.9978 14.9978Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_732"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7178 2.02784C6.62784 1.59784 1.59784 6.62784 2.02784 12.7178C2.38784 18.0078 7.00784 21.9978 12.3078 21.9978H15.9978C16.5478 21.9978 16.9978 21.5478 16.9978 20.9978C16.9978 20.4478 16.5478 19.9978 15.9978 19.9978H12.3278C8.59784 19.9978 5.17784 17.5778 4.24784 13.9678C2.75784 8.16784 8.15784 2.75784 13.9578 4.25784C17.5778 5.17784 19.9978 8.59784 19.9978 12.3278V13.4278C19.9978 14.2178 19.2878 14.9978 18.4978 14.9978C17.7078 14.9978 16.9978 14.2178 16.9978 13.4278V12.1778C16.9978 9.66784 15.2178 7.40784 12.7378 7.05784C9.33784 6.56784 6.46784 9.50784 7.07784 12.9278C7.41784 14.8378 8.90784 16.4178 10.7978 16.8678C12.6378 17.2978 14.3878 16.7078 15.5378 15.5378C16.4278 16.7578 18.2078 17.3978 19.8378 16.7478C21.1778 16.2178 21.9978 14.8478 21.9978 13.4078V12.3178C21.9978 7.00784 18.0078 2.38784 12.7178 2.02784ZM11.9978 14.9978C10.3378 14.9978 8.99784 13.6578 8.99784 11.9978C8.99784 10.3378 10.3378 8.99784 11.9978 8.99784C13.6578 8.99784 14.9978 10.3378 14.9978 11.9978C14.9978 13.6578 13.6578 14.9978 11.9978 14.9978Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_732)"></g>
      </g>
    </svg>
  );
}
