import { ElementsIconProps } from '../components/Icon/types';

export function RefreshIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="refresh_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6418 6.35146C16.0118 4.72146 13.7018 3.78146 11.1618 4.04146C7.49184 4.41146 4.47184 7.39146 4.06184 11.0615C3.51184 15.9115 7.26184 20.0015 11.9918 20.0015C15.1818 20.0015 17.9218 18.1315 19.2018 15.4415C19.5218 14.7715 19.0418 14.0015 18.3018 14.0015C17.9318 14.0015 17.5818 14.2015 17.4218 14.5315C16.2918 16.9615 13.5818 18.5015 10.6218 17.8415C8.40184 17.3515 6.61184 15.5415 6.14184 13.3215C5.30184 9.44146 8.25184 6.00146 11.9918 6.00146C13.6518 6.00146 15.1318 6.69146 16.2118 7.78146L14.7018 9.29146C14.0718 9.92146 14.5118 11.0015 15.4018 11.0015H18.9918C19.5418 11.0015 19.9918 10.5515 19.9918 10.0015V6.41146C19.9918 5.52146 18.9118 5.07146 18.2818 5.70146L17.6418 6.35146Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
