import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { LoanFormValues } from '../types';
import { FC } from 'react';

type LoanNicknameInputProps = Pick<InputProps, 'className'>;

export const LoanNicknameInput: FC<LoanNicknameInputProps> = ({
  className,
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<LoanFormValues>();

  return (
    <Input
      className={className}
      label={tForm('loan-nickname-input-label')}
      {...register('nickname', { required: true })}
      status={errors.nickname ? 'errored' : 'normal'}
      inputProps={{ placeholder: tForm('general-required-placeholder') }}
    />
  );
};
