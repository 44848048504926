import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  CreateAssetLoanInput,
  DeleteAssetLoanInput,
} from '../generated/graphql';
import {
  clientCreateAssetLoan,
  clientDeleteAssetLoan,
  useGraphqlClient,
} from '../graphql';
import { assetLoanKeys } from './queryKeys';
import { DeleteAssetLoanMutationArgs } from './types';

export const useCreateAssetLoan = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [input, setInput] = useState<CreateAssetLoanInput>();

  const createAssetLoan = useMutation({
    mutationFn: (input: CreateAssetLoanInput) => {
      setInput(input);
      return clientCreateAssetLoan(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        assetLoanKeys.assetLoansForAsset(
          input?.assetLoan.assetID,
          input?.householdID
        )
      );
      queryClient.invalidateQueries(
        assetLoanKeys.assetLoansForLoan(
          input?.assetLoan.loanID,
          input?.householdID
        )
      );
    },
  });

  return { createAssetLoan };
};

export const useDeleteAssetLoan = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const deleteAssetLoanMutation = useMutation({
    mutationFn: (input: DeleteAssetLoanInput) => {
      return clientDeleteAssetLoan(gqlClient, input);
    },
  });

  const deleteAssetLoan = ({
    assetID,
    householdID,
    loanID,
    assetLoanID,
  }: DeleteAssetLoanMutationArgs) =>
    deleteAssetLoanMutation.mutate(
      { householdID, id: assetLoanID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            assetLoanKeys.assetLoansForAsset(assetID, householdID)
          );
          queryClient.invalidateQueries(
            assetLoanKeys.assetLoansForLoan(loanID, householdID)
          );
        },
      }
    );

  return { deleteAssetLoan, deleteAssetLoanMutation: deleteAssetLoanMutation };
};
