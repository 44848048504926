import { ElementsIconProps } from '../components/Icon/types';

export function ChevronDownIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="chevron_down_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2894 9C16.8994 8.61 16.2694 8.61 15.8794 9L11.9994 12.88L8.11937 9C7.72937 8.61 7.09937 8.61 6.70937 9C6.31937 9.39 6.31937 10.02 6.70937 10.41L11.2994 15C11.6894 15.39 12.3194 15.39 12.7094 15L17.2994 10.41C17.6794 10.03 17.6794 9.39 17.2894 9Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_963"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="8"
          width="12"
          height="8"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2894 9C16.8994 8.61 16.2694 8.61 15.8794 9L11.9994 12.88L8.11937 9C7.72937 8.61 7.09937 8.61 6.70937 9C6.31937 9.39 6.31937 10.02 6.70937 10.41L11.2994 15C11.6894 15.39 12.3194 15.39 12.7094 15L17.2994 10.41C17.6794 10.03 17.6794 9.39 17.2894 9Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_963)"></g>
      </g>
    </svg>
  );
}
