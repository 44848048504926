import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  CreateLoanInput,
  DeleteLoanInput,
  UpdateLoanInput,
} from '../generated/graphql';
import {
  clientCreateLoan,
  clientDeleteLoan,
  clientUpdateLoan,
  useGraphqlClient,
} from '../graphql';
import { loanKeys } from './queryKeys';

export const useCreateLoan = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const createLoan = useMutation({
    mutationFn: (input: CreateLoanInput) => {
      setHouseholdID(input.householdID);
      return clientCreateLoan(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(loanKeys.loans(householdID));
    },
  });

  return { createLoan };
};

export const useUpdateLoan = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const updateLoan = useMutation({
    onMutate: (input: UpdateLoanInput) => input,
    mutationFn: (input: UpdateLoanInput) => {
      return clientUpdateLoan(gqlClient, input);
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(loanKeys.loans(context?.householdID));
    },
  });

  return { updateLoan };
};

export const useDeleteLoan = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const deleteLoan = useMutation({
    mutationFn: (input: DeleteLoanInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteLoan(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(loanKeys.loans(householdID));
    },
  });

  return { deleteLoan };
};
