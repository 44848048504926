import { ComponentPropsWithoutRef, forwardRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { percentageStringToNumber } from '../../../../util';
import { InputProps } from './Input';
import { InputContainer } from './components';
import { elementsInputClasses } from './util';

export interface PercentageInputProps
  extends Omit<InputProps, 'inputProps' | 'onChange'> {
  value?: number | null;
  decimalsLimit?: number;
  placeholder?: string;
  onChange?: (value: number | null) => void;
  inputProps?: Omit<
    ComponentPropsWithoutRef<'input'>,
    'onClick' | 'name' | 'disabled' | 'onChange' | 'onBlur' | 'id'
  >;
}

export const PercentageInput = forwardRef<
  HTMLInputElement,
  PercentageInputProps
>(
  (
    {
      name,
      value,
      decimalsLimit = 2,
      onBlur,
      onChange,
      inputProps,
      placeholder,
      ...inputContainerProps
    },
    ref
  ) => {
    return (
      <InputContainer
        isReadOnly={!!inputProps?.readOnly}
        {...inputContainerProps}
      >
        <CurrencyInput
          ref={ref}
          id={name}
          suffix="%"
          name={name}
          placeholder={placeholder}
          decimalsLimit={decimalsLimit}
          maxLength={3 + decimalsLimit}
          defaultValue={value ?? undefined}
          className={elementsInputClasses.input}
          onValueChange={(_, __, values) => onChange?.(values?.float || null)}
          transformRawValue={(v) => {
            const num = percentageStringToNumber(v);
            if (num === 100 || (num > 100 && !v.includes('.'))) {
              return '100';
            }
            return v;
          }}
        />
      </InputContainer>
    );
  }
);
