import clsx from 'clsx';
import { FC, useState } from 'react';
import { clientHasBusinesses } from '../../../../FirmClient';
import { ScorecardElementSymbol } from '../../../../Scorecard';
import { PresentationScorecardButton } from './PresentationScorecardButton';
import { Business, Investment, Scorecard } from '../../../../generated/graphql';
import { motion } from 'framer-motion';

interface PresentationScorecardProps {
  scorecard: Scorecard;
  businesses: Business[];
  investments: Investment[];
}

export const PresentationScorecard: FC<PresentationScorecardProps> = ({ scorecard, businesses, investments }) => {
  const [hoveredElement, setHoveredElement] = useState<ScorecardElementSymbol>();

  const hasBusinesses = clientHasBusinesses(businesses, investments);

  return (
    <motion.div
      layout
      className={clsx('grid grid-cols-5 gap-4 max-w-max', hasBusinesses ? 'grid-cols-5' : 'grid-cols-4')}
    >
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.er}
        element="Er"
        to="equity-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.newIr}
        element="Ir"
        to="insurance-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.sr}
        className="row-[2]"
        element="Sr"
        to="savings-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.br}
        className="row-[2]"
        element="Br"
        to="burn-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.dr}
        className="row-[2]"
        element="Dr"
        to="debt-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.tr}
        className="row-[2]"
        element="Tr"
        to="tax-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.lt}
        className="row-[3]"
        element="Lt"
        to="liquid-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.qt}
        className="row-[3]"
        element="Qt"
        to="qualified-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.rt}
        className="row-[3]"
        element="Rt"
        to="real-estate-term"
      />
      {hasBusinesses && (
        <PresentationScorecardButton
          scorecard={scorecard}
          setHoveredElement={setHoveredElement}
          hoveredElement={hoveredElement}
          value={scorecard.bt}
          className="row-[3]"
          element="Bt"
          to="business-term"
        />
      )}
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.tt}
        className="row-[3]"
        element="Tt"
        to="total-term"
      />
    </motion.div>
  );
};
