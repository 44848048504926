import { useElementDetailViewIndexPage, UseElementDetailViewIndexPageArgs } from '../../shared';

export interface UseBurnRateElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useBurnRateElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseBurnRateElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
