import { IAddInsuranceFormArgsInner, useAddInsuranceForm } from '../hooks';
import { InsuranceForm } from './InsuranceForm';
import { InsuranceNameInput } from './InsuranceNameInput';
import { InputSectioning, ElementsButton } from '../../UI';
import { InsuranceCoverageOrBenefitAmountInput } from './InsuranceCoverageOrBenefitAmountInput';
import { InsurancePurchaseDatePicker } from './InsurancePurchaseDatePicker';
import { isLifeInsurance } from '../util';
import { InsuranceLengthOfCoverageDropdown } from './InsuranceLengthOfCoverageDropdown';
import { InsuranceExpirationDatePicker } from './InsuranceExpirationDatePicker';
import { InsuranceOwnerDropdown } from './InsuranceOwnerDropdown';
import { InsuranceNotesInput } from './InsuranceNotesInput';
import { FileListItem } from '../../File';
import { InsuranceCreateFileInput } from './InsuranceCreateFileInput';
import { OwnerType } from '../../generated/graphql';
import { IInsuranceForm } from '../types';

export interface IAddInsuranceFormArgs
  extends IAddInsuranceFormArgsInner,
    IInsuranceForm {}

export const AddInsuranceForm = ({
  insurancePolicyType,
  insurancePolicySubtype,
  householdID,
  firmId,
  firmClientId,
  onSuccess = () => {},
}: IAddInsuranceFormArgs) => {
  const {
    tCommon,
    isCreatingPolicy,
    useFormReturn,
    tInsurance,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    defaultOnValidSubmit,
    allowDocuments,
  } = useAddInsuranceForm({
    insurancePolicyType,
    insurancePolicySubtype,
    householdID,
  });

  return (
    <>
      <InsuranceForm
        useFormReturn={useFormReturn}
        onValid={async (data) => {
          await defaultOnValidSubmit(data);
          onSuccess();
        }}
      >
        <InsuranceNameInput />
        <InputSectioning
          className="mt-4"
          columns={[
            <InsuranceCoverageOrBenefitAmountInput />,
            <InsurancePurchaseDatePicker />,
          ]}
        />
        <InputSectioning
          columns={[
            isLifeInsurance(insurancePolicySubtype) ? (
              <InsuranceLengthOfCoverageDropdown />
            ) : (
              <InsuranceExpirationDatePicker />
            ),
            <InsuranceOwnerDropdown
              clientID={firmClientId}
              firmID={firmId}
              ownerFilter={(owner) =>
                [OwnerType.Person, OwnerType.Business].includes(owner.type)
              }
            />,
          ]}
          className={'mt-4'}
        />
        <InsuranceNotesInput />

        {allowDocuments && (
        <>
          <h3 className="mb-[26px] mt-11 text-[16px] font-bold leading-normal text-darkMode-gray-medium">
            {tInsurance('insurance-form-related-documents')}
          </h3>
          {pendingFiles && (
            <div className="mb-[18px]">
              {pendingFiles.map((fileAttachment) => (
                <FileListItem
                  subtitle={false}
                  itemID={undefined}
                  file={fileAttachment}
                  key={fileAttachment.id}
                  tenantID={householdID}
                  fileAttachmentID={fileAttachment.id}
                  className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
                  onDeleteSuccess={deletePendingFile(fileAttachment)}
                />
              ))}
            </div>
          )}

          <InsuranceCreateFileInput
            onPendingFileCreation={onPendingFileCreation}
            householdID={householdID}
            />
          </>
        )}

        <ElementsButton
          label={tCommon('save')}
          type="submit"
          iconPosition="left"
          className="mt-20"
          disabled={isCreatingPolicy || !useFormReturn.formState.isValid}
          isLoading={isCreatingPolicy}
          icon="check"
          size="large"
        />
      </InsuranceForm>
    </>
  );
};
