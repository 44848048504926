import { ElementsIconProps } from '../components/Icon/types';

export function SearchIcon(props: ElementsIconProps) {
  return (
    <svg
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94196 7.64772H8.41629L8.22998 7.46772C9.02846 6.53438 9.44101 5.26105 9.21478 3.90772C8.90204 2.05438 7.3583 0.574382 5.49518 0.347715C2.68053 0.00104856 0.311701 2.37438 0.65771 5.19438C0.883946 7.06105 2.36114 8.60772 4.21095 8.92105C5.56172 9.14772 6.83264 8.73438 7.7642 7.93438L7.94386 8.12105V8.64772L10.7718 11.481C11.0446 11.7544 11.4904 11.7544 11.7633 11.481C12.0361 11.2077 12.0361 10.761 11.7633 10.4877L8.94196 7.64772ZM4.94955 7.64772C3.2927 7.64772 1.95524 6.30772 1.95524 4.64772C1.95524 2.98772 3.2927 1.64772 4.94955 1.64772C6.6064 1.64772 7.94386 2.98772 7.94386 4.64772C7.94386 6.30772 6.6064 7.64772 4.94955 7.64772Z"
        fill="currentColor"
      />
    </svg>
  );
}
