import clsx from 'clsx';
import { StandaloneScorecardElement } from '../../UI';
import { UseScorecardElementCalculationGroupArgs, useScorecardElementCalculationGroup } from '../hooks';

interface ScorecardElementCalculationGroupProps extends UseScorecardElementCalculationGroupArgs {
  className?: string;
}

export function ScorecardElementCalculationGroup({
  element,
  className,
  householdID,
}: ScorecardElementCalculationGroupProps) {
  const { isLoading, numerator, denominator, scorecardValue, displayMonetaryAmount } =
    useScorecardElementCalculationGroup({ householdID, element });

  if (isLoading) return null;

  return (
    <div className={clsx(className, 'flex mt-14 h-[125px]')}>
      <StandaloneScorecardElement element={element} className="self-end" value={scorecardValue} />
      <div className="ml-[19px] flex flex-col justify-between">
        <p className="text-darkMode-gray-light/70 text-[16.782px] tracking-[0.127px] mb-[-6px] leading-[normal]">
          {numerator?.title}
        </p>
        <p className="text-[30.207px] leading-[normal] text-white tracking-[0.245px] font-medium">
          {displayMonetaryAmount(numerator?.value, {
            nullishIsDash: true,
          })}
        </p>
        <div className="h-[1.678px] bg-primary w-[222px]" />
        <p className="text-darkMode-gray-light/70 text-[16.782px] tracking-[0.127px] mb-[-6px] leading-[normal]">
          {denominator?.title}
        </p>
        <p className="text-[30.207px] leading-[normal] text-white tracking-[0.245px] font-medium">
          {displayMonetaryAmount(denominator?.value, {
            nullishIsDash: true,
          })}
        </p>
      </div>
    </div>
  );
}
