import { ElementsIconProps } from '../components/Icon/types';

export function FastForwardIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="fast_forward_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.19501 16.89L10.965 12.82C11.525 12.42 11.525 11.58 10.965 11.19L5.19501 7.11001C4.52501 6.65001 3.61501 7.12001 3.61501 7.93001V16.07C3.61501 16.88 4.52501 17.35 5.19501 16.89ZM12.615 7.93001V16.07C12.615 16.88 13.525 17.35 14.195 16.89L19.965 12.82C20.525 12.42 20.525 11.58 19.965 11.19L14.195 7.12001C13.525 6.65001 12.615 7.12001 12.615 7.93001Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_417"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="6"
          width="18"
          height="12"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.19501 16.89L10.965 12.82C11.525 12.42 11.525 11.58 10.965 11.19L5.19501 7.11001C4.52501 6.65001 3.61501 7.12001 3.61501 7.93001V16.07C3.61501 16.88 4.52501 17.35 5.19501 16.89ZM12.615 7.93001V16.07C12.615 16.88 13.525 17.35 14.195 16.89L19.965 12.82C20.525 12.42 20.525 11.58 19.965 11.19L14.195 7.12001C13.525 6.65001 12.615 7.12001 12.615 7.93001Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_417)"></g>
      </g>
    </svg>
  );
}
