import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseCashSummarySumTableArgs {
  householdID: string;
}

export function useCashSummarySumTable({ householdID }: UseCashSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tCashAccount } = useTranslation('cashAccount');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalCashAccounts = ttDetails?.assetSummary.totalCashAccounts;
  const cashAccounts = ttDetails?.assetSummary.cashAccounts.sort((a, b) => a.name.localeCompare(b.name));

  return { cashAccounts, totalCashAccounts, tUI, isLoading, tCashAccount };
}
