import { useTranslation } from 'react-i18next';
import { useFirmClient } from '../../../../FirmClient';

export interface UseElementDetailViewIndexPageArgs {
  firmID: string | undefined | null;
  firmClientID: string | undefined | null;
}

export function useElementDetailViewIndexPage({ firmClientID, firmID }: UseElementDetailViewIndexPageArgs) {
  const { t: tPages } = useTranslation('pages');
  const { t: tScorecard } = useTranslation('scorecard');

  const { firmClient } = useFirmClient({ firmClientID, firmID });
  const householdID = firmClient?.household?.id;

  return {
    tPages,
    tScorecard,
    householdID,
  };
}
