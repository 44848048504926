import { ElementsIconProps } from '../components/Icon/types';

export function StarBorderIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="star_border_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6485 9.29459L14.8085 8.87459L12.9185 4.42459C12.5785 3.61459 11.4185 3.61459 11.0785 4.42459L9.18855 8.88459L4.35855 9.29459C3.47855 9.36459 3.11855 10.4646 3.78855 11.0446L7.45855 14.2246L6.35855 18.9446C6.15855 19.8046 7.08855 20.4846 7.84855 20.0246L11.9985 17.5246L16.1485 20.0346C16.9085 20.4946 17.8385 19.8146 17.6385 18.9546L16.5385 14.2246L20.2085 11.0446C20.8785 10.4646 20.5285 9.36459 19.6485 9.29459ZM11.9985 15.6546L8.23855 17.9246L9.23855 13.6446L5.91855 10.7646L10.2985 10.3846L11.9985 6.35459L13.7085 10.3946L18.0885 10.7746L14.7685 13.6546L15.7685 17.9346L11.9985 15.6546Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_1072"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6485 9.29459L14.8085 8.87459L12.9185 4.42459C12.5785 3.61459 11.4185 3.61459 11.0785 4.42459L9.18855 8.88459L4.35855 9.29459C3.47855 9.36459 3.11855 10.4646 3.78855 11.0446L7.45855 14.2246L6.35855 18.9446C6.15855 19.8046 7.08855 20.4846 7.84855 20.0246L11.9985 17.5246L16.1485 20.0346C16.9085 20.4946 17.8385 19.8146 17.6385 18.9546L16.5385 14.2246L20.2085 11.0446C20.8785 10.4646 20.5285 9.36459 19.6485 9.29459ZM11.9985 15.6546L8.23855 17.9246L9.23855 13.6446L5.91855 10.7646L10.2985 10.3846L11.9985 6.35459L13.7085 10.3946L18.0885 10.7746L14.7685 13.6546L15.7685 17.9346L11.9985 15.6546Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1072)"></g>
      </g>
    </svg>
  );
}
