import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { PersonalRealEstateSummarySumTable } from '../../../TtDetails';
import { TotalTermElementDetailViewPersonalRealEstatePageProps } from './types';
import { SummaryPageContainer } from './components';

export function TotalTermElementDetailViewPersonalRealEstatePage({
  householdID,
}: TotalTermElementDetailViewPersonalRealEstatePageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-personal-real-estate-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-personal-real-estate-summary-page-empty-description')}
    >
      <PersonalRealEstateSummarySumTable hideOnEmpty className="mt-10" householdID={householdID} />
    </SummaryPageContainer>
  );
}
