import { UseBrDetailsArgs } from './types';
import { brDetailsKeys } from './queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getBrDetails, useGraphqlClient } from '../graphql';

export const useBrDetails = ({ householdID, ...args }: UseBrDetailsArgs) => {
  const client = useGraphqlClient();

  const brDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: brDetailsKeys.brDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getBrDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualIncome: args.includeAnnualIncome ?? false,
          includeIncomeUpdates: args.includeIncomeUpdates ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeSpendingUpdates: args.includeSpendingUpdates ?? false,
        });
      }
    },
  });

  return {
    brDetailsQuery,
    brDetails: brDetailsQuery.data,
  };
};
