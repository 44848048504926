import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RecurringFrequency } from '../../../../generated/graphql';
import { Dropdown, DropdownProps } from './Dropdown';
import { DropdownOption } from './components';

export interface FrequencyDropdownProps
  extends Pick<
    DropdownProps,
    | 'dropdownMenuClassName'
    | 'placeholder'
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'name'
    | 'status'
    | 'label'
    | 'withClearSelection'
  > {}

export const FrequencyDropdown = forwardRef<
  HTMLInputElement,
  FrequencyDropdownProps
>((dropdownProps, ref) => {
  const { t: tForm } = useTranslation('form');
  const { t: tCommon } = useTranslation('common');

  return (
    <Dropdown
      ref={ref}
      label={tForm('general-frequency-dropdown-label')}
      {...dropdownProps}
    >
      <DropdownOption
        label={tCommon('bi-weekly')}
        id={RecurringFrequency.Biweekly}
        value={RecurringFrequency.Biweekly}
      />
      <DropdownOption
        label={tCommon('monthly')}
        id={RecurringFrequency.Monthly}
        value={RecurringFrequency.Monthly}
      />
      <DropdownOption
        id={RecurringFrequency.Quarterly}
        value={RecurringFrequency.Quarterly}
        label={tCommon('quarterly')}
      />
      <DropdownOption
        id={RecurringFrequency.Annually}
        value={RecurringFrequency.Annually}
        label={tCommon('annually')}
      />
    </Dropdown>
  );
});
