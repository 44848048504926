import { useTranslation } from 'react-i18next';
import { InvestmentFormValues } from '../types';
import { InputElementProps, MonetaryInput } from '../../UI';
import { useController, useFormContext } from 'react-hook-form';

interface InvestmentEstimatedValueInputProps extends Pick<InputElementProps, 'required'> {
  className?: string;
}

export function InvestmentEstimatedValueInput({ required = true, ...props }: InvestmentEstimatedValueInputProps) {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<InvestmentFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<InvestmentFormValues, 'estimatedValue'>({
    control,
    rules: { required },
    name: 'estimatedValue',
  });

  return (
    <MonetaryInput
      {...field}
      {...props}
      status={errors.estimatedValue ? 'errored' : 'normal'}
      label={tForm('investment-estimated-value-input-label')}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
}
