import { ElementsIconProps } from '../components/Icon/types';

export function ChatAppleBusinessIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chat_apple_business_24px">
        <path
          id="Path"
          d="M12 20.659C18.3813 20.659 23.5543 16.482 23.5543 11.3295C23.5543 6.17696 18.3813 2 12 2C5.61881 2 0.445801 6.17696 0.445801 11.3295C0.445801 14.4771 2.37622 17.2606 5.33324 18.9503C5.33993 18.9541 5.36499 18.9682 5.40843 18.9926L5.40844 18.9926C5.44674 19.0142 5.47388 19.0512 5.48289 19.0942C5.73725 20.3095 4.07324 21.3994 2.96107 22.1266C6.64725 22.3586 8.50711 20.8829 9.03929 20.399C9.94169 20.5817 11.0275 20.659 12 20.659Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
