import { useMutation } from '@tanstack/react-query';
import { changePassword, clientGetMagicLink, useGraphqlClient } from '../graphql';
import { useElementsEnvironmentContext } from '../environment';
import { ChangePasswordInput } from '../generated/graphql';

export const useGetMagicLink = () => {
  const { isDev } = useElementsEnvironmentContext();
  const gqlClient = useGraphqlClient();

  const getMagicLink = useMutation({
    mutationFn: ({ email }: { email: string }) => {
      return clientGetMagicLink(gqlClient, {
        email,
        domain: isDev ? 'my.dev.getelements.com' : 'my.getelements.com',
      });
    },
  });

  return { getMagicLink };
};


export const useUpdatePassword = () => {
  const gqlClient = useGraphqlClient();
  
  const updatePassword = useMutation({
    mutationFn: (input: ChangePasswordInput) => {
      return changePassword(gqlClient, input);
    }
  })

  return { updatePassword };
}
