import { GraphQLClient, gql } from 'graphql-request';
import { MonetaryAmountString } from './fragments';
import { Query } from '../generated/graphql';
import { QueryBrDetailsArgsWithDirectives } from './types';

const brDetailsQuery = gql`
  query BrDetails($householdID: ID!, $includeScore: Boolean!, $includeAnnualExpenses: Boolean!, $includeAnnualIncome: Boolean!, $includeSpendingUpdates: Boolean!, $includeIncomeUpdates: Boolean!) {
    brDetails(householdID: $householdID) {
      br @include(if: $includeScore)
      annualExpenses @include(if: $includeAnnualExpenses) {${MonetaryAmountString}}
      annualIncome @include(if: $includeAnnualIncome)  {${MonetaryAmountString}}
      spendingUpdates @include(if: $includeSpendingUpdates) {
        dateTime
        monthlyAmount {${MonetaryAmountString}}
        dataPointID
        dataPointGroupID
      }
      incomeUpdates @include(if: $includeIncomeUpdates) {
        dateTime
        annualAmount {${MonetaryAmountString}}
        delta {${MonetaryAmountString}}
        dataPointID
        dataPointGroupID
      }
    }
  }
`;

export const getBrDetails = async (
  client: GraphQLClient,
  args: QueryBrDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'brDetails'>,
      QueryBrDetailsArgsWithDirectives
    >(brDetailsQuery, args)
  ).brDetails;
};
