import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { usePreTaxInvestmentsSummarySumTable } from '../hooks';
import { PreTaxInvestmentsSummarySumTableProps } from '../types';
import { displayInvestmentType, getInvestmentValue } from '../../Investment';

export function PreTaxInvestmentsSummarySumTable({ householdID, ...props }: PreTaxInvestmentsSummarySumTableProps) {
  const { isLoading, tUI, preTaxInvestments, tInvestment, totalPreTaxInvestments } =
    usePreTaxInvestmentsSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalPreTaxInvestments}
      sumTitle={tUI('pre-tax-investments-summary-sum-table-total-title')}
    >
      {preTaxInvestments?.map((investment) => (
        <AmountSumTableItem
          key={investment?.id}
          title={investment.name}
          amount={getInvestmentValue(investment)}
          subtitle={displayInvestmentType(investment.investmentType, tInvestment)}
        />
      ))}
    </AmountSumTable>
  );
}
