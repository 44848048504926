import { useWindowSize } from 'react-use';
import { useFirmBranding } from '../../../../Firm';
import { useEffect, useRef, useState } from 'react';
import { useFirmClient } from '../../../../FirmClient';
import {
  ScorecardElementSymbol,
  SCORECARD_ELEMENT_SYMBOLS,
  SCORECARD_ASSETS_ELEMENT_SYMBOLS,
  SCORECARD_CASH_FLOW_ELEMENT_SYMBOLS,
  SCORECARD_RISK_ELEMENT_SYMBOLS,
  useScorecard,
} from '../../../../Scorecard';
import { useBusinesses } from '../../../../Business';
import { useInvestments } from '../../../../Investment';
import { PresentationViewOptions } from '../types';
import { DEFAULT_PRESENTATION_VIEW_OPTIONS } from '../constants';
import { getHeadsOfHousehold, usePersons } from '../../../../Person';
import { isElementGroupFocused } from '../util';

export interface UseFullScreenScorecardPageArgs {
  firmID: string;
  clientID: string;
}

export const useFullScreenScorecardPage = ({
  firmID,
  clientID,
}: UseFullScreenScorecardPageArgs) => {
  const { width, height } = useWindowSize();

  const { firmClient } = useFirmClient({ firmClientID: clientID, firmID });
  const householdID = firmClient?.household?.id;
  const { scorecard, scorecardQuery } = useScorecard({ householdID });
  const { businesses, businessesQuery } = useBusinesses({ householdID });
  const { firmBranding, firmBrandingQuery } = useFirmBranding(firmID);
  const { investments, investmentsQuery } = useInvestments({ householdID });
  const { persons } = usePersons({ householdID });

  const firmHasNoLogo = !firmBrandingQuery.isLoading && !firmBranding?.logo;
  const headsOfHousehold = getHeadsOfHousehold(persons);

  const scorecardRef = useRef<HTMLDivElement>(null);
  const firmLogoImageRef = useRef<HTMLImageElement>(null);
  const fullPresentationRef = useRef<HTMLDivElement>(null);
  const [scaleFactor, setScaleFactor] = useState<number>();
  const [viewOptions, setViewOptions] = useState<PresentationViewOptions>(
    DEFAULT_PRESENTATION_VIEW_OPTIONS
  );

  const allFocused =
    viewOptions.focusedElements?.length === SCORECARD_ELEMENT_SYMBOLS.length;
  const cashFlowFocused = isElementGroupFocused(
    viewOptions.focusedElements,
    SCORECARD_CASH_FLOW_ELEMENT_SYMBOLS
  );
  const assetsFocused = isElementGroupFocused(
    viewOptions.focusedElements,
    SCORECARD_ASSETS_ELEMENT_SYMBOLS
  );
  const riskFocused = isElementGroupFocused(
    viewOptions.focusedElements,
    SCORECARD_RISK_ELEMENT_SYMBOLS
  );

  function modifyViewOptions(options: Partial<PresentationViewOptions>) {
    setViewOptions((prev) => ({ ...prev, ...options }));
  }

  function toggleShowClientName() {
    modifyViewOptions({ showClientName: !viewOptions.showClientName });
  }

  function toggleShowClientAge() {
    modifyViewOptions({ showClientAge: !viewOptions.showClientAge });
  }

  function toggleShowFinancialDetails() {
    modifyViewOptions({
      showFinancialDetails: !viewOptions.showFinancialDetails,
    });
  }

  function toggleElementVisibility(element: ScorecardElementSymbol) {
    modifyViewOptions({
      focusedElements: viewOptions.focusedElements?.includes(element)
        ? viewOptions.focusedElements.filter((e) => e !== element)
        : [...(viewOptions.focusedElements ?? []), element],
    });
  }

  function toggleAllElementsVisibility() {
    modifyViewOptions({
      focusedElements: allFocused ? [] : SCORECARD_ELEMENT_SYMBOLS,
    });
  }

  function toggleCashFlowElementsVisibility() {
    modifyViewOptions({
      focusedElements: cashFlowFocused
        ? SCORECARD_ELEMENT_SYMBOLS
        : SCORECARD_CASH_FLOW_ELEMENT_SYMBOLS,
    });
  }

  function toggleAssetsElementsVisibility() {
    modifyViewOptions({
      focusedElements: assetsFocused
        ? SCORECARD_ELEMENT_SYMBOLS
        : SCORECARD_ASSETS_ELEMENT_SYMBOLS,
    });
  }

  function toggleRiskElementsVisibility() {
    modifyViewOptions({
      focusedElements: riskFocused
        ? SCORECARD_ELEMENT_SYMBOLS
        : SCORECARD_RISK_ELEMENT_SYMBOLS,
    });
  }

  useEffect(() => {
    setScaleFactor(Math.min(width / 1920, height / 1080));
  }, [width, height]);

  return {
    householdID,
    firmHasNoLogo,
    scorecard,
    scorecardQuery,
    scaleFactor,
    firmBranding,
    firmBrandingQuery,
    fullPresentationRef,
    scorecardRef,
    firmLogoImageRef,
    firmClient,
    businesses,
    businessesQuery,
    investments,
    investmentsQuery,
    viewOptions,
    headsOfHousehold,
    toggleShowClientName,
    toggleShowClientAge,
    toggleElementVisibility,
    toggleAllElementsVisibility,
    toggleCashFlowElementsVisibility,
    toggleAssetsElementsVisibility,
    toggleRiskElementsVisibility,
    allFocused,
    cashFlowFocused,
    riskFocused,
    assetsFocused,
    toggleShowFinancialDetails,
  };
};
