import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { useAfterTaxInvestmentsSummarySumTable } from '../hooks';
import { AfterTaxInvestmentsSummarySumTableProps } from '../types';
import { displayInvestmentType, getInvestmentValue } from '../../Investment';

export function AfterTaxInvestmentsSummarySumTable({ householdID, ...props }: AfterTaxInvestmentsSummarySumTableProps) {
  const { isLoading, tUI, afterTaxInvestments, tInvestment, totalAfterTaxInvestments } =
    useAfterTaxInvestmentsSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalAfterTaxInvestments}
      sumTitle={tUI('after-tax-investments-summary-sum-table-total-title')}
    >
      {afterTaxInvestments?.map((investment) => (
        <AmountSumTableItem
          key={investment?.id}
          title={investment.name}
          amount={getInvestmentValue(investment)}
          subtitle={displayInvestmentType(investment.investmentType, tInvestment)}
        />
      ))}
    </AmountSumTable>
  );
}
