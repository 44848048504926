import { ScorecardElementSymbol, isScorecardTermElement } from '../../Scorecard';
import { targetScoreRangeRateDefaultValue } from './targetScoreRangeRateDefaultValue';


export function targetScoreRangeItemFormValue(value: number | null | undefined, element: ScorecardElementSymbol) {
  if (isScorecardTermElement(element)) {
    return value ?? null;
  }
  return targetScoreRangeRateDefaultValue(value);
}
