{
  "is": "is",
  "are": "are",
  "amount": "Amount",
  "frequency": "Frequency",
  "sign-out": "Sign Out",
  "sign-in": "Sign in",
  "first-name": "First Name",
  "last-name": "Last Name",
  "name": "Name",
  "relationship": "Relationship",
  "prospect": "Prospect",
  "email": "Email",
  "date-of-birth": "Date of Birth",
  "cancel": "Cancel",
  "back": "Back",
  "created": "Created",
  "close": "Close",
  "undo": "Undo",
  "edit": "Edit",
  "other": "Other",
  "assets": "Assets",
  "debts": "Debts",
  "cash": "Cash",
  "send": "Send",
  "copy": "Copy",
  "copied": "Copied!",
  "save": "Save",
  "yes": "Yes",
  "no": "No",
  "ok": "Ok",
  "add": "Add",
  "resend": "Resend",
  "delete": "Delete",
  "remove": "Remove",
  "done": "Done",
  "go-back": "Go Back",
  "dont-save": "Don’t Save",
  "dont-show-again": "Don't show again",
  "required": "Required",
  "cash-accounts": "Cash Accounts",
  "investments": "Investments",
  "real-estate": "Real Estate",
  "after-tax-investments": "After-tax Investments",
  "pre-tax-investments": "Pre-tax Investments",
  "personal-real-estate": "Personal Real Estate",
  "personal-property-item": "Personal Property Item",
  "note-receivable": "Note Receivable",
  "business": "Business",
  "businesses": "Businesses",
  "owned-by": "Owned By",
  "notes": "Notes",
  "copy-right": "Copyright © {{year}} Elements Advisors, LLC",
  "debt-rate": "Debt Rate",
  "savings-rate": "Savings Rate",
  "rate": "Rate",
  "term": "Term",
  "annual-income": "Annual Income",
  "age-display": "{{age}} old",
  "liquid-term": "Liquid Term",
  "ago-display": "{{ago}} ago",
  "ago": "ago",
  "nothing-here": "Woah! Nothing here...",
  "monthly": "Monthly",
  "bi-weekly": "Bi-Weekly",
  "quarterly": "Quarterly",
  "annually": "Annually",
  "investment-mix": "{{s}}% stocks, {{b}}% bonds",
  "dont-know": "Don't Know",
  "very-conservative": "Very Conservative",
  "conservative": "Conservative",
  "moderate": "Moderate",
  "moderate-growth": "Moderate Growth",
  "balanced": "Balanced",
  "balanced-growth": "Balanced Growth",
  "growth": "Growth",
  "aggressive-growth": "Aggressive Growth",
  "equity-growth": "Equity Growth",
  "alabama": "Alabama",
  "alabama-id": "AL",
  "alaska": "Alaska",
  "alaska-id": "AK",
  "arizona": "Arizona",
  "arizona-id": "AZ",
  "arkansas": "Arkansas",
  "arkansas-id": "AR",
  "california": "California",
  "california-id": "CA",
  "colorado": "Colorado",
  "colorado-id": "CO",
  "connecticut": "Connecticut",
  "connecticut-id": "CT",
  "delaware": "Delaware",
  "delaware-id": "DE",
  "florida": "Florida",
  "florida-id": "FL",
  "georgia": "Georgia",
  "georgia-id": "GA",
  "hawaii": "Hawaii",
  "hawaii-id": "HI",
  "idaho": "Idaho",
  "idaho-id": "ID",
  "illinois": "Illinois",
  "illinois-id": "IL",
  "indiana": "Indiana",
  "indiana-id": "IN",
  "iowa": "Iowa",
  "iowa-id": "IA",
  "kansas": "Kansas",
  "kansas-id": "KS",
  "kentucky": "Kentucky",
  "kentucky-id": "KY",
  "louisiana": "Louisiana",
  "louisiana-id": "LA",
  "maine": "Maine",
  "maine-id": "ME",
  "maryland": "Maryland",
  "maryland-id": "MD",
  "massachusetts": "Massachusetts",
  "massachusetts-id": "MA",
  "michigan": "Michigan",
  "michigan-id": "MI",
  "minnesota": "Minnesota",
  "minnesota-id": "MN",
  "mississippi": "Mississippi",
  "mississippi-id": "MS",
  "missouri": "Missouri",
  "missouri-id": "MO",
  "montana": "Montana",
  "montana-id": "MT",
  "nebraska": "Nebraska",
  "nebraska-id": "NE",
  "nevada": "Nevada",
  "nevada-id": "NV",
  "new-hampshire": "New Hampshire",
  "new-hampshire-id": "NH",
  "new-jersey": "New Jersey",
  "new-jersey-id": "NJ",
  "new-mexico": "New Mexico",
  "new-mexico-id": "NM",
  "new-york": "New York",
  "new-york-id": "NY",
  "north-carolina": "North Carolina",
  "north-carolina-id": "NC",
  "north-dakota": "North Dakota",
  "north-dakota-id": "ND",
  "ohio": "Ohio",
  "ohio-id": "OH",
  "oklahoma": "Oklahoma",
  "oklahoma-id": "OK",
  "oregon": "Oregon",
  "oregon-id": "OR",
  "pennsylvania": "Pennsylvania",
  "pennsylvania-id": "PA",
  "rhode-island": "Rhode Island",
  "rhode-island-id": "RI",
  "south-carolina": "South Carolina",
  "south-carolina-id": "SC",
  "south-dakota": "South Dakota",
  "south-dakota-id": "SD",
  "tennessee": "Tennessee",
  "tennessee-id": "TN",
  "texas": "Texas",
  "texas-id": "TX",
  "utah": "Utah",
  "utah-id": "UT",
  "vermont": "Vermont",
  "vermont-id": "VT",
  "virginia": "Virginia",
  "virginia-id": "VA",
  "washington": "Washington",
  "washington-id": "WA",
  "washington-dc": "Washington, DC",
  "washington-dc-id": "DC",
  "west-virginia": "West Virginia",
  "west-virginia-id": "WV",
  "wisconsin": "Wisconsin",
  "wisconsin-id": "WI",
  "wyoming": "Wyoming",
  "wyoming-id": "WY",
  "age": "Age {{age}}",
  "student-loans": "Student Loans",
  "business-debt": "Business Debt",
  "personal-real-estate-loans": "Personal Real Estate Loans",
  "other-loans": "Other Loans",
  "loan-maturity-distance": "{{left}} left",
  "extra-payments-description": " (+${{amount}})",
  "payments-description": "${{amount}}{{extraPaymentsInfo}}/{{frequency}} {{maturityInfo}}",
  "balloon-info": "Balloon: {{date}}",
  "interest-rate-description": "{{interestRate}}% {{interestRateTypeDesc}} {{balloonPaymentInfo}}",
  "missing-payment-details": "(missing payment details)",
  "missing-interest-details": "(missing interest rate details)",
  "missing-start-date": "(missing start date)",
  "interest": "Interest",
  "fixed": "Fixed",
  "variable": "Variable",
  "resend-invitation": "Resend Invitation",
  "invite-to-elements": "Invite to Elements",
  "dismiss": "Dismiss",
  "phone": "Phone",
  "occupation": "Occupation",
  "account": "Account",
  "balance": "Balance",
  "contributions": "Contributions",
  "an": "an",
  "a": "a",
  "password": "Password",
  "privacy-policy": "Privacy Policy",
  "terms-of-use": "Terms of Use",
  "insurance": "Insurance",
  "submit": "Submit",
  "preview": "Preview",
  "advisor": "Advisor",
  "source": "Source",
  "and": "and",
  " & ": " & ",
  "username": "username",
  "yearly-payment-frequency": "{{amount}}/yr",
  "yearly-frequency-suffix": "/yr",
  "monthly-frequency-suffix": "/mo",
  "biweekly-frequency-suffix": "/biweekly",
  "quarterly-frequency-suffix": "/quarter",
  "loading": "Loading...",
  "enable": "Enable",
  "disable": "Disable",
  "continue": "Continue",
  "legal-disclaimers-description": "By continuing, you confirm that you have read our <privacyPolicy>Privacy Policy</privacyPolicy>, <termsOfUse>Terms and Conditions</termsOfUse>, and <disclaimer>Disclaimers</disclaimer>.",
  "empty-table": "🔍 Nothing here!",
  "element-client-mobile-net-worth": "Net Worth",
  "element-client-mobile-current-annual-income": "Current Annual Income",
  "element-client-mobile-annual-spending": "Annual Spending",
  "element-client-mobile-graph-filter-past-all": "All",
  "element-client-mobile-graph-filter-past-1-year": "1y",
  "element-client-mobile-graph-filter-past-6-months": "6m",
  "element-client-mobile-graph-filter-past-3-months": "3m",
  "integrations-plaid": "Plaid",
  "integrations-baa": "ByAllAccounts",
  "integrations-linked-account-tooltip-connected": "connected",
  "integrations-linked-account-tooltip-disconnected": "disconnected",
  "element-score-tile-tooltip-target-range-set-by-advisor": "The target range of <targetRange>{{min}}-{{max}}</targetRange> was set by your advisor.",
  "client-dashboard-side-bar-nav-home": "Home",
  "client-dashboard-side-bar-nav-messages": "Messages",
  "client-dashboard-side-bar-menu-my-account": "My Account",
  "client-dashboard-side-bar-menu-person-information": "Personal Information",
  "client-dashboard-side-bar-menu-immediate-family": "Immediate Family",
  "client-dashboard-side-bar-menu-team": "Team",
  "client-dashboard-side-bar-menu-connected-accounts": "Connected Accounts",
  "client-dashboard-side-bar-menu-privacy-policy": "Privacy Policy",
  "client-dashboard-side-bar-menu-terms-of-use": "Terms of Use",
  "client-dashboard-side-bar-menu-disclaimers": "Disclaimers"
}
