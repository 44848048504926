import { getBusinessValue } from '../../Business';
import { useBusinessSummarySumTable } from '../hooks';
import { BusinessSummarySumTableProps } from '../types';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function BusinessSummarySumTable({ householdID, ...props }: BusinessSummarySumTableProps) {
  const { isLoading, tUI, businesses, totalBusinessValue } = useBusinessSummarySumTable({ householdID });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalBusinessValue}
      sumTitle={tUI('business-summary-sum-table-total-title')}
    >
      {businesses?.map((business) => (
        <AmountSumTableItem key={business?.id} title={business.name} amount={getBusinessValue(business)} />
      ))}
    </AmountSumTable>
  );
}
