import { ElementsIconProps } from '../components/Icon/types';

export function LiquidityIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="liquidity_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21.735C15.87 21.735 19 18.605 19 14.735C19 10.565 14.58 4.815 12.76 2.625C12.36 2.145 11.63 2.145 11.23 2.625C9.42 4.815 5 10.565 5 14.735C5 18.605 8.13 21.735 12 21.735Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_293"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="2"
          width="14"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21.735C15.87 21.735 19 18.605 19 14.735C19 10.565 14.58 4.815 12.76 2.625C12.36 2.145 11.63 2.145 11.23 2.625C9.42 4.815 5 10.565 5 14.735C5 18.605 8.13 21.735 12 21.735Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_293)"></g>
      </g>
    </svg>
  );
}
