import { FC, createContext, useContext } from 'react';

interface FullScreenScorecardContextProps {
  firmID: string;
  clientID: string;
  children?: React.ReactNode;
  householdID: string | undefined | null;
}

interface FullScreenScorecardContext extends Omit<FullScreenScorecardContextProps, 'children'> {}

const FullScreenScorecardContext = createContext<FullScreenScorecardContext | null>(null);

export const useFullScreenScorecardContext = () => {
  const context = useContext(FullScreenScorecardContext);

  if (!context) {
    throw new Error('Make sure to use the FullScreenScorecardContext inside of the provider');
  }
  return context;
};

export const FullScreenScorecardContextProvider: FC<FullScreenScorecardContextProps> = ({ children, ...props }) => {
  return <FullScreenScorecardContext.Provider value={props}>{children}</FullScreenScorecardContext.Provider>;
};
