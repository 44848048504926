import { ElementsIconProps } from '../components/Icon/types';

export function EducationIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="education_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 13.18V15.99C5 16.72 5.4 17.4 6.04 17.75L11.04 20.48C11.64 20.81 12.36 20.81 12.96 20.48L17.96 17.75C18.6 17.4 19 16.72 19 15.99V13.18L12.96 16.48C12.36 16.81 11.64 16.81 11.04 16.48L5 13.18ZM11.04 3.52L2.61 8.12C1.92 8.5 1.92 9.5 2.61 9.88L11.04 14.48C11.64 14.81 12.36 14.81 12.96 14.48L21 10.09V16C21 16.55 21.45 17 22 17C22.55 17 23 16.55 23 16V9.59C23 9.22 22.8 8.89 22.48 8.71L12.96 3.52C12.36 3.2 11.64 3.2 11.04 3.52Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
