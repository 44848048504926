import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateRecurringMonetaryAmountValueDataPointServiceInput,
  useCreateRecurringMonetaryAmountValueDataPointService,
} from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { scorecardKeys } from '../Scorecard/queryKeys';
import { DataPointGroupType } from '../generated/graphql';
import { useCreateIncomeSource, useUpdateIncomeSource } from './mutations';
import { incomeSourceKeys } from './queryKeys';
import {
  CreateIncomeSourceServiceArgs,
  UpdateIncomeSourceServiceArgs,
} from './types';

export const useUpdateIncomeSourceValueService = () => {
  const queryClient = useQueryClient();
  const { createRecurringMonetaryAmountValueDataPointService } =
    useCreateRecurringMonetaryAmountValueDataPointService();

  const updateIncomeSourceValueService = useMutation({
    mutationFn: async (
      input: CreateRecurringMonetaryAmountValueDataPointServiceInput
    ) => {
      await createRecurringMonetaryAmountValueDataPointService.mutateAsync(
        input,
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              incomeSourceKeys.incomeSources(input.tenantID)
            );
            queryClient.invalidateQueries(
              scorecardKeys.scorecard(input.tenantID)
            );
          },
        }
      );
    },
  });

  return { updateIncomeSourceValueService };
};

export const useUpdateIncomeSourceService = (
  householdID: string | undefined
) => {
  const { updateIncomeSource } = useUpdateIncomeSource();
  const { updateIncomeSourceValueService } =
    useUpdateIncomeSourceValueService();

  const updateIncomeSourceService = useMutation({
    mutationKey: ['updateIncomeSourceService'],
    mutationFn: async (input: UpdateIncomeSourceServiceArgs) => {
      if (!householdID) return;

      const { incomeSource } = await updateIncomeSource.mutateAsync({
        householdID,
        id: input.updateIncomeSourceInput.id,
        changes: input.updateIncomeSourceInput.changes,
        changeToken: input.updateIncomeSourceInput.changeToken,
      });

      await updateIncomeSourceValueService.mutateAsync(
        {
          tenantID: incomeSource.householdID,
          amountInCents: input.amountInCents,
          amountRecurringFrequency: input.amountRecurringFrequency,
          amountDataPointGroupID: incomeSource.amountDataPointGroup.id,
        },
        input.updateIncomeSourceValueServiceOptions
      );
    },
  });

  return {
    updateIncomeSourceService,
    updateIncomeSourceMutation: updateIncomeSource,
    updateIncomeSourceServiceMutation: updateIncomeSourceValueService,
  };
};

export const useCreateIncomeSourceService = () => {
  const { updateIncomeSourceValueService } =
    useUpdateIncomeSourceValueService();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createIncomeSource } = useCreateIncomeSource();

  const createIncomeSourceService = useMutation({
    mutationKey: ['createIncomeSourceService'],
    mutationFn: async (input: CreateIncomeSourceServiceArgs) => {
      const householdID = input.householdID;

      const { dataPointGroup } = await createDataPointGroup.mutateAsync({
        tenantID: householdID,
        dataPointGroup: {
          groupType: DataPointGroupType.RecurringMonetaryAmount,
        },
      });

      if (input.amountInCents && input.amountRecurringFrequency) {
        await updateIncomeSourceValueService.mutateAsync(
          {
            tenantID: householdID,
            amountInCents: input.amountInCents,
            amountDataPointGroupID: dataPointGroup.id,
            amountRecurringFrequency: input.amountRecurringFrequency,
          },
          input.updateIncomeSourceValueServiceOptions
        );
      }

      await createIncomeSource.mutateAsync({
        householdID,
        incomeSource: {
          ...input.incomeSource,
          amountDataPointGroupID: dataPointGroup.id,
        },
      });
    },
  });

  return {
    createIncomeSourceService,
    createIncomeSourceMutation: createIncomeSource,
    updateIncomeSourceValueServiceMutation: updateIncomeSourceValueService,
  };
};
