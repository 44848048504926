import { ElementsIconProps } from '../components/Icon/types';

export function GolfIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="golf_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 8.98V18.03C13.84 18.19 16 19.01 16 20C16 21.1 13.31 22 10 22C6.69 22 4 21.1 4 20C4 19.26 5.21 18.62 7 18.27V19C7 19.55 7.45 20 8 20C8.55 20 9 19.55 9 19V3.6C9 2.86 9.78 2.38 10.45 2.71L15.21 5.04C15.95 5.4 15.95 6.46 15.22 6.83L11 8.98ZM19.5 21C20.3284 21 21 20.3284 21 19.5C21 18.6716 20.3284 18 19.5 18C18.6716 18 18 18.6716 18 19.5C18 20.3284 18.6716 21 19.5 21Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
