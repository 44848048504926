import {
  ContributionsSumTable,
  ContributionsSumTableItem,
  ContributionsSumTableProps,
} from '../../UI';
import {
  UseCashSavingsContributionsSumTableArgs,
  useCashSavingsContributionsSumTable,
} from '../hooks';
import {
  displayCashAccountType,
  getCashAccountRecurringContributions,
  getCashAccountValue,
  getLastCashAccountUpdateDate,
} from '../util';

interface CashSavingsContributionsSumTableProps
  extends UseCashSavingsContributionsSumTableArgs,
    Partial<
      Pick<
        ContributionsSumTableProps,
        'title' | 'sumTitle' | 'hideOnEmpty'
      >
    > {
  className?: string;
}

export function CashSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  householdID,
  hideOnEmpty,
}: CashSavingsContributionsSumTableProps) {
  const {
    accounts,
    isLoading,
    tCashAccount,
    annualContributions,
    monthlyContributionsDisplay,
  } = useCashSavingsContributionsSumTable({
    householdID,
  });

  return (
    <ContributionsSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty}
      sum={annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      title={
        title ?? tCashAccount('cash-savings-contributions-sum-table-title')
      }
      sumTitle={
        sumTitle ??
        tCashAccount('cash-savings-contributions-sum-table-sum-title')
      }
    >
      {accounts.map((account) => (
        <ContributionsSumTableItem
          key={account.id}
          title={account.name}
          balance={getCashAccountValue(account)}
          lastBalanceUpdateISO={getLastCashAccountUpdateDate(account)}
          contributions={getCashAccountRecurringContributions(account)}
          subtitle={displayCashAccountType(
            account.cashAccountType,
            tCashAccount
          )}
        />
      ))}
    </ContributionsSumTable>
  );
}
