import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OwnerData } from '../../../../FirmClient';
import { Dropdown, DropdownProps } from './Dropdown';
import { DropdownOption } from './components';
import { OwnerType } from '../../../../generated/graphql';
import { usePossibleOwners } from './hooks';

export interface OwnerDropdownProps
  extends Pick<
    DropdownProps,
    'dropdownMenuClassName' | 'placeholder' | 'value' | 'onBlur' | 'name' | 'status' | 'label'
  > {
  clientID: string | undefined;
  firmID: string | undefined | null;
  ownerOptionsFilter?: (owner: OwnerData) => boolean;
  onChange?: (ownerData: { ownerID: string | null; ownerType: OwnerType | undefined }) => void;
}

export const OwnerDropdown = forwardRef<HTMLInputElement, OwnerDropdownProps>(
  (
    { clientID, firmID, placeholder, ownerOptionsFilter = () => true, value, onChange, label, ...dropdownProps },
    ref
  ) => {
    const { t: tForm } = useTranslation('form');
    const { t: tCommon } = useTranslation('common');
    const { personsQuery, businessesQuery, possibleOwners } = usePossibleOwners(clientID, firmID, ownerOptionsFilter);

    return (
      <Dropdown
        ref={ref}
        value={value}
        label={label ?? tForm('general-owner-dropdown-label')}
        retriggerSelectionFromValue={personsQuery.isSuccess && businessesQuery.isSuccess}
        placeholder={personsQuery.isLoading && businessesQuery.isLoading ? tCommon('loading') : placeholder}
        onChange={(value) => {
          onChange?.({
            ownerID: value,
            ownerType: possibleOwners.find((owner) => owner.id === value)?.type,
          });
        }}
        {...dropdownProps}
      >
        {possibleOwners.map((owner) => (
          <DropdownOption id={owner.id} key={owner.id} value={owner.id} label={owner.name} />
        ))}
      </Dropdown>
    );
  }
);
