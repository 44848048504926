import { ElementsIconProps } from '../components/Icon/types';

export function ReplayIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="replay_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9621 5.64249V2.85249C11.9621 2.40249 11.4221 2.18249 11.1121 2.50249L7.31208 6.29249C7.11208 6.49249 7.11208 6.80249 7.31208 7.00249L11.1021 10.7925C11.4221 11.1025 11.9621 10.8825 11.9621 10.4325V7.64249C15.6921 7.64249 18.6421 11.0625 17.8221 14.9325C17.3521 17.2025 15.5121 19.0325 13.2521 19.5025C9.68208 20.2525 6.50208 17.8025 6.02208 14.4925C5.95208 14.0125 5.53208 13.6425 5.04208 13.6425C4.44208 13.6425 3.96208 14.1725 4.04208 14.7725C4.66208 19.1625 8.84208 22.4125 13.5721 21.4925C16.6921 20.8825 19.2021 18.3725 19.8121 15.2525C20.8021 10.1225 16.9021 5.64249 11.9621 5.64249Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_500"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="2"
          width="16"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9621 5.64249V2.85249C11.9621 2.40249 11.4221 2.18249 11.1121 2.50249L7.31208 6.29249C7.11208 6.49249 7.11208 6.80249 7.31208 7.00249L11.1021 10.7925C11.4221 11.1025 11.9621 10.8825 11.9621 10.4325V7.64249C15.6921 7.64249 18.6421 11.0625 17.8221 14.9325C17.3521 17.2025 15.5121 19.0325 13.2521 19.5025C9.68208 20.2525 6.50208 17.8025 6.02208 14.4925C5.95208 14.0125 5.53208 13.6425 5.04208 13.6425C4.44208 13.6425 3.96208 14.1725 4.04208 14.7725C4.66208 19.1625 8.84208 22.4125 13.5721 21.4925C16.6921 20.8825 19.2021 18.3725 19.8121 15.2525C20.8021 10.1225 16.9021 5.64249 11.9621 5.64249Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_500)"></g>
      </g>
    </svg>
  );
}
