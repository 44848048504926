import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formRecurringMonetaryAmountValueInCents } from '../../UI';
import { useSubmitOnChange } from '../../UI/components/Form/hooks';
import { Loan } from '../../generated/graphql';
import { useUpdateLoanService } from '../service';
import { LoanFormDataTransformer, LoanFormValues } from '../types';
import { deriveMaturityDate, loanFormDefaultValues } from '../util';

export interface UseLoanFormArgs {
  loan?: Loan;
  saveOnChange?: boolean;
  defaultValues?: Partial<LoanFormValues>;
  dataTransformer?: LoanFormDataTransformer;
}

export const useLoanForm = ({
  loan,
  defaultValues,
  saveOnChange,
  dataTransformer,
}: UseLoanFormArgs) => {
  const { updateLoanService } = useUpdateLoanService();
  const methods = useForm<LoanFormValues>({
    defaultValues: loanFormDefaultValues(loan, defaultValues),
  });

  const onSubmit: SubmitHandler<LoanFormValues> = useCallback(
    (data) => {
      if (!loan) return;

      const transformedData = { ...data, ...dataTransformer?.(data, loan) };

      updateLoanService.mutate({
        debtPaymentsInCents: formRecurringMonetaryAmountValueInCents(
          transformedData.payments
        ),
        interestRateDecimal: transformedData.interestRate
          ? transformedData.interestRate / 100
          : null,
        loanBalanceInCents: transformedData.estimatedBalance * 100,
        debtPaymentsRecurringFrequency: transformedData.payments?.frequency,
        updateLoanInput: {
          id: loan.id,
          householdID: loan.householdID,
          changeToken: loan.changeToken,
          changes: {
            name: transformedData.nickname,
            loanType: transformedData.loanType,
            maturityDate: deriveMaturityDate(
              loan.originationDate,
              transformedData.lengthOfLoan?.length,
              transformedData.lengthOfLoan?.frequency
            ),
          },
        },
      });
    },
    [loan, dataTransformer]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: methods.watch,
    handleSubmit: methods.handleSubmit,
    enabled: saveOnChange && !!loan,
  });

  return { formMethods: methods, onSubmit };
};
