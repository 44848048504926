import { ElementsIconProps } from '../components/Icon/types';

export function PortraitIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="portrait_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.25C13.24 12.25 14.25 11.24 14.25 10C14.25 8.76 13.24 7.75 12 7.75C10.76 7.75 9.75 8.76 9.75 10C9.75 11.24 10.76 12.25 12 12.25ZM16.5 16.25C16.5 14.75 13.5 14 12 14C10.5 14 7.5 14.75 7.5 16.25V17H16.5V16.25ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18C19 18.55 18.55 19 18 19Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
