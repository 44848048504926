import { Outlet } from '@remix-run/react';
import { elementsColors } from '../../../util';
import { SideOver } from '../../components';
import { LocalThemeContextProvider } from '../../context';

export function LiquidTermElementDetailViewLayoutPage() {
  return (
    <SideOver className="!px-0">
      <LocalThemeContextProvider colorPrimary={elementsColors['Lt']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
