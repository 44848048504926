import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputProps,
  MonetaryInput,
  validateRecurringContributionInput,
} from '../../UI';
import { LoanFormValues } from '../types';
import { FC } from 'react';

type LoanPaymentsAmountInputProps = Pick<InputProps, 'className'>;

export const LoanPaymentsAmountInput: FC<LoanPaymentsAmountInputProps> = ({
  className,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<
    LoanFormValues,
    'payments.amount'
  >({
    control,
    name: 'payments.amount',
    rules: {
      validate: (amount, { payments: { frequency } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('loan-payments-amount-input-label')}
    />
  );
};
