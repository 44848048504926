import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { LoanType } from '../../generated/graphql';
import { LoanFormValues } from '../types';
import { displayLoanType } from '../util';

interface LoanTypeDropdownProps
  extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const LoanTypeDropdown: FC<LoanTypeDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tLoan } = useTranslation('loan');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<LoanFormValues, 'loanType'>({
    control,
    name: 'loanType',
    rules: { required: true },
  });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      label={tForm('loan-type-dropdown-label')}
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
    >
      <DropdownOption
        id={LoanType.Mortgage}
        value={LoanType.Mortgage}
        label={displayLoanType(LoanType.Mortgage, tLoan)}
      />
      <DropdownOption
        id={LoanType.CreditCard}
        value={LoanType.CreditCard}
        label={displayLoanType(LoanType.CreditCard, tLoan)}
      />
      <DropdownOption
        id={LoanType.AutoLoan}
        value={LoanType.AutoLoan}
        label={displayLoanType(LoanType.AutoLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.PersonalLoan}
        value={LoanType.PersonalLoan}
        label={displayLoanType(LoanType.PersonalLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.StudentLoan}
        value={LoanType.StudentLoan}
        label={displayLoanType(LoanType.StudentLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.HomeEquityLineOfCredit}
        value={LoanType.HomeEquityLineOfCredit}
        label={displayLoanType(LoanType.HomeEquityLineOfCredit, tLoan)}
      />
      <DropdownOption
        id={LoanType.HomeEquityLoan}
        value={LoanType.HomeEquityLoan}
        label={displayLoanType(LoanType.HomeEquityLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.OtherBusinessRealEstateLoan}
        value={LoanType.OtherBusinessRealEstateLoan}
        label={displayLoanType(LoanType.OtherBusinessRealEstateLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.BusinessLoan}
        value={LoanType.BusinessLoan}
        label={displayLoanType(LoanType.BusinessLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.OtherBusinessLoan}
        value={LoanType.OtherBusinessLoan}
        label={displayLoanType(LoanType.OtherBusinessLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.ReverseMortgage}
        value={LoanType.ReverseMortgage}
        label={displayLoanType(LoanType.ReverseMortgage, tLoan)}
      />
      <DropdownOption
        id={LoanType.InvestmentRealEstateLoan}
        value={LoanType.InvestmentRealEstateLoan}
        label={displayLoanType(LoanType.InvestmentRealEstateLoan, tLoan)}
      />
      <DropdownOption
        id={LoanType.SecuredLineOfCredit}
        value={LoanType.SecuredLineOfCredit}
        label={displayLoanType(LoanType.SecuredLineOfCredit, tLoan)}
      />
      <DropdownOption
        id={LoanType.UnsecuredLineOfCredit}
        value={LoanType.UnsecuredLineOfCredit}
        label={displayLoanType(LoanType.UnsecuredLineOfCredit, tLoan)}
      />
      <DropdownOption
        id={LoanType.OtherLoan}
        value={LoanType.OtherLoan}
        label={displayLoanType(LoanType.OtherLoan, tLoan)}
      />
    </Dropdown>
  );
};
