import { ElementsIconProps } from '../components/Icon/types';

export function WarningAmberIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="warning_amber_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.49375L19.53 18.5037H4.47L12 5.49375ZM2.74 17.5037C1.97 18.8337 2.93 20.5037 4.47 20.5037H19.53C21.07 20.5037 22.03 18.8337 21.26 17.5037L13.73 4.49375C12.96 3.16375 11.04 3.16375 10.27 4.49375L2.74 17.5037ZM11 10.5037V12.5037C11 13.0537 11.45 13.5037 12 13.5037C12.55 13.5037 13 13.0537 13 12.5037V10.5037C13 9.95375 12.55 9.50375 12 9.50375C11.45 9.50375 11 9.95375 11 10.5037ZM11 15.5037H13V17.5037H11V15.5037Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_391"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="20"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.49375L19.53 18.5037H4.47L12 5.49375ZM2.74 17.5037C1.97 18.8337 2.93 20.5037 4.47 20.5037H19.53C21.07 20.5037 22.03 18.8337 21.26 17.5037L13.73 4.49375C12.96 3.16375 11.04 3.16375 10.27 4.49375L2.74 17.5037ZM11 10.5037V12.5037C11 13.0537 11.45 13.5037 12 13.5037C12.55 13.5037 13 13.0537 13 12.5037V10.5037C13 9.95375 12.55 9.50375 12 9.50375C11.45 9.50375 11 9.95375 11 10.5037ZM11 15.5037H13V17.5037H11V15.5037Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_391)"></g>
      </g>
    </svg>
  );
}
