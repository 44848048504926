import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { getLastLoanUpdateDate, getLoanPaymentsAndExtraPayments } from '../util';
import { useLoanPaymentsSumTable, UseLoanPaymentsSumTableArgs } from '../hooks';
import { RecurringFrequency } from '../../generated/graphql';

interface LoanPaymentsSumTableProps extends UseLoanPaymentsSumTableArgs {
  className?: string;
}

export function LoanPaymentsSumTable({ className, ...props }: LoanPaymentsSumTableProps) {
  const {
    tLoan,
    isLoading,
    paymentLoans,
    totalAnnualPayments,
    totalMonthlyPaymentsDisplay,
    displayLoanAmountSumTableItemSubtitle,
  } = useLoanPaymentsSumTable(props);

  return (
    <AmountSumTable
      className={className}
      isLoading={isLoading}
      sum={totalAnnualPayments?.amount}
      sumAppendix={totalMonthlyPaymentsDisplay}
      title={tLoan('loan-payments-sum-table-title')}
      sumTitle={tLoan('loan-payments-sum-table-sum-title')}
    >
      {paymentLoans.map((loan) => (
        <AmountSumTableItem
          withChevron={false}
          key={loan.id}
          title={loan.name}
          lastUpdated={getLastLoanUpdateDate(loan)}
          amount={getLoanPaymentsAndExtraPayments(loan, {
            frequency: RecurringFrequency.Monthly,
          })}
          subtitle={displayLoanAmountSumTableItemSubtitle(loan)}
        />
      ))}
    </AmountSumTable>
  );
}
