import { ElementsIconProps } from '../components/Icon/types';

export function ExpandLessIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="expand_less_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.295 9L6.70498 13.59C6.31498 13.98 6.31498 14.61 6.70498 15C7.09498 15.39 7.72498 15.39 8.11498 15L12.005 11.12L15.885 15C16.275 15.39 16.905 15.39 17.295 15C17.685 14.61 17.685 13.98 17.295 13.59L12.705 9C12.325 8.61 11.685 8.61 11.295 9Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_972"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="8"
          width="12"
          height="8"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.295 9L6.70498 13.59C6.31498 13.98 6.31498 14.61 6.70498 15C7.09498 15.39 7.72498 15.39 8.11498 15L12.005 11.12L15.885 15C16.275 15.39 16.905 15.39 17.295 15C17.685 14.61 17.685 13.98 17.295 13.59L12.705 9C12.325 8.61 11.685 8.61 11.295 9Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_972)"></g>
      </g>
    </svg>
  );
}
