import { ElementsIconProps } from '../components/Icon/types';

export function ReplyIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1008_800)">
        <path
          d="M11.1299 19.0781C11.335 19.0781 11.4932 19.0107 11.6045 18.876C11.7158 18.7412 11.7715 18.5771 11.7715 18.3838V14.6836H12.1406C13.4824 14.6836 14.6484 14.8125 15.6387 15.0703C16.6348 15.3281 17.4756 15.7412 18.1611 16.3096C18.8467 16.8779 19.3975 17.6279 19.8135 18.5596C19.9014 18.7588 19.998 18.8936 20.1035 18.9639C20.209 19.04 20.3379 19.0781 20.4902 19.0781C20.6543 19.0781 20.792 19.0137 20.9033 18.8848C21.0205 18.7559 21.0791 18.5801 21.0791 18.3574C21.0791 16.6055 20.8711 15.0938 20.4551 13.8223C20.0449 12.5449 19.4502 11.4932 18.6709 10.667C17.8975 9.84082 16.96 9.22852 15.8584 8.83008C14.7568 8.42578 13.5176 8.22363 12.1406 8.22363H11.7715V4.45312C11.7715 4.25977 11.7158 4.0957 11.6045 3.96094C11.4932 3.82031 11.332 3.75 11.1211 3.75C10.9805 3.75 10.8516 3.78223 10.7344 3.84668C10.6172 3.91113 10.5029 3.99902 10.3916 4.11035L3.50098 10.5176C3.28418 10.7168 3.14648 10.8867 3.08789 11.0273C3.0293 11.1621 3 11.291 3 11.4141C3 11.5371 3.0293 11.6689 3.08789 11.8096C3.15234 11.9443 3.29004 12.1113 3.50098 12.3105L10.3916 18.7266C10.4971 18.832 10.6084 18.917 10.7256 18.9814C10.8486 19.0459 10.9834 19.0781 11.1299 19.0781Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1008_800">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
