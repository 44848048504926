import { ElementsIconProps } from '../components/Icon/types';

export const CheckIcon = (props: ElementsIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Selected=No">
      <path
        id="Color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.795 15.875L5.325 12.405C5.13817 12.2177 4.88452 12.1125 4.62 12.1125C4.35548 12.1125 4.10183 12.2177 3.915 12.405C3.525 12.795 3.525 13.425 3.915 13.815L8.095 17.995C8.485 18.385 9.115 18.385 9.505 17.995L20.085 7.41498C20.475 7.02498 20.475 6.39498 20.085 6.00498C19.8982 5.81773 19.6445 5.71249 19.38 5.71249C19.1155 5.71249 18.8618 5.81773 18.675 6.00498L8.795 15.875Z"
        fill="currentColor"
      />
      <mask
        id="mask0_0_951"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="18"
        height="14"
      >
        <path
          id="Color_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.795 15.875L5.325 12.405C5.13817 12.2177 4.88452 12.1125 4.62 12.1125C4.35548 12.1125 4.10183 12.2177 3.915 12.405C3.525 12.795 3.525 13.425 3.915 13.815L8.095 17.995C8.485 18.385 9.115 18.385 9.505 17.995L20.085 7.41498C20.475 7.02498 20.475 6.39498 20.085 6.00498C19.8982 5.81773 19.6445 5.71249 19.38 5.71249C19.1155 5.71249 18.8618 5.81773 18.675 6.00498L8.795 15.875Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_951)"></g>
    </g>
  </svg>
);
