import { useElementDetailViewIndexPage, UseElementDetailViewIndexPageArgs } from '../../shared';
export interface UseLiquidTermElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useLiquidTermElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseLiquidTermElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
