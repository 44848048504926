import { BusinessEquityDetails, BusinessEquitySummaryProportionChart } from '../../../BtDetails';
import { PrivateStockSumTable } from '../../../Investment';
import { ScorecardElementCalculationGroup } from '../../../Scorecard';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  ClientBusinessTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
} from '../../components';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { UseBusinessTermElementDetailViewIndexPageArgs, useBusinessTermElementDetailViewIndexPage } from './hooks';

interface BusinessTermElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    UseBusinessTermElementDetailViewIndexPageArgs,
    ElementDetailViewIndexPageCommonProps {}

export function BusinessTermElementDetailViewIndexPage({
  firmID,
  firmClientID,
  showScoreHealthRange,
  downloadReportButton,
}: BusinessTermElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID } = useBusinessTermElementDetailViewIndexPage({
    firmClientID,
    firmID,
  });

  if (!householdID || !firmClientID || !firmID) return;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('business-term')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('business-term-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup className="mb-12" element="Bt" householdID={householdID} />
      {showScoreHealthRange && <ClientBusinessTermScoreHealthRange firmID={firmID} firmClientID={firmClientID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('business-term-element-detail-view-learn-more-body')}
        label={tPages('business-term-element-detail-view-learn-more-label')}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <BusinessEquityDetails householdID={householdID} />
      <PrivateStockSumTable householdID={householdID} />
      <BusinessEquitySummaryProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
