import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseInvestmentRealEstateSummarySumTableArgs {
  householdID: string;
}

export function useInvestmentRealEstateSummarySumTable({ householdID }: UseInvestmentRealEstateSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tRealEstate } = useTranslation('realEstate');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalInvestmentRealEstate = ttDetails?.assetSummary.totalInvestmentRealEstateValue;
  const investmentRealEstateProperties = ttDetails?.assetSummary.investmentRealEstate.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return { investmentRealEstateProperties, totalInvestmentRealEstate, tUI, isLoading, tRealEstate };
}
