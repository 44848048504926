import clsx from 'clsx';
import { InputSize, InputStatus } from './types';

function inputSizeClasses(inputSize: undefined | null | InputSize) {
  switch (inputSize) {
    case 'small':
      return clsx(
        '[&_.ElementsInput-leading]:mr-1',
        '[&_.ElementsInput-trailing]:ml-1',
        '[&_.ElementsInput-container]:px-[6px] [&_.ElementsInput-container]:pt-[3px] [&_.ElementsInput-container]:pb-[3px]',
        '[&_.ElementsInput-input]:text-sm [&_.ElementsInput-input]:placeholder-shown:text-sm'
      );
    case 'default':
    default:
      return clsx(
        '[&_.ElementsInput-leading]:mr-2',
        '[&_.ElementsInput-trailing]:ml-2',
        '[&_.ElementsInput-container]:px-4 [&_.ElementsInput-container]:py-2 [&_.ElementsInput-container]:min-h-[64px]',
        '[&_.ElementsInput-input]:text-lg [&_.ElementsInput-input]:placeholder-shown:text-lg'
      );
  }
}

function inputStatusClasses(inputStatus: undefined | null | InputStatus) {
  switch (inputStatus) {
    case 'errored':
      return clsx(
        '[&_.ElementsInput-container]:!bg-darkMode-danger/10 [&_.ElementsInput-container]:!text-darkMode-danger',
        '[&_.ElementsInput-helperText]:!text-darkMode-danger',
        '[&_.ElementsInput-label]:!text-darkMode-danger',
        '[&_.ElementsInput-leading]:!text-darkMode-danger [&_.ElementsInput-leading_svg]:!text-darkMode-danger',
        '[&_.ElementsInput-trailing]:!text-darkMode-danger [&_.ElementsInput-trailing_svg]:!text-darkMode-danger',
        '[&_.ElementsInput-input]:!placeholder-darkMode-danger/50',
        '[&_.InputInlineToggle-chosenPill]:!bg-darkMode-danger'
      );
    case 'disabled':
      return clsx(
        '[&_.ElementsInput-container]:opacity-40 [&_.ElementsInput-container]:grayscale',
        '[&_.ElementsInput-helperText]:!opacity-40'
      );
    case 'normal':
      return clsx(
        '[&_.ElementsInput-container]:focus-within:!bg-primary/[0.08] [&_.ElementsInput-container]:focus-within:caret-primary',
        '[&_.ElementsInput-label]:focus-within:text-primary'
      );
    default:
      return '';
  }
}

export const elementsInputClasses = {
  container:
    'ElementsInput-container selection:bg-primary/20 flex items-center rounded-md bg-darkMode-gray-light/10 shadow-sm dark:bg-darkMode-gray-medium/[0.16]',
  leading: 'ElementsInput-leading',
  trailing: 'ElementsInput-trailing',
  label:
    'ElementsInput-label block flex-1 text-sm font-medium text-darkMode-gray-medium/70 dark:text-darkMode-gray-medium',
  helperText:
    'whitespace-pre-line ElementsInput-helperText ml-4 mt-[3.21px] text-sm !leading-normal text-darkMode-gray-medium',
  input:
    'ElementsInput-input block w-full border-0 bg-transparent p-0 font-normal text-darkMode-almostBlack placeholder-darkMode-gray-medium/50 dark:text-white dark:placeholder-[#3B4044]',
  status: inputStatusClasses,
  size: inputSizeClasses,
};

export const passwordRegex = new RegExp(
  /^(((?!.*[\s])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{10,}))|((?!.*[\s])(.{20,}))$/
);

export const phoneRegex = new RegExp(
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
);
