import { SubmitHandler, useForm } from 'react-hook-form';
import {
  TargetScoreRangeTemplateFormSubmitData,
  TargetScoreRangesFormValues,
  targetScoreRangesFormDefaultValues,
  useTargetScoreRangeTemplate,
} from '..';
import { useCallback } from 'react';
import { useFormMemoDefaultValues, useSetFormDefaultValues } from '../../UI';

export interface UseTargetScoreRangeTemplateFormArgs {
  firmID: string;
  templateID: string;
  defaultValuesOverrides?: Partial<TargetScoreRangesFormValues>;
  handleSubmit?: (data: TargetScoreRangeTemplateFormSubmitData) => void;
}

/**
 *
 * @param templateID Default value: FIRM_DEFAULTS_TARGET_SCORE_RANGE_TEMPLATE_ID
 * @returns
 */
export function useTargetScoreRangeTemplateForm({
  firmID,
  templateID,
  handleSubmit,
  defaultValuesOverrides,
}: UseTargetScoreRangeTemplateFormArgs) {
  const formMethods = useForm<TargetScoreRangesFormValues>();

  const { targetScoreRangeTemplate, targetScoreRangeTemplateQuery } = useTargetScoreRangeTemplate({
    firmID,
    id: templateID,
  });
  const defaultValues = useFormMemoDefaultValues(
    targetScoreRangesFormDefaultValues(targetScoreRangeTemplate?.derivedRanges, defaultValuesOverrides)
  );

  const isLoadingFormData = targetScoreRangeTemplateQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const innerOnSubmit: SubmitHandler<TargetScoreRangesFormValues> = useCallback(
    (formValues) => {
      handleSubmit?.({ firmID, formValues, templateID });
    },
    [handleSubmit, firmID, templateID]
  );

  return {
    formMethods,
    onSubmit: innerOnSubmit,
    formReady,
    isLoadingFormData,
    targetScoreRanges: targetScoreRangeTemplate?.derivedRanges,
    targetScoreRangeTemplate,
  };
}
