import { ButtonTheme } from '../types';
import { useContrastColorSwitcher } from '../../../hooks';
import { useLocalThemeContext } from '../../../context';

export function useButtonContrastColorSwitcher(theme?: ButtonTheme) {
  const { colorPrimary } = useLocalThemeContext();

  const foregroundColor = useContrastColorSwitcher(colorPrimary, {
    enabled: theme === 'primary',
  });

  return foregroundColor;
}
