import { ElementsIconProps } from '../components/Icon/types';

export function BankInstitutionIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bank_institution_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 11.2425V15.2425C4.5 16.0725 5.17 16.7425 6 16.7425C6.83 16.7425 7.5 16.0725 7.5 15.2425V11.2425C7.5 10.4125 6.83 9.7425 6 9.7425C5.17 9.7425 4.5 10.4125 4.5 11.2425ZM10.5 11.2425V15.2425C10.5 16.0725 11.17 16.7425 12 16.7425C12.83 16.7425 13.5 16.0725 13.5 15.2425V11.2425C13.5 10.4125 12.83 9.7425 12 9.7425C11.17 9.7425 10.5 10.4125 10.5 11.2425ZM4 21.7425H20C20.83 21.7425 21.5 21.0725 21.5 20.2425C21.5 19.4125 20.83 18.7425 20 18.7425H4C3.17 18.7425 2.5 19.4125 2.5 20.2425C2.5 21.0725 3.17 21.7425 4 21.7425ZM16.5 11.2425V15.2425C16.5 16.0725 17.17 16.7425 18 16.7425C18.83 16.7425 19.5 16.0725 19.5 15.2425V11.2425C19.5 10.4125 18.83 9.7425 18 9.7425C17.17 9.7425 16.5 10.4125 16.5 11.2425ZM11.07 1.2325L3.17 5.3925C2.76 5.6025 2.5 6.0325 2.5 6.4925C2.5 7.1825 3.06 7.7425 3.75 7.7425H20.26C20.94 7.7425 21.5 7.1825 21.5 6.4925C21.5 6.0325 21.24 5.6025 20.83 5.3925L12.93 1.2325C12.35 0.9225 11.65 0.9225 11.07 1.2325Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
