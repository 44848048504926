import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetLoans, useGraphqlClient } from '../graphql';
import { loanKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { LoansQueryArgs } from './types';

const loansQuery = (
  gqlClient: GraphQLClient,
  { householdID }: LoansQueryArgs
) => ({
  enabled: !!householdID,
  queryKey: loanKeys.loans(householdID),
  queryFn: () => {
    if (householdID) return clientGetLoans(gqlClient, { householdID });
  },
});

export function usePrefetchLoans() {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  async function prefetchLoans(args: LoansQueryArgs) {
    return await queryClient.refetchQueries(loansQuery(gqlClient, args));
  }

  return { prefetchLoans };
}

export const useLoans = (args: LoansQueryArgs) => {
  const query = useQuery(loansQuery(useGraphqlClient(), args));

  return { loansQuery: query, loans: query.data?.items || [] };
};
