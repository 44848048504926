import { ElementsIconProps } from '../components/Icon/types';

export function MergeBoxesIcon(props: ElementsIconProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="merge_boxes_24px">
        <g id="Group">
          <path
            id="Path"
            d="M19.4444 2.75H8.55556C8.11421 2.75 7.75 3.11421 7.75 3.55556V14.4444C7.75 14.8858 8.11421 15.25 8.55556 15.25H19.4444C19.8858 15.25 20.25 14.8858 20.25 14.4444V3.55556C20.25 3.11421 19.8858 2.75 19.4444 2.75Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M14.4444 7.75H3.55556C3.11421 7.75 2.75 8.11421 2.75 8.55556V19.4444C2.75 19.8858 3.11421 20.25 3.55556 20.25H14.4444C14.8858 20.25 15.25 19.8858 15.25 19.4444V8.55556C15.25 8.11421 14.8858 7.75 14.4444 7.75Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <rect
            id="Rectangle"
            width="7"
            height="7"
            transform="matrix(-1 0 0 1 15 8)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
