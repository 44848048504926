import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateLoanInput,
  DeleteLoanInput,
  Mutation,
  Query,
  QueryLoansArgs,
  UpdateLoanInput,
} from '../generated/graphql';
import { LoanFragment } from './fragments';

const loansQuery = gql`
  ${LoanFragment}
  query Loans($householdID: ID!, $cursor: String) {
    loans(householdID: $householdID, cursor: $cursor) {
      items {
        ...LoanFragment
      }
    }
  }
`;

export const clientGetLoans = async (
  client: GraphQLClient,
  args: QueryLoansArgs
) => {
  return (await client.request<Pick<Query, 'loans'>>(loansQuery, args)).loans;
};

const createLoanMutation = gql`
  ${LoanFragment}
  mutation createLoan($input: CreateLoanInput!) {
    createLoan(input: $input) {
      loan {
        ...LoanFragment
      }
    }
  }
`;

export const clientCreateLoan = async (
  client: GraphQLClient,
  input: CreateLoanInput
) => {
  const { createLoan } = await client.request<Pick<Mutation, 'createLoan'>>(
    createLoanMutation,
    { input }
  );
  return createLoan;
};

const deleteLoanMutation = gql`
  mutation DeleteLoan($input: DeleteLoanInput!) {
    deleteLoan(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteLoan = async (
  client: GraphQLClient,
  input: DeleteLoanInput
) => {
  const { deleteLoan } = await client.request<Pick<Mutation, 'deleteLoan'>>(
    deleteLoanMutation,
    { input }
  );
  return deleteLoan;
};

const updateLoanMutation = gql`
  ${LoanFragment}
  mutation UpdateLoan($input: UpdateLoanInput!) {
    updateLoan(input: $input) {
      loan {
        ...LoanFragment
      }
    }
  }
`;

export const clientUpdateLoan = async (
  client: GraphQLClient,
  input: UpdateLoanInput
) => {
  const { updateLoan } = await client.request<Pick<Mutation, 'updateLoan'>>(
    updateLoanMutation,
    { input }
  );
  return updateLoan;
};
