import { ChartGradient } from '../types';
import { Color } from 'echarts/core';

const elementsGradientBuilder = (colorAt0: string, colorAt100: string): Color => ({
  type: 'linear',
  x: 0,
  y: 0,
  x2: 1,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: colorAt0, // color at 0%
    },
    {
      offset: 1,
      color: colorAt100, // color at 0%
    },
  ],
});

export const elementGradientMap = new Map<ChartGradient, Color>([
  ['Ir', elementsGradientBuilder('#169FFF', '#0057A8')],
  ['Tr', elementsGradientBuilder('#FFBE00', '#FF8C0E')],
  ['Lt', elementsGradientBuilder('#FF8C0E', '#FF4F03')],
  ['Bt', elementsGradientBuilder('#FF144F', '#A8005B')],
  ['Qt', elementsGradientBuilder('#8B31F1', '#5B00C1')],
  ['Rt', elementsGradientBuilder('#284ECD', '#0029B3')],
  ['Sr', elementsGradientBuilder('#31948F', '#005662')],
  ['Dr', elementsGradientBuilder('#016E42', '#004607')],
  ['Er', elementsGradientBuilder('#16A104', '#005F3D')],
  ['Tt', elementsGradientBuilder('#93CE00', '#4F8D00')],
  ['netWorth', elementsGradientBuilder('#00F58D', '#8B49EC')],
  ['netWorth-negative', elementsGradientBuilder('#8B49EC', '#C036DD')],
  ['account', elementsGradientBuilder('#4AF196', '#007564')],
  ['account-negative', elementsGradientBuilder('#007564', '#4AF196')],
]);
