import { ElementsIconProps } from '../components/Icon/types';

export function AirplayIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="airplay_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.41 21.5H15.58C16.47 21.5 16.92 20.42 16.29 19.79L12.7 16.2C12.5132 16.0127 12.2595 15.9075 11.995 15.9075C11.7305 15.9075 11.4768 16.0127 11.29 16.2L7.7 19.79C7.08 20.42 7.52 21.5 8.41 21.5ZM21 2.5H3C1.9 2.5 1 3.4 1 4.5V16.5C1 17.6 1.9 18.5 3 18.5H6C6.55 18.5 7 18.05 7 17.5C7 16.95 6.55 16.5 6 16.5H4C3.45 16.5 3 16.05 3 15.5V5.5C3 4.95 3.45 4.5 4 4.5H20C20.55 4.5 21 4.95 21 5.5V15.5C21 16.05 20.55 16.5 20 16.5H18C17.45 16.5 17 16.95 17 17.5C17 18.05 17.45 18.5 18 18.5H21C22.1 18.5 23 17.6 23 16.5V4.5C23 3.4 22.1 2.5 21 2.5Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_409"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="2"
          width="22"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.41 21.5H15.58C16.47 21.5 16.92 20.42 16.29 19.79L12.7 16.2C12.5132 16.0127 12.2595 15.9075 11.995 15.9075C11.7305 15.9075 11.4768 16.0127 11.29 16.2L7.7 19.79C7.08 20.42 7.52 21.5 8.41 21.5ZM21 2.5H3C1.9 2.5 1 3.4 1 4.5V16.5C1 17.6 1.9 18.5 3 18.5H6C6.55 18.5 7 18.05 7 17.5C7 16.95 6.55 16.5 6 16.5H4C3.45 16.5 3 16.05 3 15.5V5.5C3 4.95 3.45 4.5 4 4.5H20C20.55 4.5 21 4.95 21 5.5V15.5C21 16.05 20.55 16.5 20 16.5H18C17.45 16.5 17 16.95 17 17.5C17 18.05 17.45 18.5 18 18.5H21C22.1 18.5 23 17.6 23 16.5V4.5C23 3.4 22.1 2.5 21 2.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_409)"></g>
      </g>
    </svg>
  );
}
