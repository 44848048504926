import { ElementsIconProps } from '../components/Icon/types';

export function CalculatorIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="calculator_24px">
        <g id="calculator">
          <path
            id="Mask"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2H17C18.1046 2 19 2.89543 19 4V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V4C5 2.89543 5.89543 2 7 2ZM7 4V8H17V4H7ZM7 10V12H9V10H7ZM11 10V12H13V10H11ZM15 10V12H17V10H15ZM7 14V16H9V14H7ZM11 14V16H13V14H11ZM15 14V16H17V14H15ZM7 18V20H9V18H7ZM11 18V20H13V18H11ZM15 18V20H17V18H15Z"
            fill="currentColor"
          />
          <mask
            id="mask0_0_688"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="2"
            width="14"
            height="20"
          >
            <path
              id="Mask_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 2H17C18.1046 2 19 2.89543 19 4V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V4C5 2.89543 5.89543 2 7 2ZM7 4V8H17V4H7ZM7 10V12H9V10H7ZM11 10V12H13V10H11ZM15 10V12H17V10H15ZM7 14V16H9V14H7ZM11 14V16H13V14H11ZM15 14V16H17V14H15ZM7 18V20H9V18H7ZM11 18V20H13V18H11ZM15 18V20H17V18H15Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_0_688)"></g>
        </g>
      </g>
    </svg>
  );
}
