import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC, useRef } from 'react';
import { Icon } from '../Icon';
import { ButtonTheme } from './types';
import { elementsButtonClasses } from './util';
import { IconName } from '../Icon/types';
import { useButtonContrastColorSwitcher } from './hooks';
import { ClientOnly } from 'remix-utils/client-only';
import { useHoverDirty } from 'react-use';

export interface IconButtonProps
  extends Pick<ComponentPropsWithoutRef<'button'>, 'onClick' | 'type' | 'className' | 'disabled' | 'style'> {
  isLoading?: boolean;
  icon: JSX.Element | IconName;
  iconClassName?: string;
  diameter?: number;
  theme?: ButtonTheme;
  hoverTheme?: ButtonTheme;
}

const IconButtonInner: FC<IconButtonProps> = ({
  type = 'button',
  diameter = 40,
  icon,
  iconClassName,
  className,
  theme,
  isLoading,
  hoverTheme,
  disabled,
  style,
  onClick,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hovered = useHoverDirty(buttonRef);

  const runtimeTheme = hovered && hoverTheme ? hoverTheme : theme;
  const foregroundColor = useButtonContrastColorSwitcher(runtimeTheme);

  return (
    <button
      disabled={disabled}
      ref={buttonRef}
      style={{
        height: diameter,
        width: diameter,
        ...style,
      }}
      type={type}
      onClick={(e) => {
        if (isLoading || disabled) return e.preventDefault();
        onClick?.(e);
      }}
      className={clsx(
        'p-0',
        className,
        elementsButtonClasses.base({ disabled }),
        elementsButtonClasses.theme(runtimeTheme)
      )}
    >
      <div className={clsx('contents IconButton-icon', elementsButtonClasses.iconTheme(runtimeTheme))}>
        {isLoading ? (
          <Icon style={{ color: foregroundColor }} icon="spinner" />
        ) : typeof icon === 'string' ? (
          <Icon style={{ color: foregroundColor }} icon={icon} className={iconClassName} />
        ) : (
          icon
        )}
      </div>
    </button>
  );
};

export const IconButton: FC<IconButtonProps> = (props) => (
  <ClientOnly>{() => <IconButtonInner {...props} />}</ClientOnly>
);
