import { useCallback } from 'react';
import { useRealEstateProperty } from '../queries';
import { SubmitHandler, useForm } from 'react-hook-form';
import { realEstatePropertyFormDefaultValues } from '../util';
import { RealEstateFormSubmitData, RealEstateFormValues } from '../types';
import { useFormMemoDefaultValues, useSetFormDefaultValues, useSubmitOnChange } from '../../UI/components/Form/hooks';

export interface UseRealEstateFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  realEstatePropertyID?: string | null;
  defaultValueOverrides?: Partial<RealEstateFormValues>;
  handleSubmit?: (data: RealEstateFormSubmitData) => void;
}

export const useRealEstatePropertyForm = ({
  householdID,
  handleSubmit,
  saveOnChange,
  defaultValueOverrides,
  realEstatePropertyID,
}: UseRealEstateFormArgs) => {
  const formMethods = useForm<RealEstateFormValues>();
  const { realEstateProperty, realEstatePropertyQuery } = useRealEstateProperty({ realEstatePropertyID, householdID });
  const defaultValues = useFormMemoDefaultValues(
    realEstatePropertyFormDefaultValues(realEstateProperty, defaultValueOverrides)
  );

  const isLoadingFormData = realEstatePropertyQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const onSubmit: SubmitHandler<RealEstateFormValues> = useCallback(
    (data) => {
      handleSubmit?.({
        householdID,
        formValues: data,
        realEstatePropertyID,
        changeToken: realEstateProperty?.changeToken,
      });
    },
    [handleSubmit, realEstateProperty?.changeToken, realEstatePropertyID, householdID]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!realEstateProperty,
  });

  return { formMethods, onSubmit, householdID, realEstateProperty, formReady };
};
