import { UseElementDetailViewIndexPageArgs, useElementDetailViewIndexPage } from '../../shared';

export interface UseSavingsRateElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useSavingsRateElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseSavingsRateElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
