import { isLight } from '../../../../util';
import { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { SidebarDivider } from './SidebarDivider';
import { SidebarElementsCube } from './SidebarElementsCube';
import { toast as toastAPI } from 'react-hot-toast';
import { SidebarHelpSupportNotification } from './SidebarHelpSupportNotification';

export type SidebarProps<TMenuItem> = {
  firmColor?: string;
  onItemSelected?: (option: TMenuItem) => boolean | Promise<boolean>;
  defaultSelected: TMenuItem;
  avatar?: JSX.Element;
  menuItems: ({
    selected,
    color,
    setSelected,
  }: {
    selected: TMenuItem;
    color: string;
    setSelected: Dispatch<SetStateAction<TMenuItem>>;
  }) => JSX.Element[];
};

export const Sidebar = <TMenuItem,>({
  firmColor = '#fff',
  defaultSelected,
  menuItems,
  avatar,
}: SidebarProps<TMenuItem>): JSX.Element => {
  const [selected, setSelected] = useState<TMenuItem>(defaultSelected);
  const isLightColor = isLight(firmColor);
  const isDarkColor = !isLightColor;

  return (
    <aside
      className={clsx('w-[88px] h-full')}
      aria-label={'Sidebar'}
      style={{
        backgroundColor: firmColor,
      }}
    >
      <div className={`h-full py-6 overflow-y-auto flex flex-col justify-start items-center`}>
        <div className={clsx('flex flex-row w-full h-[72px] justify-center items-center mb-10 py-2')}>{avatar}</div>

        {menuItems({ selected, color: firmColor, setSelected })}

        {/* Bottom group */}
        <div className={'w-full flex flex-col justify-center items-center mt-auto'}>
          {/* Help */}
          <div
            className={'w-full cursor-pointer flex flex-row justify-center items-center mb-[18px]'}
            onClick={() => toastAPI.error('TODO: implement help menu action')}
          >
            <div
              className={clsx(
                `w-[32px] h-[32px] rounded-3xl`,
                isDarkColor && 'bg-darkMode-gray-dark mix-blend-plus-lighter',
                isLightColor && 'bg-darkMode-gray-light mix-blend-multiply'
              )}
            >
              <SidebarHelpSupportNotification className={clsx('text-white')} />
            </div>
          </div>
          <SidebarDivider className={`mb-6`} />
          <SidebarElementsCube className={clsx(isLightColor ? 'mix-blend-multiply' : 'mix-blend-plus-lighter')} />
        </div>
      </div>
    </aside>
  );
};
