import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UsePersonalRealEstateSummarySumTableArgs {
  householdID: string;
}

export function usePersonalRealEstateSummarySumTable({ householdID }: UsePersonalRealEstateSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tRealEstate } = useTranslation('realEstate');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalPersonalRealEstate = ttDetails?.assetSummary.totalPersonalRealEstateValue;
  const personalRealEstateProperties = ttDetails?.assetSummary.personalRealEstate.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return { personalRealEstateProperties, totalPersonalRealEstate, tUI, isLoading, tRealEstate };
}
