import { ElementsIconProps } from '../components/Icon/types';

export function LastPageIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="last_page_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29128 8.11L10.1813 12L6.29128 15.89C5.90128 16.28 5.90128 16.91 6.29128 17.3C6.68128 17.69 7.31128 17.69 7.70128 17.3L12.2913 12.71C12.6813 12.32 12.6813 11.69 12.2913 11.3L7.70128 6.7C7.51445 6.51275 7.2608 6.40751 6.99628 6.40751C6.73176 6.40751 6.47811 6.51275 6.29128 6.7C5.91128 7.09 5.91128 7.73 6.29128 8.11ZM17.0013 6C17.5513 6 18.0013 6.45 18.0013 7V17C18.0013 17.55 17.5513 18 17.0013 18C16.4513 18 16.0013 17.55 16.0013 17V7C16.0013 6.45 16.4513 6 17.0013 6Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_988"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="6"
          width="14"
          height="12"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.29128 8.11L10.1813 12L6.29128 15.89C5.90128 16.28 5.90128 16.91 6.29128 17.3C6.68128 17.69 7.31128 17.69 7.70128 17.3L12.2913 12.71C12.6813 12.32 12.6813 11.69 12.2913 11.3L7.70128 6.7C7.51445 6.51275 7.2608 6.40751 6.99628 6.40751C6.73176 6.40751 6.47811 6.51275 6.29128 6.7C5.91128 7.09 5.91128 7.73 6.29128 8.11ZM17.0013 6C17.5513 6 18.0013 6.45 18.0013 7V17C18.0013 17.55 17.5513 18 17.0013 18C16.4513 18 16.0013 17.55 16.0013 17V7C16.0013 6.45 16.4513 6 17.0013 6Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_988)"></g>
      </g>
    </svg>
  );
}
