import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseBusinessDebtSummarySumTableArgs {
  householdID: string;
}

export function useBusinessDebtSummarySumTable({ householdID }: UseBusinessDebtSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalBusinessDebt = ttDetails?.debtSummary.totalBusinessLoans;
  const businessDebt = ttDetails?.debtSummary.businessLoans.sort((a, b) => a.name.localeCompare(b.name));

  return { businessDebt, totalBusinessDebt, tUI, isLoading, tLoan };
}
