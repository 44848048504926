import { usePersonalRealEstateLoansSummarySumTable } from '../hooks';
import { PersonalRealEstateLoansSummarySumTableProps } from '../types';
import { displayLoanType, getLoanBalance } from '../../Loan';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function PersonalRealEstateLoansSummarySumTable({
  householdID,
  ...props
}: PersonalRealEstateLoansSummarySumTableProps) {
  const { isLoading, tUI, personalRealEstateLoans, tLoan, totalPersonalRealEstateLoans } =
    usePersonalRealEstateLoansSummarySumTable({
      householdID,
    });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalPersonalRealEstateLoans}
      sumTitle={tUI('personal-real-estate-loans-summary-sum-table-total-title')}
    >
      {personalRealEstateLoans?.map((loan) => (
        <AmountSumTableItem
          key={loan?.id}
          title={loan.name}
          amount={getLoanBalance(loan)}
          subtitle={displayLoanType(loan.loanType, tLoan)}
        />
      ))}
    </AmountSumTable>
  );
}
