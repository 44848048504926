import { InvestmentType } from '../generated/graphql';

export const INVESTMENT_VALUE_DECAY_LIMIT_IN_MONTHS = 3;

export const TAX_FREE_QUALIFIED_INVESTMENT_TYPES = [
  InvestmentType.RothIndividualRetirementAccount,
  InvestmentType.RothSubsection_401KAccount,
];

export const TAX_DEFERRED_QUALIFIED_INVESTMENT_TYPES = [
  InvestmentType.IndividualRetirementAccount,
  InvestmentType.Subsection_401KAccount,
  InvestmentType.SimpleIndividualRetirementAccount,
  InvestmentType.SimplifiedEmployeePensionAccount,
  InvestmentType.ProfitSharingAccount,
  InvestmentType.TaxShelteredAnnuity_403BAccount,
];

export const OTHER_QUALIFIED_INVESTMENT_TYPES = [
  InvestmentType.Annuity,
  InvestmentType.QualifiedTuitionPlan_529,
  InvestmentType.HealthSavingsAccount,
  InvestmentType.MinorIndividualRetirementAccount,
  InvestmentType.MinorRothIndividualRetirementAccount,
];

export const AFTER_TAX_SAVINGS_INVESTMENT_TYPES = [
  InvestmentType.PrivateStock,
  InvestmentType.Cryptocurrency,
  InvestmentType.UniversalTrustForMinorAccount,
  InvestmentType.BrokerageAccount,
  InvestmentType.StockOption,
  InvestmentType.RothSubsection_401KAccount,
  InvestmentType.RothIndividualRetirementAccount,
];

export const EDUCATION_SAVINGS_INVESTMENT_TYPES = [
  InvestmentType.QualifiedTuitionPlan_529,
];

export const PRE_TAX_SAVINGS_INVESTMENT_TYPES = [
  InvestmentType.Subsection_401KAccount,
  InvestmentType.Annuity,
  InvestmentType.DefinedBenefitPlanAccount,
  InvestmentType.HealthSavingsAccount,
  InvestmentType.IndividualRetirementAccount,
  InvestmentType.MinorIndividualRetirementAccount,
  InvestmentType.MinorRothIndividualRetirementAccount,
  InvestmentType.ProfitSharingAccount,
  InvestmentType.SimpleIndividualRetirementAccount,
  InvestmentType.SimplifiedEmployeePensionAccount,
  InvestmentType.TaxShelteredAnnuity_403BAccount,
];
