import { useElementDetailViewIndexPage, UseElementDetailViewIndexPageArgs } from '../../shared';

export interface UseDebtRateElementDetailViewIndexPageArgs extends UseElementDetailViewIndexPageArgs {}

export function useDebtRateElementDetailViewIndexPage(
  useElementDetailViewIndexPageArgs: UseDebtRateElementDetailViewIndexPageArgs
) {
  const useElementDetailViewIndexPageReturn = useElementDetailViewIndexPage(useElementDetailViewIndexPageArgs);

  return { ...useElementDetailViewIndexPageReturn };
}
