import { useTranslation } from 'react-i18next';
import { TaxReturnSummaryFormSideOverPageProps } from './types';
import { StandardTaxReturnSummaryForm } from '../../../TaxReturnSummary';
import { ElementsButton, SideOverHeading, SideOverHeaderNew } from '../../components';

export function TaxReturnSummaryFormSideOverPage({
  exit,
  isLoading,
  allowDocuments,
  sideOverHeaderChildren,
  useTaxReturnSummaryFormReturn,
}: TaxReturnSummaryFormSideOverPageProps) {
  const { t: tTaxes } = useTranslation('taxes');
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')}>
        {sideOverHeaderChildren}
      </SideOverHeaderNew>
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={tTaxes('add-taxes')} />
        {useTaxReturnSummaryFormReturn.formReady && (
          <StandardTaxReturnSummaryForm
            className="mb-36"
            useTaxReturnSummaryFormReturn={useTaxReturnSummaryFormReturn}
            visibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
          >
            <ElementsButton
              size="large"
              icon="check"
              className="mt-20"
              iconPosition="left"
              isLoading={isLoading}
              label={tCommon('save')}
            />
          </StandardTaxReturnSummaryForm>
        )}
      </div>
    </>
  );
}
