import { ElementsIconProps } from '../components/Icon/types';

export function StackElementsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="stack_elements_24px">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.78673 7.275C3.44383 7.46551 3.44383 7.95866 3.78673 8.14916L11.0287 12.1725C11.6328 12.5081 12.3672 12.5081 12.9713 12.1725L20.2133 8.14916C20.5562 7.95866 20.5562 7.46551 20.2133 7.275L12.9713 3.25169C12.3672 2.9161 11.6328 2.91611 11.0287 3.25169L3.78673 7.275ZM4.79999 10.7121L3.78673 11.275C3.44383 11.4655 3.44383 11.9587 3.78673 12.1492L11.0287 16.1725C11.6328 16.5081 12.3672 16.5081 12.9713 16.1725L20.2133 12.1492C20.5562 11.9587 20.5562 11.4655 20.2133 11.275L19.2 10.7121L12.9713 14.1725C12.3672 14.5081 11.6328 14.5081 11.0287 14.1725L4.79999 10.7121ZM4.79999 14.7121L3.78673 15.275C3.44383 15.4655 3.44383 15.9587 3.78673 16.1492L11.0287 20.1725C11.6328 20.5081 12.3672 20.5081 12.9713 20.1725L20.2133 16.1492C20.5562 15.9587 20.5562 15.4655 20.2133 15.275L19.2 14.7121L12.9713 18.1725C12.3672 18.5081 11.6328 18.5081 11.0287 18.1725L4.79999 14.7121Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
