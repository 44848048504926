import { ElementsIconProps } from '../components/Icon/types';

export function FavoriteBorderIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="favorite_border_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6603 4.1672C17.0203 2.3672 13.7603 3.2072 12.0003 5.2672C10.2403 3.2072 6.98027 2.3572 4.34027 4.1672C2.94027 5.1272 2.06027 6.7472 2.00027 8.4572C1.86027 12.3372 5.30027 15.4472 10.5503 20.2172L10.6503 20.3072C11.4103 20.9972 12.5803 20.9972 13.3403 20.2972L13.4503 20.1972C18.7003 15.4372 22.1303 12.3272 22.0003 8.4472C21.9403 6.7472 21.0603 5.1272 19.6603 4.1672ZM12.1003 18.7272L12.0003 18.8272L11.9003 18.7272C7.14027 14.4172 4.00027 11.5672 4.00027 8.6772C4.00027 6.6772 5.50027 5.1772 7.50027 5.1772C9.04027 5.1772 10.5403 6.1672 11.0703 7.5372H12.9403C13.4603 6.1672 14.9603 5.1772 16.5003 5.1772C18.5003 5.1772 20.0003 6.6772 20.0003 8.6772C20.0003 11.5672 16.8603 14.4172 12.1003 18.7272Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="3"
          width="22"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6603 4.1672C17.0203 2.3672 13.7603 3.2072 12.0003 5.2672C10.2403 3.2072 6.98027 2.3572 4.34027 4.1672C2.94027 5.1272 2.06027 6.7472 2.00027 8.4572C1.86027 12.3372 5.30027 15.4472 10.5503 20.2172L10.6503 20.3072C11.4103 20.9972 12.5803 20.9972 13.3403 20.2972L13.4503 20.1972C18.7003 15.4372 22.1303 12.3272 22.0003 8.4472C21.9403 6.7472 21.0603 5.1272 19.6603 4.1672ZM12.1003 18.7272L12.0003 18.8272L11.9003 18.7272C7.14027 14.4172 4.00027 11.5672 4.00027 8.6772C4.00027 6.6772 5.50027 5.1772 7.50027 5.1772C9.04027 5.1772 10.5403 6.1672 11.0703 7.5372H12.9403C13.4603 6.1672 14.9603 5.1772 16.5003 5.1772C18.5003 5.1772 20.0003 6.6772 20.0003 8.6772C20.0003 11.5672 16.8603 14.4172 12.1003 18.7272Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_130)"></g>
      </g>
    </svg>
  );
}
