import { useTranslation } from 'react-i18next';
import { useInvestments } from '../queries';
import { getTotalValueOfInvestments, investmentsOfType } from '../util';
import { InvestmentType } from '../../generated/graphql';

export interface UsePrivateStockSumTableArgs {
  householdID: string | undefined | null;
}

export function usePrivateStockSumTable({
  householdID,
}: UsePrivateStockSumTableArgs) {
  const { t: tInvestment } = useTranslation('investment');
  const { investments, investmentsQuery } = useInvestments({ householdID });

  const privateStock = investmentsOfType(
    investments,
    InvestmentType.PrivateStock
  );
  const totalPrivateStockValue = getTotalValueOfInvestments(privateStock);
  const isLoading = investmentsQuery.isLoading;

  return {
    tInvestment,
    isLoading,
    privateStock,
    totalPrivateStockValue,
  };
}
