import { add, differenceInMonths, Duration as DateFnsAddDuration, isValid } from 'date-fns';
import { TFunction } from 'i18next';
import { DefaultValues } from 'react-hook-form';
import {
  monetaryAmountDataPointFormDefaultValue,
  percentageDataPointFormDefaultValue,
  recurringMonetaryAmountDataPointFormDefaultValue,
} from '../UI';
import {
  InterestRateType,
  Loan,
  LoanType,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import {
  addMonetaryAmounts,
  addRecurringMonetaryAmounts,
  displayPercentage,
  recurringMonetaryAmountConverter,
} from '../util';
import { LoanFormValues } from './types';
import { floatDataPointValue, monetaryAmountDataPointValue, recurringMonetaryAmountDataPointValue } from '../DataPoint';

export function getTotalDebtPaymentsForLoans(
  loans: Loan[],
  options: { frequency?: RecurringFrequency } = {}
): RecurringMonetaryAmount | null {
  const debtPayments: RecurringMonetaryAmount[] = [];

  for (const loan of loans) {
    const loanPayments = getLoanPayments(loan);
    if (loanPayments) debtPayments.push(loanPayments);
  }

  return addRecurringMonetaryAmounts(debtPayments, options.frequency);
}

export function getLoanPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  const value = recurringMonetaryAmountDataPointValue(loan.paymentAmount.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getLoansWithPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanPayments(loan)?.amount.value);
}

export function getLoanExtraPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  const value = recurringMonetaryAmountDataPointValue(loan.extraPaymentAmount.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getTotalExtraPaymentsForLoans(loans: Loan[], options: { frequency?: RecurringFrequency } = {}) {
  const extraPayments: RecurringMonetaryAmount[] = [];

  for (const loan of loans) {
    const loanExtraPayments = getLoanExtraPayments(loan);
    if (loanExtraPayments) extraPayments.push(loanExtraPayments);
  }

  return addRecurringMonetaryAmounts(extraPayments, options.frequency);
}

export function getLoansWithExtraPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanExtraPayments(loan)?.amount.value);
}

export function getLoansWithPaymentsOrExtraPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanPayments(loan)?.amount.value || !!getLoanExtraPayments(loan)?.amount.value);
}

export function getLoanPaymentsAndExtraPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  return addRecurringMonetaryAmounts(
    [getLoanPayments(loan, options), getLoanExtraPayments(loan, options)],
    options.frequency
  );
}

export function getTotalPaymentsAndExtraPaymentsForLoans(
  loans: Loan[],
  options: { frequency?: RecurringFrequency } = {}
) {
  const payments = getTotalDebtPaymentsForLoans(loans, options);
  const extraPayments = getTotalExtraPaymentsForLoans(loans, options);

  return addRecurringMonetaryAmounts([payments, extraPayments], options.frequency);
}

export const lengthOfLoanDefaultValue = (loan?: Loan) => {
  if (!loan?.originationDate || !loan?.maturityDate) return null;

  const originationDate = new Date(loan.originationDate);
  const maturityDate = new Date(loan.maturityDate);
  const monthDiff = differenceInMonths(maturityDate, originationDate);
  const inYears = monthDiff % 12 === 0;

  return {
    length: inYears ? monthDiff / 12 : monthDiff,
    frequency: inYears ? RecurringFrequency.Annually : RecurringFrequency.Monthly,
  };
};

export const loanFormDefaultValues = (
  loan?: Loan,
  defaultValues?: Partial<LoanFormValues>
): DefaultValues<LoanFormValues> => {
  if (!loan) return { ...defaultValues };

  return {
    nickname: loan.name,
    loanType: loan.loanType,
    lengthOfLoan: lengthOfLoanDefaultValue(loan),
    interestRate: percentageDataPointFormDefaultValue(loan.interestRate.latestDataPoint),
    estimatedBalance: monetaryAmountDataPointFormDefaultValue(loan.balance.latestDataPoint),
    payments: recurringMonetaryAmountDataPointFormDefaultValue(loan.paymentAmount.latestDataPoint),
    ...defaultValues,
  };
};

export function groupLoansByType(loans: Loan[]) {
  const loansMap: Map<LoanType, Loan[]> = new Map();

  loans.forEach((loan) => {
    if (!loansMap.has(loan.loanType)) {
      loansMap.set(loan.loanType, []);
    }
    const loansOfType = loansMap.get(loan.loanType);
    if (loansOfType) {
      loansOfType.push(loan);
    }
  });

  return loansMap;
}

export const deriveMaturityDate = (
  originationDate?: string | null,
  lengthOfLoan?: number | null,
  lengthFrequency?: RecurringFrequency | null
) => {
  let maturityDate: string | null = null;

  if (lengthOfLoan && !isNaN(Number(lengthOfLoan)) && isValid(new Date(originationDate ?? ''))) {
    const addObj: DateFnsAddDuration = {
      months: lengthFrequency === RecurringFrequency.Monthly ? lengthOfLoan : 0,
      years: lengthFrequency === RecurringFrequency.Annually ? lengthOfLoan : 0,
    };

    maturityDate = add(new Date(originationDate!), addObj).toISOString();
  }

  return maturityDate;
};

export const isSimpleLoan = (loan: Loan) => {
  switch (loan.loanType) {
    case LoanType.CreditCard:
    case LoanType.SecuredLineOfCredit:
    case LoanType.UnsecuredLineOfCredit:
      return true;
    default:
      return false;
  }
};

export function loansOfType(loans: Loan[], loanType: LoanType) {
  return loans.filter((loan) => loan.loanType === loanType);
}

export function displayLoanType(type: LoanType, t: TFunction<'loan'>) {
  switch (type) {
    case LoanType.AutoLoan:
      return t('auto-loan');
    case LoanType.BusinessLoan:
      return t('business-loan');
    case LoanType.CreditCard:
      return t('credit-card');
    case LoanType.HomeEquityLineOfCredit:
      return t('home-equity-line-of-credit');
    case LoanType.HomeEquityLoan:
      return t('home-equity-loan');
    case LoanType.InvestmentRealEstateLoan:
      return t('investment-real-estate-loan');
    case LoanType.Mortgage:
      return t('mortgage');
    case LoanType.OtherBusinessLoan:
      return t('other-business-loan');
    case LoanType.OtherBusinessRealEstateLoan:
      return t('other-business-real-estate-loan');
    case LoanType.OtherLoan:
      return t('other-loan');
    case LoanType.PersonalLoan:
      return t('personal-loan');
    case LoanType.ReverseMortgage:
      return t('reverse-mortgage');
    case LoanType.SecuredLineOfCredit:
      return t('secured-line-of-credit');
    case LoanType.StudentLoan:
      return t('student-loan');
    case LoanType.UnsecuredLineOfCredit:
      return t('unsecured-line-of-credit');
  }
}

export const loansHaveOfType = (loans: Loan[], type: LoanType) => loans.some((loan) => loan.loanType === type);

export function getLastLoanUpdateDate(loan: Loan): string {
  return loan.balance?.latestDataPoint?.dateTime ?? loan.balance?.updatedAt;
}

export function getLoanInterestRate(loan: Loan) {
  return floatDataPointValue(loan.interestRate?.latestDataPoint);
}

export function getLoanInterestRateDisplay(loan: Loan) {
  const percentage = getLoanInterestRate(loan);
  if (!percentage) return '';
  return displayPercentage(percentage);
}

export function displayInterestRateType(type: InterestRateType, t: TFunction<'loan'>) {
  switch (type) {
    case InterestRateType.Fixed:
      return t('fixed');
    case InterestRateType.Variable:
      return t('variable');
    default:
      return '';
  }
}

export function getLoanBalance(loan: Loan) {
  return monetaryAmountDataPointValue(loan.balance.latestDataPoint);
}

export function getLoanBalanceNegative(loan: Loan): MonetaryAmount {
  const balance: MonetaryAmount = getLoanBalance(loan) || { value: 0, currencyCode: 'USD' };
  return {
    ...balance,
    value: -1 * balance.value,
  };
}

export function getTotalBalanceOfLoans(loans: Loan[]) {
  const balances: MonetaryAmount[] = [];

  for (const loan of loans) {
    const balance = getLoanBalance(loan);
    if (balance) balances.push(balance);
  }

  return addMonetaryAmounts(balances);
}

export function getLoansWithBalance(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanBalance(loan)?.value);
}
