import { SummaryPageContainer } from './components';
import { CashSummarySumTable } from '../../../TtDetails';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewCashAccountsPageProps } from './types';

export function TotalTermElementDetailViewCashAccountsPage({
  householdID,
}: TotalTermElementDetailViewCashAccountsPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-cash-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-cash-summary-page-empty-description')}
    >
      <CashSummarySumTable hideOnEmpty className="mt-10" householdID={householdID} />
    </SummaryPageContainer>
  );
}
