import { useCashSummarySumTable } from '../hooks';
import { CashSummarySumTableProps } from '../types';
import { displayCashAccountType, getCashAccountValue } from '../../CashAccount';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function CashSummarySumTable({ householdID, ...props }: CashSummarySumTableProps) {
  const { isLoading, tUI, cashAccounts, totalCashAccounts, tCashAccount } = useCashSummarySumTable({
    householdID,
  });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalCashAccounts}
      sumTitle={tUI('cash-account-summary-sum-table-total-title')}
    >
      {cashAccounts?.map((cashAccount) => (
        <AmountSumTableItem
          key={cashAccount?.id}
          title={cashAccount.name}
          amount={getCashAccountValue(cashAccount)}
          subtitle={displayCashAccountType(cashAccount.cashAccountType, tCashAccount)}
        />
      ))}
    </AmountSumTable>
  );
}
