import { ElementsIconProps } from '../components/Icon/types';

export function NetWorthIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6288 2C17.9133 2 17.3333 2.57999 17.3333 3.29545V19.7045C17.3333 20.42 17.9133 21 18.6288 21H18.7045C19.42 21 20 20.42 20 19.7045V3.29545C20 2.57999 19.42 2 18.7045 2H18.6288ZM9.7399 7.18182C9.02444 7.18182 8.44444 7.76181 8.44444 8.47727V19.7045C8.44444 20.42 9.02444 21 9.7399 21H9.81566C10.5311 21 11.1111 20.42 11.1111 19.7045V8.47727C11.1111 7.76181 10.5311 7.18182 9.81566 7.18182H9.7399ZM5.29545 10.6364C4.57999 10.6364 4 11.2164 4 11.9318V19.7045C4 20.42 4.58 21 5.29545 21H5.37121C6.08667 21 6.66667 20.42 6.66667 19.7045V11.9318C6.66667 11.2164 6.08667 10.6364 5.37121 10.6364H5.29545ZM12.8889 9.34091C12.8889 8.62545 13.4689 8.04545 14.1843 8.04545H14.2601C14.9756 8.04545 15.5556 8.62545 15.5556 9.34091V19.7045C15.5556 20.42 14.9756 21 14.2601 21H14.1843C13.4689 21 12.8889 20.42 12.8889 19.7045V9.34091Z"
        fill="currentColor"
        />
    </svg>
  );
}
