import { ElementsIconProps } from '../components/Icon/types';

export function FolderMoveIcon(props: ElementsIconProps) {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <g id="folder__move_24px">
      <path 
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H10L8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM10 11.79V10H7C6.45 10 6 9.55 6 9C6 8.45 6.45 8 7 8H10V6.21C10 5.76 10.54 5.54 10.85 5.86L13.64 8.65C13.84 8.85 13.84 9.16 13.64 9.36L10.85 12.15C10.54 12.46 10 12.24 10 11.79Z"
        fill="currentColor"  
      />
    </g>
    </svg>
  );
}
