import { useTranslation } from 'react-i18next';
import { Investment, RecurringFrequency } from '../../generated/graphql';
import {
  getInvestmentMix,
  getTotalRecurringContributionsForInvestments,
  getTotalValueOfInvestments,
} from '../../Investment/util';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { displayInvestmentMix, displayInvestmentMixRatio } from '../../Investment/display';
import { useErDetails } from '../queries';

export interface UseInvestmentDetailsTableArgs {
  householdID: string;
}

export const useInvestmentDetailsTable = ({ householdID }: UseInvestmentDetailsTableArgs) => {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { displayRecurringMonetaryAmount } = useDisplayRecurringMonetaryAmount();
  const { erDetails, erDetailsQuery } = useErDetails({
    householdID,
    includeInvestments: true,
    includeTotalEquityInvestments: true,
  });

  const isLoading = erDetailsQuery.isLoading;
  const investments = erDetails?.investments || [];
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    getTotalRecurringContributionsForInvestments(investments, {
      frequency: RecurringFrequency.Monthly,
    }),
    { nullishIsDash: true }
  );
  const totalInvestmentValue = getTotalValueOfInvestments(investments);

  const getCustomBalanceSubtitle = (investment: Investment) => {
    const investmentMix = getInvestmentMix(investment);
    if (investmentMix) {
      return tInvestment('investment-details-table-investment-mix-subtitle', {
        ratioDisplay: displayInvestmentMixRatio(investmentMix, tInvestment),
        mixDisplay: displayInvestmentMix(investmentMix, tInvestment),
      });
    }
    return null;
  };

  return {
    tUI,
    isLoading,
    tInvestment,
    investments,
    totalInvestmentValue,
    getCustomBalanceSubtitle,
    monthlyContributionsDisplay,
  };
};
