import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { UseIncomeSourceFormArgs, useIncomeSourceForm } from '../hooks';

interface IncomeSourceFormProps extends UseIncomeSourceFormArgs {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
}

export const IncomeSourceForm: FC<IncomeSourceFormProps> = ({
  children,
  className,
  ...useIncomeSourceFormArgs
}) => {
  const { formMethods, onSubmit } = useIncomeSourceForm(
    useIncomeSourceFormArgs
  );

  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};
