import { FC } from 'react';
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicy, InsurancePolicyType } from '../../../../generated/graphql';
import { displayInsurancePolicySubType, useFormatExpirationInformation } from '../../../../Insurance';
import { displayMonetaryAmount } from '../../../../util';
import { ListItem } from '../../../components';
import { useInsuranceElementDetailViewContext } from '../hooks';

interface PolicyClickMenuListItemProps {
  insurance: InsurancePolicy;
}

export const PolicyClickMenuListItem: FC<PolicyClickMenuListItemProps> = ({ insurance }) => {
  const { firmClient, routes } = useInsuranceElementDetailViewContext();
  const { t: tCommon } = useTranslation('common');
  const { t: tDisplay } = useTranslation('insurance');
  const expirationInfo = useFormatExpirationInformation(insurance);

  const subtitle =
    insurance.policyType === InsurancePolicyType.Life
      ? expirationInfo
      : displayInsurancePolicySubType(insurance.policySubtype, tDisplay);

  const inner = (
    <ListItem
      withChevron
      className="[&_.ListItem-children-container]:block [&_.ListItem-children-container]:flex-1 [&_.ListItem-children-container]:!bg-darkMode-gray-floatingMenu !bg-darkMode-gray-floatingMenu"
    >
      <div className="mb-2 grid gap-1">
        <p className="inline-block text-[18px] font-normal leading-[18px] text-white">{insurance.name}</p>
        {subtitle && <p className="whitespace-nowrap text-sm font-normal leading-[14px]">{subtitle}</p>}
      </div>
      <p className="text-2xl font-bold text-white">
        {displayMonetaryAmount(insurance.coverageAmount, {
          suffix: insurance.policyType === InsurancePolicyType.Disability ? tCommon('monthly-frequency-suffix') : '',
        })}
      </p>
    </ListItem>
  );

  return firmClient && routes?.editInsurance ? (
    <Link
      to={routes.editInsurance({
        clientID: firmClient?.id,
        insuranceID: insurance.id,
      })}
      className="border-b border-b-darkMode-gray-medium/40 pb-[23px] last:!border-none"
    >
      {inner}
    </Link>
  ) : (
    <div className="border-b border-b-darkMode-gray-medium/40 pb-[23px] last:!border-none">{inner}</div>
  );
};
