import { ComponentPropsWithoutRef } from 'react';

export interface CheckboxIconProps
  extends Pick<ComponentPropsWithoutRef<'svg'>, 'className'> {
  checked?: boolean | null;
  type?: 'circle' | 'square';
  borderColor?: string;
  fillColor?: string;
}

export const CheckboxIcon = ({
  checked,
  type = 'circle',
  className,
  borderColor = '#585F66',
  fillColor = '#4AF196',
}: CheckboxIconProps) => {
  let icon = null;

  if (checked) {
    if (type === 'circle') {
      icon = (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.59 7.59a.996.996 0 0 1-1.41 0z"
            fill={fillColor}
          />
          <mask
            id="0xqmjchpra"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="20"
            height="20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.59 7.59a.996.996 0 0 1-1.41 0z"
              fill="#fff"
            />
          </mask>
        </svg>
      );
    } else if (type === 'square') {
      icon = (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29a.996.996 0 0 1-1.41 0L5.71 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.58 7.59z"
              id="hlhl9kdiya"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <use fill={fillColor} xlinkHref="#hlhl9kdiya" />
          </g>
        </svg>
      );
    }
  } else {
    if (type === 'circle') {
      icon = (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            fill={borderColor}
          />
          <mask
            id="vy4mxwtxma"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="20"
            height="20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
              fill="#fff"
            />
          </mask>
        </svg>
      );
    } else if (type === 'square') {
      icon = (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
              id="CheckboxIcon_unchecked_square_a"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <use
              fill={borderColor}
              xlinkHref="#CheckboxIcon_unchecked_square_a"
            />
          </g>
        </svg>
      );
    }
  }

  return icon;
};
