import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UsePreTaxInvestmentsSummarySumTableArgs {
  householdID: string;
}

export function usePreTaxInvestmentsSummarySumTable({ householdID }: UsePreTaxInvestmentsSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalPreTaxInvestments = ttDetails?.assetSummary.totalPreTaxInvestments;
  const preTaxInvestments = ttDetails?.assetSummary.preTaxInvestments.sort((a, b) => a.name.localeCompare(b.name));

  return { preTaxInvestments, totalPreTaxInvestments, tUI, isLoading, tInvestment };
}
