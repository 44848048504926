import { Link, LinkProps } from '@remix-run/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';

interface AnimatedFabDivider
  extends Partial<Pick<LinkProps, 'to' | 'prefetch'>> {
  icon: JSX.Element;
  diameter?: number;
  hoverWidth: number;
  className?: HTMLDivElement['className'];
  label: string;
}

export const AnimatedFabDivider: FC<AnimatedFabDivider> = ({
  to,
  icon,
  label,
  prefetch,
  className,
  hoverWidth,
  diameter = 60,
}) => {
  const [labelVisible, setLabelVisible] = useState(false);

  const button = (
    <motion.button
      transition={{ ease: 'easeInOut' }}
      style={{ height: `${diameter}px` }}
      initial={{ width: `${diameter}px` }}
      onHoverEnd={() => setLabelVisible(false)}
      onHoverStart={() => setLabelVisible(true)}
      whileHover={{ width: `${hoverWidth}px`, transformOrigin: 'right' }}
      className="grid grid-flow-col place-content-center items-end justify-start overflow-hidden rounded-full bg-primary py-[18px] pl-[18px] pr-6 active:bg-primary/80"
    >
      {icon}
      <AnimatePresence>
        {labelVisible && (
          <motion.p
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="ml-2 whitespace-nowrap text-xl font-bold leading-[21px] text-darkMode-gray-dark"
          >
            {label}
          </motion.p>
        )}
      </AnimatePresence>
    </motion.button>
  );

  return (
    <div
      style={{ width: 'calc(100% + 96px)' }}
      className={clsx(
        'relative -left-12 flex h-[60px]  items-center',
        className
      )}
    >
      <div
        className="w-full border-t border-t-[#101112] bg-[#585f66] bg-opacity-20"
        style={{ height: '2px' }}
      />
      <div className={clsx('absolute left-12')}>
        {to ? (
          <Link to={to} prefetch={prefetch}>
            {button}
          </Link>
        ) : (
          button
        )}
      </div>
    </div>
  );
};
