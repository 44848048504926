import clsx from 'clsx';
import { ContributionsSumTable, ContributionsSumTableItem } from '../../UI';
import { UseInvestmentDetailsTableArgs, useInvestmentDetailsTable } from '../hooks';
import { getInvestmentRecurringContributions, getInvestmentValue, displayInvestmentType } from '../../Investment';

interface InvestmentDetailsTableProps extends UseInvestmentDetailsTableArgs {}

export function InvestmentDetailsTable({ ...useInvestmentDetailsTableArgs }: InvestmentDetailsTableProps) {
  const {
    tUI,
    isLoading,
    tInvestment,
    investments,
    totalInvestmentValue,
    getCustomBalanceSubtitle,
    monthlyContributionsDisplay,
  } = useInvestmentDetailsTable(useInvestmentDetailsTableArgs);

  return (
    <ContributionsSumTable
      hideOnEmpty
      isLoading={isLoading}
      sum={totalInvestmentValue}
      sumAppendix={monthlyContributionsDisplay}
      title={tUI('investment-details-table-title')}
      sumTitle={tUI('investment-details-table-sum-title')}
      headers={[
        tUI('contributions-sum-table-account-header'),
        tUI('contributions-sum-table-contributions-header'),
        tUI('contributions-sum-table-balance-header'),
      ]}
      className="[&_.ContributionsSumTable-TrailingHeadersContainer]:!w-[265px] mb-8"
    >
      {investments.map((inv) => (
        <ContributionsSumTableItem
          key={inv.id}
          title={inv.name}
          balance={getInvestmentValue(inv)}
          customBalanceSubTitle={getCustomBalanceSubtitle(inv)}
          contributions={getInvestmentRecurringContributions(inv)}
          className={clsx(
            '!items-center',
            '[&_.ContributionsSumTableItem-BalanceColumn]:order-1 [&_.ContributionsSumTableItem-BalanceColumn]:min-w-[159px]',
            '[&_.ContributionsSumTableItem-ContributionsColum]:!min-w-max',
            '[&_.ContributionsSumTableItem-Title]:line-clamp-2'
          )}
          subtitle={displayInvestmentType(inv.investmentType, tInvestment)}
        />
      ))}
    </ContributionsSumTable>
  );
}
