import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FrequencyDropdown,
  FrequencyDropdownProps,
  validateRecurringContributionInput,
} from '../../UI';
import { LoanFormValues } from '../types';

interface LoanPaymentsFrequencyDropdownProps
  extends Pick<FrequencyDropdownProps, 'dropdownMenuClassName'> {}

export const LoanPaymentsFrequencyDropdown: FC<
  LoanPaymentsFrequencyDropdownProps
> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<LoanFormValues, 'payments.frequency'>({
    control,
    name: 'payments.frequency',
    rules: {
      validate: (frequency, { payments: { amount } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <FrequencyDropdown
      {...field}
      withClearSelection
      {...dropdownProps}
      status={error ? 'errored' : 'normal'}
      onChange={(value) => field.onChange(value)}
      label={tForm('loan-payments-frequency-dropdown-label')}
    />
  );
};
