import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';

export const BusinessFragment = gql`
  ${OwnerFragment}
  fragment BusinessFragment on Business {
    id
    name
    notes
    __typename
    changeToken
    householdID
    entityTaxStructure
    ownership {
      __typename
      ... on TenantsInCommon {
        interests {
          owner {
            ...OwnerFragment
          }
          percentage
        }
      }
    }
    value {
      tenantID
      id
      updatedAt
      latestDataPoint {
        dateTime
        data {
          __typename
          ... on MonetaryAmountDataPointValue {
            value {
              currencyCode
              value
            }
          }
        }
      }
      dataPoints {
        items {
          id
          dateTime
          data {
            __typename
            ... on MonetaryAmountDataPointValue {
              value {
                currencyCode
                value
              }
            }
          }
        }
      }
    }
  }
`;
