import { useLoans } from '../../Loan';
import { useDrDetails } from '..';
import { useTranslation } from 'react-i18next';
import { RecurringFrequency } from '../../generated/graphql';
import { useRecurringMonetaryAmountDisplayData } from '../../DataPoint';
import { recurringMonetaryAmountConverter } from '../../util';
import { useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan/hooks';

export interface UseLoanDetailsSumTableArgs {
  householdID: string;
}

export function useLoanDetailsSumTable({ householdID }: UseLoanDetailsSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();

  const { loans, loansQuery } = useLoans({ householdID });
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeAnnualDebtPayments: true });

  const isLoading = loansQuery.isLoading || drDetailsQuery.isLoading;
  const annualContributions = drDetails?.annualDebtPayments;
  const monthlyContributions = annualContributions
    ? recurringMonetaryAmountConverter(
        {
          amount: annualContributions,
          frequency: RecurringFrequency.Annually,
          __typename: 'RecurringMonetaryAmount',
        },
        RecurringFrequency.Monthly
      )
    : null;
  const monthlyContributionsDisplay =
    useRecurringMonetaryAmountDisplayData(monthlyContributions).displayStringWithFrequency;

  return {
    tUI,
    loans,
    isLoading,
    annualContributions,
    monthlyContributionsDisplay,
    displayLoanAmountSumTableItemSubtitle,
  };
}
