import { TargetScoreRange } from '../../../../generated/graphql';
import { isScorecardTermElement, ScorecardElementSymbol } from '../../../../Scorecard';
import { displayRate, getFormattedNumberLabel } from '../../../../util';

type ScoreHealthRangeLabelKeys = 'target-score-default' | 'target-score-range-default' | 'target-score-set-advisor' | 'target-score-range-set-advisor' | 'target-score-range-set-client' | 'target-score-set-client';

export function getScoreHealthRangeLabel(element: ScorecardElementSymbol, elementTargetScoreRange: TargetScoreRange) {
  let score;
  let label: ScoreHealthRangeLabelKeys;
  const isSingleScore = elementTargetScoreRange.range?.min == elementTargetScoreRange.range?.max;
  if (isSingleScore) {
    label = 'target-score-default';
    score = isScorecardTermElement(element)
      ? getFormattedNumberLabel(elementTargetScoreRange.range?.min)
      : displayRate(elementTargetScoreRange.range?.min);
  } else {
    label = 'target-score-range-default';
    score = isScorecardTermElement(element)
      ? `${getFormattedNumberLabel(elementTargetScoreRange.range?.min)}-${getFormattedNumberLabel(elementTargetScoreRange.range?.max)}`
      : `${displayRate(elementTargetScoreRange.range?.min)}-${displayRate(elementTargetScoreRange.range?.max)}`;
  }

  if (elementTargetScoreRange?.customized) {
    if (isSingleScore) {
      label = 'target-score-set-advisor';
    } else {
      label = 'target-score-range-set-advisor';
    }
  }

  return { score, label };
}
