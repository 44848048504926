import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { scorecardKeys } from '../Scorecard/queryKeys';
import {
  CreateInvestmentInput,
  DeleteInvestmentInput,
  UpdateInvestmentInput,
} from '../generated/graphql';
import {
  clientCreateInvestment,
  clientDeleteInvestment,
  clientUpdateInvestment,
  useGraphqlClient,
} from '../graphql';
import { investmentKeys } from './queryKeys';

export const useCreateInvestment = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const createInvestment = useMutation({
    retry: 3,
    mutationKey: ['useCreateInvestment'],
    mutationFn: (input: CreateInvestmentInput) => {
      setHouseholdID(input.householdID);
      return clientCreateInvestment(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(investmentKeys.investments(householdID));
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { createInvestment };
};

export const useUpdateInvestment = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const updateInvestment = useMutation({
    retry: 3,
    mutationKey: ['useUpdateInvestment'],
    mutationFn: (input: UpdateInvestmentInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateInvestment(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(investmentKeys.investments(householdID));
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { updateInvestment };
};

export const useDeleteInvestment = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const deleteInvestment = useMutation({
    retry: 3,
    mutationKey: ['useDeleteInvestment'],
    mutationFn: (input: DeleteInvestmentInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteInvestment(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(investmentKeys.investments(householdID));
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { deleteInvestment };
};
