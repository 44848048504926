import { useOtherAssetsSummarySumTable } from '../hooks';
import { OtherAssetsSummarySumTableProps } from '../types';
import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { getNoteReceivableValue } from '../../NoteReceivable';
import { getPersonalPropertyItemValue } from '../../PersonalPropertyItem';

export function OtherAssetsSummarySumTable({ householdID, ...props }: OtherAssetsSummarySumTableProps) {
  const { isLoading, tUI, notesReceivable, personalPropertyItems, totalOtherAssets } = useOtherAssetsSummarySumTable({
    householdID,
  });

  return (
    <AmountSumTable
      {...props}
      isLoading={isLoading}
      sum={totalOtherAssets}
      sumTitle={tUI('other-assets-summary-sum-table-total-title')}
    >
      {!!notesReceivable?.length && !!personalPropertyItems?.length && (
        <>
          {notesReceivable?.map((noteReceivable) => (
            <AmountSumTableItem
              key={noteReceivable?.id}
              title={noteReceivable.name}
              amount={getNoteReceivableValue(noteReceivable)}
              subtitle={tUI('other-assets-summary-sum-table-note-receivable-subtitle')}
            />
          ))}
          {personalPropertyItems?.map((personalPropertyItem) => (
            <AmountSumTableItem
              key={personalPropertyItem?.id}
              title={personalPropertyItem.name}
              amount={getPersonalPropertyItemValue(personalPropertyItem)}
              subtitle={tUI('other-assets-summary-sum-table-personal-property-item-subtitle')}
            />
          ))}
        </>
      )}
    </AmountSumTable>
  );
}
