import { useMutation } from '@tanstack/react-query';
import { useCreateDataPoint } from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { DataPointGroupType } from '../generated/graphql';
import { recurringMonetaryAmountDataPointValueInput } from '../util';
import { useCreateCashAccount, useUpdateCashAccount } from './mutations';
import {
  CreateCashAccountServiceInput,
  UpdateCashAccountRecurringContributionServiceInput,
  UpdateCashAccountServiceInput,
  UpdateCashAccountValueServiceInput,
} from './types';

export const useUpdateCashAccountValueService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateCashAccountValueService = useMutation({
    mutationKey: ['updateCashAccountValueService'],
    mutationFn: async (input: UpdateCashAccountValueServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.valueInCents,
              },
            },
          },
          groupID: input.valueDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateCashAccountValueService };
};

export const useUpdateCashAccountRecurringContributionService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateCashAccountRecurringContributionService = useMutation({
    mutationKey: ['updateCashAccountRecurringContributionService'],
    mutationFn: async (input: UpdateCashAccountRecurringContributionServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: input.recurringContributionValueInput,
          },
          groupID: input.recurringContributionDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateCashAccountRecurringContributionService };
};

export const useCreateCashAccountService = () => {
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createCashAccount } = useCreateCashAccount();
  const { updateCashAccountRecurringContributionService } = useUpdateCashAccountRecurringContributionService();
  const { updateCashAccountValueService } = useUpdateCashAccountValueService();

  const createCashAccountService = useMutation({
    mutationKey: ['createCashAccountService'],
    mutationFn: async ({
      createCashAccountInput: { householdID, cashAccount, linkSwitch },
      ...input
    }: CreateCashAccountServiceInput) => {
      let cashAccountValueDataPointGroupID: string | undefined;
      if (input.valueInCents) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: { groupType: DataPointGroupType.MonetaryAmount },
        });
        cashAccountValueDataPointGroupID = dataPointGroup.id;
        await updateCashAccountValueService.mutateAsync({
          householdID,
          valueInCents: input.valueInCents,
          valueDataPointGroupID: dataPointGroup.id,
        });
      }

      let recurringContributionDataPointGroupID: string | undefined;
      if (input.recurringContributionFrequency && !isNaN(Number(input.recurringContributionInCents))) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: {
            groupType: DataPointGroupType.RecurringMonetaryAmount,
          },
        });
        recurringContributionDataPointGroupID = dataPointGroup.id;
        await updateCashAccountRecurringContributionService.mutateAsync({
          householdID,
          recurringContributionDataPointGroupID: dataPointGroup.id,
          recurringContributionValueInput: recurringMonetaryAmountDataPointValueInput({
            frequency: input.recurringContributionFrequency,
            valueInCents: Number(input.recurringContributionInCents),
          }),
        });
      }

      await createCashAccount.mutateAsync({
        householdID,
        linkSwitch,
        cashAccount: {
          ...cashAccount,
          valueDataPointGroup: cashAccountValueDataPointGroupID,
          recurringContributionDataPointGroup: recurringContributionDataPointGroupID,
        },
      });
    },
  });

  return { createCashAccountService };
};

export const useUpdateCashAccountService = () => {
  const { updateCashAccount } = useUpdateCashAccount();
  const { updateCashAccountRecurringContributionService } = useUpdateCashAccountRecurringContributionService();
  const { updateCashAccountValueService } = useUpdateCashAccountValueService();

  const updateCashAccountService = useMutation({
    mutationKey: ['updateCashAccountService'],
    mutationFn: async (input: UpdateCashAccountServiceInput) => {
      const { cashAccount } = await updateCashAccount.mutateAsync(input.updateCashAccountInput);

      if (!isNaN(Number(input.estimatedValueInCents))) {
        await updateCashAccountValueService.mutateAsync({
          householdID: cashAccount.householdID,
          valueDataPointGroupID: cashAccount.value.id,
          valueInCents: Number(input.estimatedValueInCents),
        });
      }

      if (input.recurringContributionFrequency && !isNaN(Number(input.recurringContributionInCents))) {
        await updateCashAccountRecurringContributionService.mutateAsync({
          householdID: cashAccount.householdID,
          recurringContributionDataPointGroupID: cashAccount.recurringContribution.id,
          recurringContributionValueInput: recurringMonetaryAmountDataPointValueInput({
            frequency: input.recurringContributionFrequency,
            valueInCents: input.recurringContributionInCents,
          }),
        });
      }
    },
  });

  return { updateCashAccountService };
};
