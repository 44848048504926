import { useCashAccounts } from '../queries';
import { useTranslation } from 'react-i18next';
import { getCashAccountsWithRecurringContributions, getTotalRecurringContributionsForCashAccounts } from '../util';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { recurringMonetaryAmountConverter } from '../../util';

export interface UseCashSavingsContributionsSumTableArgs {
  householdID: string | undefined | null;
}

export function useCashSavingsContributionsSumTable({ householdID }: UseCashSavingsContributionsSumTableArgs) {
  const { displayRecurringMonetaryAmount } = useDisplayRecurringMonetaryAmount();
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { cashAccounts, cashAccountsQuery } = useCashAccounts({ householdID });

  const accounts = getCashAccountsWithRecurringContributions(cashAccounts);
  const annualContributions = getTotalRecurringContributionsForCashAccounts(accounts, {
    frequency: RecurringFrequency.Annually,
  });
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    recurringMonetaryAmountConverter(annualContributions, RecurringFrequency.Monthly)
  );

  const isLoading = cashAccountsQuery.isLoading;

  return {
    accounts,
    isLoading,
    tCashAccount,
    annualContributions,
    monthlyContributionsDisplay,
  };
}
