import { FC } from 'react';
import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { useTranslation } from 'react-i18next';
import { useRtDetails } from '../queries';
import { MonetaryAmountDataPointValue } from '../../generated/graphql';

export const RealEstateUnattachedLoans: FC<{ householdID: string }> = ({ householdID }) => {
  const { t } = useTranslation('pages');
  const { rtDetails } = useRtDetails({
    householdID,
    includeUnattachedLoans: true,
    includeTotalUnattachedLoans: true,
  });

  return (
    <>
      {rtDetails?.unattachedLoans && rtDetails?.unattachedLoans.length > 0 && (
        <AmountSumTable
          className={'mb-8'}
          title={t('real-estate-term-element-detail-view-details-table-unattached-loans')}
          sum={rtDetails?.totalUnattachedLoans}
          sumTitle={t('real-estate-term-element-detail-view-details-table-unattached-loans-sum-title')}
        >
          {rtDetails?.unattachedLoans?.map((loan) => (
            <AmountSumTableItem
              key={loan.id}
              title={loan.name}
              amount={(loan.balance.latestDataPoint?.data as MonetaryAmountDataPointValue)?.value}
            />
          ))}
        </AmountSumTable>
      )}
    </>
  );
};
