import { DefaultValues } from 'react-hook-form';
import { Person, RelationshipType } from '../generated/graphql';
import { PersonFormValues } from './types';
import { TFunction } from 'i18next';
import { compareAsc, differenceInYears } from 'date-fns';

export const personFormDefaultValues = (
  person?: Person,
  defaultValues?: Partial<PersonFormValues>
): DefaultValues<PersonFormValues> => {
  if (!person) return { ...defaultValues };

  return {
    givenName: person.givenName,
    familyName: person.familyName,
    dateOfBirth: person.dateOfBirth,
    emailAddress: person.emailAddress,
    relationship: person.relationship,
  };
};

export function personsOfRelationshipTypes(
  people: Person[],
  relationshipTypes: RelationshipType[] | RelationshipType
) {
  return people.filter((p) => relationshipTypes.includes(p.relationship));
}

export function getChildren(people: Person[]) {
  return personsOfRelationshipTypes(people, [
    RelationshipType.Son,
    RelationshipType.Daughter,
    RelationshipType.Child,
  ]);
}

export function significantOthers(people: Person[]) {
  return personsOfRelationshipTypes(people, [
    RelationshipType.Boyfriend,
    RelationshipType.Girlfriend,
    RelationshipType.Husband,
    RelationshipType.Wife,
    RelationshipType.Partner,
  ]);
}

export function getHeadsOfHousehold(people: Person[]) {
  return personsOfRelationshipTypes(people, [
    RelationshipType.HeadOfHousehold,
    RelationshipType.Husband,
    RelationshipType.Wife,
    RelationshipType.Partner,
  ]);
}

export const isHeadOfHousehold = (
  personOrRelationship: RelationshipType | Person | null | undefined
) => {
  if (!personOrRelationship) return false;

  const relationship =
    (personOrRelationship as Person).relationship || personOrRelationship;
  switch (relationship) {
    case RelationshipType.HeadOfHousehold:
    case RelationshipType.Husband:
    case RelationshipType.Wife:
    case RelationshipType.Partner:
      return true;
  }
  return false;
};

export function getPersonAge(person: Person | null | undefined) {
  if (!person?.dateOfBirth) return null;
  return differenceInYears(new Date(), new Date(person.dateOfBirth));
}

export function getOldestPerson(people: Person[]) {
  let oldestPerson = people[0];
  for (const person of people) {
    if (person.dateOfBirth && oldestPerson.dateOfBirth) {
      if (
        compareAsc(
          new Date(person.dateOfBirth),
          new Date(oldestPerson.dateOfBirth)
        ) === -1
      ) {
        oldestPerson = person;
      }
    }
  }
  return oldestPerson;
}

export const displayAges = (persons: Person[], t: TFunction<'common'>) => {
  return persons
    .filter((p) => p.dateOfBirth)
    ?.map((person) =>
      t('age', {
        age: differenceInYears(new Date(), new Date(person.dateOfBirth)),
      })
    )
    .join(' | ');
};
